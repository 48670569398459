import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_DELETE_TASK_TRANSFER_PERMISSION } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';

export interface UseDeleteTaskTransferPermissionReturn {
  handleDeleteTaskTransferPermission: (id: string) => Promise<void>;
  loading: boolean;
}

const DeleteTaskTransferPermissionMutationOptions: MutationOptions<
  {
    deleteTaskTransferPermission: boolean;
  },
  {
    permissionId: string;
  }
> = {
  mutation: MUTATION_DELETE_TASK_TRANSFER_PERMISSION,
  refetchQueries: ['queryTaskTransferPermissions']
};

export const useDeleteTaskTransferPermission =
  (): UseDeleteTaskTransferPermissionReturn => {
    const deleteTaskTransferPermission = useGraphqlMutation(
      DeleteTaskTransferPermissionMutationOptions
    );
    const deleteTaskTransferPermissionRun = deleteTaskTransferPermission.run;
    const handleDeleteTaskTransferPermission = useCallback(
      async (permissionId: string) => {
        await deleteTaskTransferPermissionRun({ variables: { permissionId } });
      },
      [deleteTaskTransferPermissionRun]
    );
    return {
      handleDeleteTaskTransferPermission,
      loading: deleteTaskTransferPermission.loading
    };
  };
