import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import queryString from 'query-string';
import clsx from 'clsx';

import Split from 'react-split';
import Tippy from '@tippyjs/react';
import { Placement } from '@popperjs/core';
import { useToggle } from '../../common/hooks/useToggle';
import {
  DocumentType,
  TaskCreateInput,
  TaskLogStoryType,
  TaskLogStoryVariant,
  TaskStatus
} from '../../graphql/types';
import { useInput } from '../../common/hooks/useInput';
import {
  URL_KEY_SEARCH_VALUE,
  URL_KEY_SELECTED_DAYS,
  URL_KEY_SELECTED_TAGS,
  URL_KEY_TASK_ID
} from '../../store/urlKeys';
import { useUrlKey } from '../../common/hooks/useUrlKey';
import { TasksStore } from '../../store/TasksStore';
import { useCreateTask } from '../../graphql/hooks/tasks/useCreateTask';
import {
  STORE_EMAIL_ACCOUNTS,
  STORE_LOCATION,
  STORE_TASKS
} from '../../store/storeKeys';

import { ReactComponent as IconChevron } from '../../assets/icons/IconChevron.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/IconPlus.svg';

import { Divider } from '../../common/components/ui/Divider/Divider';
import { Modal } from '../../common/components/ui/Modal/Modal';
import { DropdownItems } from '../../common/components/ui/DropdownItems/DropdownItems';
import { useMediaQuery } from '../../common/hooks/useMediaQuery';
import { emailTextToPreview, htmlToInlineText } from '../../utils/stringUtils';
import { handleDefaultError } from '../../utils/handleDefaultError';
import { LoaderPositions } from '../../common/components/ui/Loader/Loader';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { useCreateOnlyOfficeDocument } from '../../graphql/hooks/tasks/useCreateOnlyOfficeDocument';
import { EmailAccountsStoreState } from '../../store/EmailAccountsStore';
import {
  UploadTaskFileVariant,
  useUploadTaskFile
} from '../../common/hooks/tasks/useUploadTaskFile';
import { IndexPageHeader } from './IndexPageHeader/IndexPageHeader';
import { TasksListItem } from './TasksListItem/TasksListItem';

import { TaskCard } from './TaskCard/TaskCard';
import { Settings } from './Settings/Settings';
import { LetterForm, LetterFormRef } from './TaskCard/LetterForm/LetterForm';
import s from './IndexPage.module.scss';

export interface IndexPageProps {}

enum DropdownPlacement {
  Top,
  Bottom
}

const dropdownTippyPlacement: { [key: number]: Placement } = {
  [DropdownPlacement.Top]: 'top-end',
  [DropdownPlacement.Bottom]: 'bottom-end'
};

const dropdownTippyOffset: { [key: number]: [number, number] } = {
  [DropdownPlacement.Top]: [0, 50],
  [DropdownPlacement.Bottom]: [0, 10]
};

export const IndexPage: React.FC<IndexPageProps> = () => {
  const taskCreateDropdownToggle = useToggle(false);
  const letterModalToggle = useToggle(false);

  const [{ tasks }, tasksStore] = useStore<TasksStore>(STORE_TASKS);

  const [searchValue, , setSearchValue] = useInput('');
  const [, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_SEARCH_VALUE,
    URL_KEY_SELECTED_DAYS,
    URL_KEY_SELECTED_TAGS,
    URL_KEY_TASK_ID
  ]);
  useEffect(() => {
    locationStore.changeQuery({ [URL_KEY_SEARCH_VALUE]: searchValue }, true);
  }, [locationStore, searchValue]);

  useEffect(() => {
    if (setSearchValue !== locationStore.state.query?.searchValue) {
      setSearchValue(locationStore.state.query?.searchValue || '');
    }
  }, [locationStore.state.query, setSearchValue]);

  const { handleCreateTask: createTask, loading: createTaskLoading } =
    useCreateTask();

  const { handleCreateOnlyOfficeDocument: createOnlyOfficeDocument } =
    useCreateOnlyOfficeDocument();

  const handleTaskCreated = (taskId: string | undefined) => {
    setSearchValue('');
    setCurrentTaskId(taskId);
    setTaskCardOpen(true);
  };

  const handleCreateTask = async (values: Partial<TaskCreateInput> = {}) => {
    const newTaskId = await createTask({
      name: searchValue,
      due_date: new Date().toISOString(),
      favorite: false,
      status: TaskStatus.Opened,
      ...values
    });
    handleTaskCreated(newTaskId);
  };

  const handleCreateOnlyOfficeDocument = async (documentType: DocumentType) => {
    const newTaskId = await createOnlyOfficeDocument({
      documentType
    });
    handleTaskCreated(newTaskId);
  };

  const [currentTaskId, setCurrentTaskId] = useUrlKey<string | undefined>(
    URL_KEY_TASK_ID
  );

  useEffect(() => {
    if (tasksStore.tasksQuery.state.loading || !currentTaskId) {
      return;
    }

    const task = tasks.find((task) => task.id === currentTaskId.toString());
    if (!task) {
      setCurrentTaskId(undefined);
    }
  }, [currentTaskId, setCurrentTaskId, tasks, tasksStore]);

  const [taskCardOpen, setTaskCardOpen] = useState<boolean>(
    typeof currentTaskId === 'string'
  );
  const settingsToggle = useToggle();
  const closeTaskCard = () => {
    if (!currentTaskId) {
      return;
    }
    setTaskCardOpen(false);
    setTimeout(() => setCurrentTaskId(undefined), 300);
  };

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.key === 'Escape' &&
        ['BODY', 'INPUT'].includes((e.target as HTMLElement)?.nodeName)
      ) {
        if (currentTaskId) {
          locationStore.changeQuery({
            [URL_KEY_TASK_ID]: undefined
          });
        } else {
          setSearchValue('');
          locationStore.changeQuery({
            [URL_KEY_SELECTED_DAYS]: undefined,
            [URL_KEY_SELECTED_TAGS]: undefined
          });
        }
      }
    },
    [setSearchValue, locationStore, currentTaskId]
  );
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    const parsedQuery = queryString.parse(
      locationStore.history.location.search,
      {
        arrayFormat: 'comma'
      }
    );

    locationStore.changeQuery({
      ...parsedQuery,
      [URL_KEY_SELECTED_DAYS]: [parsedQuery[URL_KEY_SELECTED_DAYS]],
      [URL_KEY_SELECTED_TAGS]: parsedQuery[URL_KEY_SELECTED_TAGS]
        ? [parsedQuery[URL_KEY_SELECTED_TAGS]]
        : parsedQuery[URL_KEY_SELECTED_TAGS]
    });

    if (parsedQuery[URL_KEY_TASK_ID]) {
      setCurrentTaskId(String(parsedQuery[URL_KEY_TASK_ID]));
    }
  }, [locationStore, setCurrentTaskId]);

  useEffect(() => {
    setTaskCardOpen(Boolean(currentTaskId));
  }, [currentTaskId]);

  const createTasksListItemClickHandler =
    (taskId: string) => (e: MouseEvent) => {
      e.stopPropagation();
      setCurrentTaskId(taskId);
      setTaskCardOpen(true);
    };

  const isMobileScreen = useMediaQuery('(max-width: 819px)');

  const { emailAccounts } =
    useStoreState<EmailAccountsStoreState>(STORE_EMAIL_ACCOUNTS);

  /*
    Костыль с ref, чтобы как то отследить любое закрытие (в том числе через клик
    за пределами модалки) LetterForm в случае, когда оно рендерится из IndexPage,
    т.к. нам нужен только случай, когда создается новое письмо.
    При этом оставить логику создания письма в LetterForm.
    Возможно есть способ лучше
   */
  const letterFormRef = useRef<LetterFormRef>(null);

  const onLetterFormClose = useCallback(() => {
    letterFormRef.current?.onClose();
    letterModalToggle.unset();
  }, [letterModalToggle]);

  useEffect(() => {
    if (letterModalToggle.value) {
      letterFormRef.current?.subjectInput?.focus();
    }
  }, [letterModalToggle]);

  const [taskCreateDropdownPlacement, setTaskCreateDropdownPlacement] =
    useState<DropdownPlacement>(DropdownPlacement.Top);

  const onDrag = useCallback(
    (sizes: [number, number]) => {
      const indexPageHeight = (sizes[0] * window.innerHeight) / 100;
      setTaskCreateDropdownPlacement(
        indexPageHeight < 345 ? DropdownPlacement.Bottom : DropdownPlacement.Top
      );
    },
    [setTaskCreateDropdownPlacement]
  );

  const getLastUpdateMessage = (logStory: TaskLogStoryType) => {
    if (
      logStory.variant === TaskLogStoryVariant.Email ||
      logStory.variant === TaskLogStoryVariant.EmailDraft
    ) {
      const inlineText =
        (logStory.variant === TaskLogStoryVariant.Email &&
          emailTextToPreview(logStory.emailLetter?.plain_text || '')) ||
        (logStory.variant === TaskLogStoryVariant.EmailDraft &&
          htmlToInlineText(logStory.emailLetterDraft?.html || ''));

      return (
        inlineText ||
        (logStory.variant === TaskLogStoryVariant.Email
          ? 'Пустое письмо'
          : 'Пустой черновик')
      );
    }

    return logStory.message;
  };

  const { onFileUpload } = useUploadTaskFile({
    variant: UploadTaskFileVariant.CREATE_TASK,
    handleCreateTask
  });

  return (
    <>
      <ConditionalWrapper
        condition={!isMobileScreen}
        wrapper={(children) => (
          <Split
            style={{
              height: '100%',
              backgroundColor: 'transparent'
            }}
            sizes={taskCardOpen ? [50, 50] : [100, 0]}
            minSize={taskCardOpen ? [0, 0] : [0, 0]}
            gutterSize={taskCardOpen ? 10 : 0}
            gutterStyle={() => ({
              display: taskCardOpen ? 'flex' : 'none'
            })}
            gutterAlign="center"
            snapOffset={0}
            dragInterval={1}
            direction="vertical"
            cursor="row-resize"
            expandToMin={taskCardOpen}
            onDrag={onDrag}
          >
            {children}
          </Split>
        )}
      >
        <div
          className={clsx(
            s.IndexPage,
            taskCardOpen && s.IndexPage_taskCardOpen
          )}
        >
          <IndexPageHeader
            searchValue={searchValue}
            handleCreateTask={handleCreateTask}
            createTaskLoading={createTaskLoading}
            setSearchValue={setSearchValue}
            onSettingsOpen={settingsToggle.set}
          />
          <div
            className={s.IndexPage__tasksList}
            onClick={() => closeTaskCard()}
          >
            {queryLoader(tasksStore.tasksQuery.state, {
              loaderProps: { position: LoaderPositions.absolute }
            }) ||
              tasks?.map(
                ({ filtered, ...task }, i) =>
                  !filtered && (
                    <React.Fragment key={i}>
                      <TasksListItem
                        className={clsx(
                          task.id === currentTaskId &&
                            s.IndexPage__tasksListItem_current
                        )}
                        {...task}
                        id={parseInt(task.id)}
                        lastUpdateDate={
                          task.log[0] && new Date(task.log[0].created_at)
                        }
                        lastUpdateMessage={
                          task.log[0] && getLastUpdateMessage(task.log[0])
                        }
                        onClick={createTasksListItemClickHandler(task.id)}
                        visible={!taskCardOpen}
                      />
                      <Divider className={s.IndexPage__tasksListDivider} />
                    </React.Fragment>
                  )
              )}
          </div>

          <Tippy
            placement={dropdownTippyPlacement[taskCreateDropdownPlacement]}
            offset={() => dropdownTippyOffset[taskCreateDropdownPlacement]}
            appendTo={document.body}
            content={
              <DropdownItems
                className={s.IndexPage__createTaskDropdownList}
                itemClassName={s.IndexPage__createTaskDropdownItem}
                items={[
                  {
                    text: 'Презентация',
                    onClick: () => {
                      handleCreateOnlyOfficeDocument(DocumentType.Presentation);
                      taskCreateDropdownToggle.unset();
                    }
                  },
                  {
                    text: 'Таблица',
                    onClick: () => {
                      handleCreateOnlyOfficeDocument(DocumentType.Sheet);
                      taskCreateDropdownToggle.unset();
                    }
                  },
                  {
                    text: 'Документ',
                    onClick: () => {
                      handleCreateOnlyOfficeDocument(DocumentType.Document);
                      taskCreateDropdownToggle.unset();
                    }
                  },
                  {
                    text: (
                      <label
                        className={s.IndexPage__createFileTaskButton}
                        htmlFor="createFileTask"
                      >
                        <input
                          id="createFileTask"
                          type="file"
                          className={s.IndexPage__fileInput}
                          onChange={onFileUpload}
                        />
                        Загрузить файл
                      </label>
                    ),
                    personalItemClassName: clsx(
                      s.IndexPage__createTaskDropdownItem,
                      s.IndexPage__createTaskDropdownItem_file
                    ),
                    onClick: () => {
                      taskCreateDropdownToggle.unset();
                    }
                  },
                  {
                    text: 'Письмо',
                    onClick: () => {
                      if (emailAccounts.length) {
                        letterModalToggle.set();
                        taskCreateDropdownToggle.unset();
                      } else {
                        handleDefaultError(
                          'Добавьте почтовый аккаунт для создания задачи-письма'
                        );
                      }
                    }
                  },
                  {
                    text: 'Задача',
                    onClick: () => {
                      handleCreateTask();
                      taskCreateDropdownToggle.unset();
                    }
                  }
                ]}
              />
            }
            interactive
            visible={taskCreateDropdownToggle.value}
            onClickOutside={taskCreateDropdownToggle.unset}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={s.IndexPage__createTaskWrap}
            >
              <div className={s.IndexPage__createTask}>
                <div
                  onClick={() => handleCreateTask()}
                  className={s.IndexPage__createTaskButton}
                >
                  <IconPlus className={s.IndexPage__createTaskIcon} />
                </div>

                <div
                  onClick={taskCreateDropdownToggle.toggle}
                  className={clsx(
                    s.IndexPage__createTaskDropdown,
                    taskCreateDropdownToggle.value &&
                      s.IndexPage__createTaskDropdown_open
                  )}
                >
                  <IconChevron
                    className={clsx(
                      s.IndexPage__createTaskDropdownIcon,
                      taskCreateDropdownPlacement &&
                        s.IndexPage__createTaskDropdownIcon_reverse
                    )}
                  />
                </div>
              </div>
            </div>
          </Tippy>
        </div>

        <div className={s.IndexPage__taskCardContainer}>
          <TaskCard open={taskCardOpen} close={closeTaskCard} />
        </div>
      </ConditionalWrapper>

      <LetterForm
        close={onLetterFormClose}
        onLetterSent={letterModalToggle.unset}
        onLetterDelete={letterModalToggle.unset}
        component={Modal}
        className={s.IndexPage__letterModal}
        isOpen={letterModalToggle.value}
        onClose={onLetterFormClose}
        ref={letterFormRef}
        disableDefaultStyles
      />

      <Settings
        open={settingsToggle.value}
        onSettingsClose={settingsToggle.unset}
      />
    </>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
