import React from 'react';
import { History } from 'history';
import { RecoverPasswordForm } from '../../common/components/ui/AuthForm/RecoverPasswordForm';
import { PageFooter } from '../../common/components/ui/PageFooter/PageFooter';
import s from './RecoverPasswordPage.module.scss';

export interface RecoverPasswordPageProps {
  history: History;
}

export default function RecoverPasswordPage({
  history
}: RecoverPasswordPageProps) {
  return (
    <>
      <div className={s.RecoverPasswordPage__formContainer}>
        <RecoverPasswordForm
          className={s.RecoverPasswordPage__form}
          history={history}
        />
      </div>
      <PageFooter />
    </>
  );
}
