import { LocationStore } from '@proscom/prostore-react-router';
import { ApolloClientsManager } from '@proscom/prostore-apollo-react';
import { AxiosClientsManager } from '@proscom/prostore-axios-react';
import { appHistory } from './appHistory';
import {
  createBrowserClientWithAuth,
  createSimpleBrowserClient
} from './graphql/client';
import { apiGraphqlUrl, apiUrl } from './config';
import {
  STORE_AUTH,
  STORE_LOCATION,
  STORE_SETTINGS,
  STORE_TASKS,
  STORE_CALENDAR_OPEN,
  STORE_ADDING_NEW_TAG,
  STORE_SYSTEM_LOGS_ENABLED,
  STORE_EMAIL_ACCOUNTS
} from './store/storeKeys';
import { urlTransformers } from './store/urlKeys';
import { AuthStore } from './store/AuthStore';
import { CLIENT_DEFAULT, CLIENT_SIMPLE } from './store/clientNames';
import { TasksStore } from './store/TasksStore';
import { CalendarOpenStore } from './store/CalendarOpenStore';
import { SettingsStore } from './store/SettingsStore';
import { AddingNewTagStore } from './store/AddingNewTagStore';
import { SystemLogsEnabledStore } from './store/SystemLogsEnabledStore';
import { createAxiosClientWithAuth } from './axios/client';
import { EmailAccountsStore } from './store/EmailAccountsStore';

// Создаем LocationStore для отслеживания изменений в адресной строке
// Передаем ему набор трансформеров, чтобы преобразовывать параметры
// из строки в значения и наоборот
export const locationStore = new LocationStore({
  history: appHistory,
  transformers: urlTransformers
});

export const apolloClient = createSimpleBrowserClient({ uri: apiGraphqlUrl });

export const authStore = new AuthStore({
  localStorage,
  client: apolloClient
});
authStore.registerListener();

export const clientWithAuth = createBrowserClientWithAuth({
  uri: apiGraphqlUrl,
  authStore
});

const tasksStore = new TasksStore({
  authStore,
  locationStore,
  client: clientWithAuth
});

const calendarOpenStore = new CalendarOpenStore();

export const apolloContext = new ApolloClientsManager({
  [CLIENT_DEFAULT]: clientWithAuth,
  [CLIENT_SIMPLE]: apolloClient
});

const axiosClient = createAxiosClientWithAuth({
  uri: apiUrl,
  authStore
});

export const axiosContext = new AxiosClientsManager({
  [CLIENT_DEFAULT]: axiosClient
});

const settingsStore = new SettingsStore();

const addingNewTagStore = new AddingNewTagStore();

const systemLogsEnabledStore = new SystemLogsEnabledStore();

const emailAccountsStore = new EmailAccountsStore({
  authStore,
  client: clientWithAuth
});

export const appStores = {
  [STORE_AUTH]: authStore,
  [STORE_LOCATION]: locationStore,
  [STORE_TASKS]: tasksStore,
  [STORE_CALENDAR_OPEN]: calendarOpenStore,
  [STORE_SETTINGS]: settingsStore,
  [STORE_ADDING_NEW_TAG]: addingNewTagStore,
  [STORE_SYSTEM_LOGS_ENABLED]: systemLogsEnabledStore,
  [STORE_EMAIL_ACCOUNTS]: emailAccountsStore
};
