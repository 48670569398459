import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskCreateInput, TaskType } from '../../types';
import { MUTATION_CREATE_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseCreateTaskReturn {
  handleCreateTask: (input: TaskCreateInput) => Promise<string | undefined>;
  loading: boolean;
}

const CreateTaskMutationOptions: MutationOptions<
  {
    createTask: TaskType;
  },
  {
    input: TaskCreateInput;
  }
> = {
  mutation: MUTATION_CREATE_TASK
};

export const useCreateTask = (): UseCreateTaskReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const createTask = useGraphqlMutation(CreateTaskMutationOptions);
  const createTaskRun = createTask.run;
  const handleCreateTask = useCallback(
    (input: TaskCreateInput) =>
      createTaskRun({ variables: { input } }).then(({ data }) => {
        const task = data?.createTask;
        if (task) {
          tasksStore.handleTaskCreated(task);
        }
        return task?.id;
      }),
    [createTaskRun, tasksStore]
  );
  return {
    handleCreateTask,
    loading: createTask.loading
  };
};
