import axios from 'axios';
import { AuthStore } from '../store/AuthStore';
import { createSetAccessTokenContextInterceptor } from './interceptors/setAccessTokenContext';
import { createOperationHandleInterceptor } from './interceptors/operationCancelHandle';

export interface CreateClientWithAuthProps {
  uri: string;
  authStore: AuthStore;
}

export function createAxiosClientWithAuth({
  uri,
  authStore
}: CreateClientWithAuthProps) {
  const client = axios.create({
    baseURL: uri
  });

  const setAccessTokenContext =
    createSetAccessTokenContextInterceptor(authStore);

  const operationCancelHandleInterceptor = createOperationHandleInterceptor();

  client.interceptors.request.use(setAccessTokenContext);
  axios.interceptors.response.use(
    operationCancelHandleInterceptor.onFulfilled,
    operationCancelHandleInterceptor.onRejected
  );

  return client;
}
