import React, { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '@proscom/ui-react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/IconSearch.svg';
import { ReactComponent as IconDelete } from '../../../../assets/icons/IconDelete.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/IconEdit.svg';
import { ReactComponent as IconCheck } from '../../../../assets/icons/IconCheck-2.svg';

import { useCurrentUserTags } from '../../../../graphql/hooks/tags/useCurrentUserTags';

import { Divider } from '../../../../common/components/ui/Divider/Divider';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { useSearchTags } from '../../../../graphql/hooks/tags/useSearchTags';
import { useDeleteTag } from '../../../../graphql/hooks/tags/useDeleteTag';
import { useUpdateTag } from '../../../../graphql/hooks/tags/useUpdateTag';
import { TagType } from '../../../../graphql/types';
import { EmptyListPlaceholder } from '../EmptyListPlaceholder/EmptyListPlaceholder';
import { AutosizeInput } from '../../../../common/components/ui/AutosizeInput/AutosizeInput';
import { SettingsDeleteTagModal } from './SettingsDeleteTagModal/SettingsDeleteTagModal';
import s from './SettingsTags.module.scss';

export const SettingsTags = () => {
  const currentUserTagsRequestResult = useCurrentUserTags();
  const currentUserTags = currentUserTagsRequestResult.state.data;
  const { handleDeleteTag, loading: deleteTagLoading } = useDeleteTag();
  const { handleUpdateTag } = useUpdateTag();

  const [searchTagName, setSearchTagName] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentTag, setCurrentTag] = useState({} as TagType);
  const [isEditingTag, setIsEditingTag] = useState<boolean>(false);
  const [editedTagName, setEditedTagName] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);
  useClickOutside(inputRef, () => {
    setIsEditingTag(false);
    setEditedTagName('');
  });

  const searchTagsRequestResult = useSearchTags(searchTagName, []);
  const searchTags = searchTagsRequestResult.state.data;

  const deleteTag = async (tagId: string) => {
    setModalOpen(false);
    try {
      await handleDeleteTag(tagId);
    } catch (e) {
      handleDefaultError('Не удалось удалить тэг, попробуйте еще раз', e);
    }
  };

  const updateTag = async (id: string, name: string) => {
    try {
      await handleUpdateTag({ id, name });
    } catch (e) {
      handleDefaultError('Не удалось обновить тэг, попробуйте еще раз', e);
    }
  };

  const handleTagDelete = (tag: TagType) => {
    setCurrentTag(tag);
    setModalOpen(true);
  };

  const handleTagUpdate = (tag: TagType) => {
    setIsEditingTag(false);
    updateTag(tag.id, editedTagName);
  };

  const handleTagNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTagName(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && editedTagName) {
      handleTagUpdate(currentTag);
    }
  };

  useEffect(() => {
    if (isEditingTag) inputRef.current?.focus();
  }, [currentTag, isEditingTag]);

  if (!currentUserTags?.length && !currentUserTagsRequestResult.state.loading) {
    return <EmptyListPlaceholder className={s.SettingsTags__empty} />;
  }

  return (
    <>
      <div className={s.SettingsTags__inputWrap}>
        <SearchIcon className={s.SettingsTags__searchIcon} />
        <input
          value={searchTagName}
          onChange={(e) => setSearchTagName(e.target.value)}
          className={s.SettingsTags__input}
          placeholder={'Поиск тэга...'}
          type="text"
        />
      </div>
      <div className={s.SettingsTags__tags}>
        {searchTagsRequestResult.state.loading
          ? 'Загрузка'
          : !searchTags?.length && searchTagName.length
          ? 'По вашему запросу ничего не найдено'
          : (searchTags?.length ? searchTags : currentUserTags)?.map((tag) => (
              <React.Fragment key={tag.id}>
                <div className={s.SettingsTags__tag}>
                  <AutosizeInput
                    onChange={handleTagNameChange}
                    onKeyDown={handleKeyDown}
                    readOnly={!(isEditingTag && currentTag.id === tag.id)}
                    ref={currentTag.id === tag.id ? inputRef : null}
                    value={
                      currentTag.id === tag.id && isEditingTag
                        ? editedTagName
                        : tag.name
                    }
                    className={s.SettingsTags__editingTagInput}
                    type="text"
                  />

                  {isEditingTag && currentTag.id === tag.id ? (
                    !!editedTagName.length && (
                      <IconCheck
                        onClick={() => handleTagUpdate(tag)}
                        className={s.SettingsTags__checkIcon}
                      />
                    )
                  ) : (
                    <>
                      <IconEdit
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditingTag(true);
                          setCurrentTag(tag);
                          setEditedTagName(tag.name);
                        }}
                        className={s.SettingsTags__editIcon}
                      />
                      <IconDelete
                        onClick={() => handleTagDelete(tag)}
                        className={s.SettingsTags__deleteIcon}
                      />
                    </>
                  )}
                </div>
                <Divider className={s.SettingsTags__divider} />
              </React.Fragment>
            ))}
      </div>
      <SettingsDeleteTagModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onTagDelete={deleteTag}
        currentTag={currentTag}
        deleteTagLoading={deleteTagLoading}
      />
    </>
  );
};
