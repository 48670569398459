import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import {
  MUTATION_CREATE_FILE_TASK_FROM_LETTER_ATTACHMENT
} from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseCreateFileTaskFromLetterAttachmentReturn {
  handleCreateFileTaskFromLetterAttachment: (
    attachmentId: number
  ) => Promise<string | undefined>;
  loading: boolean;
}

const CreateFileTaskFromLetterAttachmentMutationOptions: MutationOptions<
  {
    createFileTaskFromLetterAttachment: TaskType;
  },
  {
    attachmentId: number;
  }
> = {
  mutation: MUTATION_CREATE_FILE_TASK_FROM_LETTER_ATTACHMENT
};

export const useCreateFileTaskFromLetterAttachment =
  (): UseCreateFileTaskFromLetterAttachmentReturn => {
    const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

    const createFileTaskFromLetterAttachment = useGraphqlMutation(
      CreateFileTaskFromLetterAttachmentMutationOptions
    );
    const createFileTaskFromLetterAttachmentRun =
      createFileTaskFromLetterAttachment.run;
    const handleCreateFileTaskFromLetterAttachment = useCallback(
      (attachmentId: number) =>
        createFileTaskFromLetterAttachmentRun({
          variables: { attachmentId }
        }).then(({ data }) => {
          const task = data?.createFileTaskFromLetterAttachment;
          if (task) {
            tasksStore.handleTaskCreated(task);
          }
          return task?.id;
        }),
      [createFileTaskFromLetterAttachmentRun, tasksStore]
    );
    return {
      handleCreateFileTaskFromLetterAttachment,
      loading: createFileTaskFromLetterAttachment.loading
    };
  };
