import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { MUTATION_DELETE_REPLY_DRAFT } from '../../mutations/letterDrafts';
import { TaskType } from '../../types';

export interface UseDeleteReplyDraftReturn {
  handleDeleteReplyDraft: (draftId: number) => Promise<void>;
  loading: boolean;
}

const DeleteReplyDraftMutationOptions: MutationOptions<
  {
    deleteReplyDraft: TaskType;
  },
  {
    draftId: number;
  }
> = {
  mutation: MUTATION_DELETE_REPLY_DRAFT
};

export const useDeleteReplyDraft = (): UseDeleteReplyDraftReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const deleteReplyDraft = useGraphqlMutation(DeleteReplyDraftMutationOptions);
  const deleteReplyDraftRun = deleteReplyDraft.run;

  const handleDeleteReplyDraft = useCallback(
    (draftId: number) =>
      deleteReplyDraftRun({ variables: { draftId } }).then(({ data }) => {
        const task = data?.deleteReplyDraft;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [deleteReplyDraftRun, tasksStore]
  );
  return {
    handleDeleteReplyDraft,
    loading: deleteReplyDraft.loading
  };
};
