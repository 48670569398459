import React, { KeyboardEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/IconClose.svg';
import { Button } from '../../../../common/components/ui/Button/Button';
import { Input } from '../../../../common/components/ui/Input/Input';
import { TaskType } from '../../../../graphql/types';
import s from './TaskCardTransferTaskModal.module.scss';

export interface TaskCardTransferTaskModalProps {
  isOpen: boolean;
  onClose: () => any;
  task: TaskType | undefined;
  onTransferTask: (transfereeUsername: string) => any;
  transferTaskLoading: boolean;
}

export const TaskCardTransferTaskModal = ({
  isOpen,
  onClose,
  task,
  onTransferTask,
  transferTaskLoading
}: TaskCardTransferTaskModalProps) => {
  const [receiverLogin, setReceiverLogin] = useState('');

  const handleTransferTask = async () => {
    if (task) {
      await onTransferTask(receiverLogin);
      toast(
        `Задача ${
          task.name ? `«${task.name}»` : 'без названия'
        } отправлена пользователю с логином ${receiverLogin}`
      );
      setReceiverLogin('');
      onClose();
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        return handleTransferTask();
      case 'Escape':
        setReceiverLogin('');
        onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeIcon={
        <CloseIcon
          className={s.TaskCardTransferTaskModal__closeIcon}
          onClick={onClose}
        />
      }
      contentClassName={s.TaskCardTransferTaskModal}
      header="ПЕРЕДАЧА ЗАДАЧИ"
    >
      <p className={s.TaskCardTransferTaskModal__header}>
        Будет передана копия задачи со всем логом событий и комментариев.
      </p>
      <Input
        label="Логин получателя:\>"
        value={receiverLogin}
        onChange={setReceiverLogin}
        onKeyDown={onKeyDown}
        className={s.TaskCardTransferTaskModal__input}
        focusAfterRender
      />
      <p className={s.TaskCardTransferTaskModal__warning}>
        Система не отображает, существует ли пользователь с таким логином,
        поэтому убедитесь, что данные введены верно
      </p>
      <Button
        className={s.TaskCardTransferTaskModal__button}
        disabled={!receiverLogin}
        onClick={handleTransferTask}
        loading={transferTaskLoading}
      >
        ПЕРЕДАТЬ ЗАДАЧУ
      </Button>
    </Modal>
  );
};
