import {
  IEmailAccountSettingFormData,
  IEmailAccountSettingFormErrors
} from './EmailAccountSettingModal';

const emptyFieldErrors = {
  email: 'Введите email',
  name: 'Введите имя учетной записи',
  imap_host: 'Введите хост',
  imap_port: 'Введите порт',
  imap_login: 'Введите логин',
  imap_password: 'Введите пароль',
  smtp_host: 'Введите хост',
  smtp_port: 'Введите порт',
  smtp_login: 'Введите логин',
  smtp_password: 'Введите пароль'
};

export const validateCustomEmailAccountData = (
  values: IEmailAccountSettingFormData,
  validatePasswords?: boolean
) => {
  const errors: IEmailAccountSettingFormErrors = {};
  for (const key in emptyFieldErrors) {
    if (
      validatePasswords
        ? !values[key]
        : !values[key] && key !== 'imap_password' && key !== 'smtp_password'
    ) {
      errors[key] = emptyFieldErrors[key];
    }
  }
  return errors;
};
