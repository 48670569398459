import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_TAG } from '../../mutations/tags';
import { TagCreateInput } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';

export interface UseCreateTagReturn {
  handleCreateTag: (
    TagCreateInput
  ) => Promise<{ id: string } | null | undefined>;
  loading: boolean;
}

const CreateTagMutationOptions: MutationOptions<
  { createTag: { id: string } },
  { input: TagCreateInput }
> = {
  mutation: MUTATION_CREATE_TAG
};

export const useCreateTag = (): UseCreateTagReturn => {
  const createTag = useGraphqlMutation(CreateTagMutationOptions);
  const createTagRun = createTag.run;
  const handleCreateTag = useCallback(
    (input: TagCreateInput) =>
      createTagRun({ variables: { input } }).then(
        ({ data }) => data?.createTag
      ),
    [createTagRun]
  );
  return {
    handleCreateTag,
    loading: createTag.loading
  };
};
