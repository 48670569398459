import {
  useAsyncOperation,
  useContextStore,
  useStore
} from '@proscom/prostore-react';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { LocationStore } from '@proscom/prostore-react-router';
import { AuthStore } from '../../../../store/AuthStore';
import {
  STORE_AUTH,
  STORE_SYSTEM_LOGS_ENABLED,
  STORE_SETTINGS,
  STORE_TASKS,
  STORE_LOCATION
} from '../../../../store/storeKeys';
import { useToggle } from '../../../hooks/useToggle';
import { DropdownItems } from '../DropdownItems/DropdownItems';
import { ReactComponent as IconChevron } from '../../../../assets/icons/IconChevron.svg';
import { ReactComponent as SettingIcon } from '../../../../assets/icons/IconSettings.svg';
import { ReactComponent as IconRefresh } from '../../../../assets/icons/IconRefresh.svg';
import { SettingsStore } from '../../../../store/SettingsStore';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { SystemLogsEnabledStore } from '../../../../store/SystemLogsEnabledStore';
import { TasksStore } from '../../../../store/TasksStore';
import {
  URL_KEY_SEARCH_VALUE,
  URL_KEY_SELECTED_DAYS,
  URL_KEY_SELECTED_TAGS,
  URL_KEY_TARGET,
  URL_KEY_TASK_ID
} from '../../../../store/urlKeys';
import s from './Menu.module.scss';

interface MenuProps {
  className?: string;
  onSettingsOpen: () => void;
}

export const Menu = ({ className, onSettingsOpen }: MenuProps) => {
  const dropdownToggle = useToggle();
  const authStore = useContextStore<AuthStore>(STORE_AUTH);
  const logout = useAsyncOperation(authStore.logOut, { singleton: true });
  const [, settingsStore] = useStore<SettingsStore>(STORE_SETTINGS);
  const [systemLogsEnabled, systemLogsEnabledStore] =
    useStore<SystemLogsEnabledStore>(STORE_SYSTEM_LOGS_ENABLED);
  const [, tasksStore] = useStore<TasksStore>(STORE_TASKS);
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);

  const onLogoutClick = useCallback(async () => {
    await logout.run();

    locationStore.changeQuery(
      {
        [URL_KEY_SEARCH_VALUE]: undefined,
        [URL_KEY_SELECTED_DAYS]: undefined,
        [URL_KEY_SELECTED_TAGS]: undefined,
        [URL_KEY_TASK_ID]: undefined,
        [URL_KEY_TARGET]: undefined
      },
      true
    );
  }, [logout, locationStore]);

  const openSettings = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dropdownToggle.unset();
    onSettingsOpen();
  };

  const refreshTasks = useCallback(() => {
    tasksStore.loadTasks();
  }, [tasksStore]);

  return (
    <Tippy
      content={
        <DropdownItems
          className={s.Menu__dropdownItems}
          itemClassName={s.Menu__dropdownItem}
          dividerClassName={s.Menu__dropdownDivider}
          items={[
            {
              text: 'Передача задач',
              onClick: (e) => {
                dropdownToggle.unset();
                openSettings(e);
                settingsStore.setSettingTitle('Передача задач');
              }
            },
            {
              text: 'Тэги',
              onClick: (e) => {
                dropdownToggle.unset();
                openSettings(e);
                settingsStore.setSettingTitle('Тэги');
              }
            },
            {
              text: 'Почта',
              onClick: (e) => {
                dropdownToggle.unset();
                openSettings(e);
                settingsStore.setSettingTitle('Почта');
              }
            },
            {
              text: (
                <>
                  Системные комментарии
                  <ToggleSwitch
                    className={s.Menu__dropdownSwitch}
                    checked={!!systemLogsEnabled.value}
                    onChange={() => {}}
                  />
                </>
              ),
              onClick: () => {
                systemLogsEnabledStore.toggleEnabled();
              },
              personalItemClassName: s.Menu__dropdownItem_withSwitch
            },
            {
              text: 'Выход',
              onClick: onLogoutClick,
              divided: true,
              personalItemClassName: s.Menu__dropdownItem_logout
            }
          ]}
        />
      }
      interactive
      visible={dropdownToggle.value}
      onClickOutside={dropdownToggle.unset}
      placement="bottom-end"
    >
      <div className={clsx(s.Menu, className)}>
        <div className={s.Menu__refreshButton} onClick={refreshTasks}>
          <IconRefresh className={s.Menu__refreshIcon} />
        </div>

        <div className={s.Menu__settings}>
          <div className={s.Menu__settingsButton} onClick={openSettings}>
            <SettingIcon className={s.Menu__settingsIcon} />
          </div>
          <div
            className={clsx(
              s.Menu__dropdown,
              dropdownToggle.value && s.Menu__dropdown_open
            )}
            onClick={dropdownToggle.toggle}
          >
            <IconChevron className={s.Menu__chevron} />
          </div>
        </div>
      </div>
    </Tippy>
  );
};
