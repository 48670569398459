function env<T = string | undefined>(
  variable: string | undefined,
  defaultValue?: T
) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

export const apiUrl = requireEnv(
  process.env.REACT_APP_API_URL,
  'REACT_APP_API_URL'
);
export const apiGraphqlUrl = apiUrl + '/graphql';

export const oauthOrigin = requireEnv(
  process.env.REACT_APP_OAUTH_ORIGIN,
  'REACT_APP_OAUTH_ORIGIN'
);
export const oauthUrl = requireEnv(
  process.env.REACT_APP_OAUTH_URL,
  'REACT_APP_OAUTH_URL'
);
