import { useContextApolloClient } from '@proscom/prostore-apollo-react';
import ApolloClient, { MutationOptions } from 'apollo-client';
import {
  useAsyncOperation,
  UseAsyncOperationOptions
} from '@proscom/prostore-react';

export function useGraphqlMutation<Result = any, Vars = any>(
  options?: MutationOptions<Result, Vars>,
  clientOrName?: string | ApolloClient<any>,
  operationOptions: UseAsyncOperationOptions = {}
) {
  const client = useContextApolloClient(clientOrName || null);
  return useAsyncOperation(
    async (mutationObject: Partial<MutationOptions<Result, Vars>>) => {
      const response = await client.mutate<Result, Vars>({
        ...options,
        ...mutationObject,
        errorPolicy: 'all'
      } as any);

      if (response.errors?.[0]) {
        throw response.errors?.[0];
      }

      return response;
    },
    {
      singleton: true,
      ...operationOptions
    }
  );
}
