import gql from 'graphql-tag';

export const QUERY_EMAIL_ACCOUNTS = gql`
  query queryEmailAccounts {
    currentUserEmailAccounts {
      id
      service
      email
      name
      smtp_host
      smtp_port
      smtp_login
      smtp_security_protocol
      imap_host
      imap_port
      imap_login
      imap_security_protocol
      imap_error
      imap_error_date
      last_successful_mail_update_date
    }
  }
`;
