import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { merge, parseInt, pull, uniqueId } from 'lodash-es';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { FileType, FileVariant } from '../../../graphql/types';
import { useUpdateFilesExpirationDates } from '../../../graphql/hooks/tasks/useUpdateFilesExpirationDates';
import { useFileUploadOptions } from '../../../graphql/hooks/tasks/useFileUploadOptions';
import { useCopyFile } from '../../../graphql/hooks/tasks/useCopyFile';

export interface UploadFilesItem {
  id?: string;
  localId: string;
  name: string;
  size: number;
  source?: File;
  loading?: boolean;
  error?: boolean;
  expiredAt?: Date;
  progress?: number;
  canBeOpenedInOnlyOffice?: boolean;
  originalFileId?: string;
}

export interface UseUploadFilesReturn {
  files: UploadFilesItem[];
  uploadFile: (file: File) => Promise<UploadFilesItem>;
  uploadFileCopy: (originalFile: FileType) => Promise<UploadFilesItem>;
  removeFile: (localId: string) => void;
  reloadFile: (localId: string) => Promise<void>;
  clearFiles: () => void;
  startUpdateFileExpirationDateScheduler: (file: UploadFilesItem) => void;
  updateFileExpirationDateTimeoutIds: MutableRefObject<NodeJS.Timeout[]>;
}

interface LoadFileHttpResponse {
  id: number;
  expired_at: string;
  canBeOpenedInOnlyOffice: boolean;
}

interface LoadFileData {
  id: string;
  expiredAt: Date;
  canBeOpenedInOnlyOffice: boolean;
}

interface LoadFileCopyData {
  id: string;
  canBeOpenedInOnlyOffice: boolean;
}

export interface UploadFilesOptions {
  fileVariant: FileVariant;
  initialFiles?: UploadFilesItem[];
  showProgress?: boolean;
}

export const useUploadFiles = ({
  fileVariant,
  initialFiles = [],
  showProgress
}: UploadFilesOptions): UseUploadFilesReturn => {
  const client = useContextAxiosClient();
  const [files, setFiles] = useState<UploadFilesItem[]>(initialFiles);

  const { handleUpdateFilesExpirationDates } = useUpdateFilesExpirationDates();
  const { handleCopyFile } = useCopyFile();

  const uploadOptionsData = useFileUploadOptions();
  const uploadOptions = useMemo(
    () => ({
      [FileVariant.FileTask]: uploadOptionsData?.taskFileSizeLimit,
      [FileVariant.EmailDraftAttachment]:
        uploadOptionsData?.emailAttachmentSizeLimit
    }),
    [uploadOptionsData]
  );

  const _handleFileCreated = useCallback(
    (file: UploadFilesItem) => {
      setFiles((files) => [...files, { ...file, loading: true }]);
    },
    [setFiles]
  );

  const _handleFileUpdated = useCallback(
    (localId: string, data: Partial<UploadFilesItem>) => {
      setFiles((files) =>
        files.map((file) =>
          file.localId === localId ? { ...file, ...data } : file
        )
      );
    },
    [setFiles]
  );

  const _handleFileRemoved = useCallback(
    (localId: string) => {
      setFiles((files) => files.filter((item) => item.localId !== localId));
    },
    [setFiles]
  );

  const loadFile = useCallback(
    async (localId: string, source: File): Promise<LoadFileData> => {
      const formData = new FormData();
      formData.append('file', source);

      try {
        const response = await client.post<LoadFileHttpResponse>(
          '/files/upload',
          formData,
          {
            headers: {
              'x-filetype': fileVariant,
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (e) => {
              if (showProgress) {
                const progress = e.loaded / source.size;
                _handleFileUpdated(localId, { progress });
              }
            }
          }
        );

        const data = {
          id: String(response.data.id),
          expiredAt: new Date(response.data.expired_at),
          canBeOpenedInOnlyOffice: response.data.canBeOpenedInOnlyOffice
        };

        _handleFileUpdated(localId, {
          ...data,
          loading: false,
          error: false
        });

        return data;
      } catch (err: any) {
        _handleFileUpdated(localId, { loading: false, error: true });
        throw err;
      }
    },
    [_handleFileUpdated, client, fileVariant, showProgress]
  );

  const uploadFile = useCallback(
    async (source: File) => {
      const MB = 1024 * 1024;
      const fileMaxSize = uploadOptions[fileVariant];

      if (fileMaxSize && source.size > fileMaxSize) {
        throw new Error(
          `Размер файла не должен превышать ${Math.ceil(
            fileMaxSize / MB
          )} мегабайт`
        );
      }

      const file: UploadFilesItem = {
        localId: uniqueId('file'),
        name: source.name,
        size: source.size,
        progress: showProgress ? 0 : undefined,
        canBeOpenedInOnlyOffice: false,
        source
      };

      _handleFileCreated(file);

      const loadData = await loadFile(file.localId, source);
      merge(file, loadData);

      return file;
    },
    [_handleFileCreated, loadFile, showProgress, uploadOptions, fileVariant]
  );

  const loadFileCopy = useCallback(
    async (
      localId: string,
      originalFileId: string
    ): Promise<LoadFileCopyData | undefined> => {
      try {
        const copiedFile = await handleCopyFile(parseInt(originalFileId));
        if (copiedFile) {
          const data = {
            id: String(copiedFile.id),
            canBeOpenedInOnlyOffice: !!copiedFile.canBeOpenedInOnlyOffice
          };

          _handleFileUpdated(localId, {
            ...data,
            loading: false,
            error: false
          });

          return data;
        }
      } catch (e) {
        _handleFileUpdated(localId, { loading: false, error: true });
        throw e;
      }
    },
    [_handleFileUpdated, handleCopyFile]
  );

  const uploadFileCopy = useCallback(
    async (originalFile: FileType) => {
      const file: UploadFilesItem = {
        localId: uniqueId('file'),
        name: originalFile.file_name,
        size: originalFile.size,
        canBeOpenedInOnlyOffice: false,
        originalFileId: originalFile.id
      };

      _handleFileCreated(file);

      const loadData = await loadFileCopy(file.localId, originalFile.id);
      merge(file, loadData);

      return file;
    },
    [_handleFileCreated, loadFileCopy]
  );

  const reloadFile = useCallback(
    async (localId: string) => {
      const file = files.find((file) => file.localId === localId);
      if (!file) {
        throw new Error('Файл не найден');
      }

      _handleFileUpdated(file.localId, { loading: true, error: false });
      if (file.originalFileId) {
        await loadFileCopy(file.localId, file.originalFileId);
      } else {
        if (!file.source) {
          throw new Error('Файл не найден');
        }
        await loadFile(file.localId, file.source);
      }
    },
    [files, loadFile, loadFileCopy, _handleFileUpdated]
  );

  const removeFile = useCallback(
    (localId: string) => {
      _handleFileRemoved(localId);
    },
    [_handleFileRemoved]
  );

  const clearFiles = useCallback(() => {
    setFiles([]);
  }, [setFiles]);

  useEffect(() => {
    return () => {
      setFiles([]);
    };
    // eslint-disable-next-line
  }, []);

  const updateFileExpirationDateTimeoutIds = useRef<NodeJS.Timeout[]>([]);

  const startUpdateFileExpirationDateScheduler = useCallback(
    (file: UploadFilesItem) => {
      if (!file.expiredAt || !file.id) {
        return;
      }

      const time =
        differenceInMilliseconds(file.expiredAt, new Date()) - 60 * 1000;

      const timeoutId = setTimeout(async () => {
        pull(updateFileExpirationDateTimeoutIds.current, timeoutId);

        const updatedFile = await handleUpdateFilesExpirationDates([
          parseInt(file.id!)
        ]);

        if (updatedFile) {
          file.expiredAt = new Date(updatedFile[0].expired_at!);
          _handleFileUpdated(file.localId, {
            expiredAt: file.expiredAt
          });

          startUpdateFileExpirationDateScheduler(file);
        }
      }, time);

      updateFileExpirationDateTimeoutIds.current.push(timeoutId);
    },
    [_handleFileUpdated, handleUpdateFilesExpirationDates]
  );

  return {
    files,
    uploadFile,
    uploadFileCopy,
    removeFile,
    reloadFile,
    clearFiles,
    startUpdateFileExpirationDateScheduler,
    updateFileExpirationDateTimeoutIds
  };
};
