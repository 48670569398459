import axios, { AxiosResponse } from 'axios';

interface CreateOperationHandleInterceptorReturn {
  onFulfilled?: ((value: AxiosResponse<any>) => Promise<never>) | undefined;
  onRejected?: ((error: any) => any) | undefined;
}

export function createOperationHandleInterceptor(): CreateOperationHandleInterceptorReturn {
  return {
    onFulfilled: () => {
      throw new axios.Cancel('Operation canceled by the user.');
    },
    onRejected: (error) => {
      return Promise.reject(error);
    }
  };
}
