import {
  IUseGraphqlWatchQueryOptions,
  useGraphqlWatchQuery
} from '@proscom/prostore-apollo-react';
import { FileUploadOptionsType } from '../../types';
import { QUERY_FILE_UPLOAD_OPTIONS } from '../../queries/files';

const queryOptions: IUseGraphqlWatchQueryOptions<null, FileUploadOptionsType> =
  {
    query: QUERY_FILE_UPLOAD_OPTIONS,
    mapData: (result) => result?.fileUploadOptions
  };

export function useFileUploadOptions() {
  const request = useGraphqlWatchQuery({
    queryOptions
  });

  return request.state.data;
}
