import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { RespondToTaskTransferInput, TaskType } from '../../types';
import { MUTATION_RESPOND_TO_TASK_TRANSFER } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseRespondToTaskTransferReturn {
  handleRespondToTaskTransfer: (
    input: RespondToTaskTransferInput
  ) => Promise<void>;
  loading: boolean;
}

const RespondToTaskTransferMutationOptions: MutationOptions<
  {
    respondToTaskTransfer: TaskType;
  },
  {
    input: RespondToTaskTransferInput;
  }
> = {
  mutation: MUTATION_RESPOND_TO_TASK_TRANSFER
};

export const useRespondToTaskTransfer = (): UseRespondToTaskTransferReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const respondToTaskTransfer = useGraphqlMutation(
    RespondToTaskTransferMutationOptions
  );
  const respondToTaskTransferRun = respondToTaskTransfer.run;
  const handleRespondToTaskTransfer = useCallback(
    (input: RespondToTaskTransferInput) =>
      respondToTaskTransferRun({ variables: { input } }).then(({ data }) => {
        const task = data?.respondToTaskTransfer;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [respondToTaskTransferRun, tasksStore]
  );
  return {
    handleRespondToTaskTransfer,
    loading: respondToTaskTransfer.loading
  };
};
