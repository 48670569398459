import React from 'react';
import clsx from 'clsx';
import s from './Loader.module.scss';

export enum LoaderPositions {
  absolute = 'absolute',
  static = 'static',
  fixed = 'fixed'
}

export const loaderColors = {
  default: '#c0c9d7',
  buttonPrimary: '#fafafa',
  buttonSecondary: '#8001ff',
  buttonTertiary: '#707781'
};

export interface LoaderProps {
  className?: string;
  position?: LoaderPositions;
  singleColor?: string;
  size?: number;
  borderSize?: number;
}

const defaultProps = {
  size: 40,
  borderSize: 2,
  singleColor: loaderColors.default
};

export const Loader: React.FC<LoaderProps> = ({
  className,
  position = LoaderPositions.static,
  singleColor = defaultProps.singleColor,
  size = defaultProps.size,
  borderSize = defaultProps.borderSize
}) => {
  const style = {
    minWidth: size,
    width: size,
    height: size,
    borderWidth: borderSize,
    borderColor: singleColor
  };

  return (
    <div className={clsx(s.Loader, s[`Loader_${position}`], className)}>
      <div className={s.Loader__spinner} style={style} />
    </div>
  );
};
