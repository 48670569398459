import { MutationOptions } from 'apollo-client';
import { EmailAccountCreateInput, EmailAccountUpdateInput } from '../types';
import {
  MUTATION_CREATE_CUSTOM_EMAIL_ACCOUNT,
  MUTATION_DELETE_EMAIL_ACCOUNT,
  MUTATION_UPDATE_EMAIL_ACCOUNT
} from '../mutations/emailAccounts';
import { clearCache } from '../queriesUtils';

export class EmailAccounts {
  private static refetchQueries = ['queryEmailAccounts'];
  private static updateQueries = ['currentUserEmailAccounts'];
  private static updateFragments = ['EmailAccountType'];

  public static createCustomEmailAccount: MutationOptions<
    {
      id: string;
    },
    {
      input: EmailAccountCreateInput;
    }
  > = {
    mutation: MUTATION_CREATE_CUSTOM_EMAIL_ACCOUNT,
    refetchQueries: EmailAccounts.refetchQueries,
    update: clearCache(
      EmailAccounts.updateQueries,
      EmailAccounts.updateFragments
    )
  };

  public static updateEmailAccount: MutationOptions<
    {
      id: string;
    },
    {
      input: EmailAccountUpdateInput;
    }
  > = {
    mutation: MUTATION_UPDATE_EMAIL_ACCOUNT,
    refetchQueries: EmailAccounts.refetchQueries,
    update: clearCache(
      EmailAccounts.updateQueries,
      EmailAccounts.updateFragments
    )
  };

  public static deleteEmailAccount: MutationOptions<
    {
      result: boolean;
    },
    {
      id: string;
    }
  > = {
    mutation: MUTATION_DELETE_EMAIL_ACCOUNT,
    refetchQueries: [...EmailAccounts.refetchQueries, 'queryTasks'],
    update: clearCache(
      [...EmailAccounts.updateQueries, 'currentUserTasks'],
      [...EmailAccounts.updateFragments, 'taskFull']
    )
  };
}
