import {
  IUseGraphqlWatchQueryOptions,
  useGraphqlWatchQuery
} from '@proscom/prostore-apollo-react';
import { skipIf } from '@proscom/prostore';
import { TagType } from '../../types';
import { QUERY_SEARCH_TAGS } from '../../queries/tags';

const queryOptions: IUseGraphqlWatchQueryOptions<
  { query: string; excludedTasksIds: number[] },
  TagType[]
> = {
  query: QUERY_SEARCH_TAGS,
  mapData: (result) => result.searchTags,
  skipQuery: skipIf(({ query }) => !query, [])
};

export const useSearchTags = (query: string, excludedTasksIds: number[]) => {
  return useGraphqlWatchQuery({
    queryOptions,
    variables: { query, excludedTasksIds }
  });
};
