import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import { MUTATION_CONVERT_TEXT_TASK_TO_FILE } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseConvertTextTaskToFileReturn {
  handleConvertTextTaskToFile: (
    fileId: number,
    taskId: number
  ) => Promise<void>;
  loading: boolean;
}

const ConvertTextTaskToFileMutationOptions: MutationOptions<
  {
    convertTextTaskToFile: TaskType;
  },
  {
    fileId: number;
    taskId: number;
  }
> = {
  mutation: MUTATION_CONVERT_TEXT_TASK_TO_FILE
};

export const useConvertTextTaskToFile = (): UseConvertTextTaskToFileReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const convertTextTaskToFile = useGraphqlMutation(
    ConvertTextTaskToFileMutationOptions
  );
  const convertTextTaskToFileRun = convertTextTaskToFile.run;
  const handleConvertTextTaskToFile = useCallback(
    (fileId: number, taskId: number) =>
      convertTextTaskToFileRun({
        variables: { fileId, taskId }
      }).then(({ data }) => {
        const task = data?.convertTextTaskToFile;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [convertTextTaskToFileRun, tasksStore]
  );
  return {
    handleConvertTextTaskToFile,
    loading: convertTextTaskToFile.loading
  };
};
