import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { MUTATION_DELETE_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { clearCache } from '../../queriesUtils';

export interface UseDeleteTaskReturn {
  handleDeleteTask: (taskId: string) => Promise<void>;
  loading: boolean;
}

const DeleteTaskMutationOptions: MutationOptions<
  {
    deleteTask: boolean;
  },
  {
    taskId: string;
  }
> = {
  mutation: MUTATION_DELETE_TASK,
  refetchQueries: ['queryCurrentUserTags'],
  update: clearCache(['currentUserTags'])
};

export const useDeleteTask = (): UseDeleteTaskReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const deleteTask = useGraphqlMutation(DeleteTaskMutationOptions);
  const deleteTaskRun = deleteTask.run;
  const handleDeleteTask = useCallback(
    (taskId: string) =>
      deleteTaskRun({ variables: { taskId } }).then(({ data }) => {
        const deleted = data?.deleteTask;
        if (deleted) {
          tasksStore.handleTaskDeleted(taskId);
        }
      }),
    [deleteTaskRun, tasksStore]
  );
  return {
    handleDeleteTask,
    loading: deleteTask.loading
  };
};
