import React from 'react';
import { upperFirst } from 'lodash-es';
import { Modal } from '../../../../../common/components/ui/Modal/Modal';
import {
  Button,
  ButtonVariant
} from '../../../../../common/components/ui/Button/Button';
import s from './EmailAccountsList.module.scss';

export interface EmailAccountDeleteModalProps {
  isOpen: boolean;
  onClose: () => any;
  onCancel: () => any;
  onConfirm?: () => any;
  email?: string;
  confirmLoading?: boolean;
}

export const EmailAccountDeleteModal = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  email,
  confirmLoading
}: EmailAccountDeleteModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    className={s.EmailAccountDeleteModal}
    header="УДАЛЕНИЕ ПОЧТЫ"
  >
    Вы уверены, что хотите удалить почту{' '}
    <span className={s.EmailAccountDeleteModal__login}>
      {upperFirst(email)}?
    </span>
    <div className={s.EmailAccountDeleteModal__buttonsContainer}>
      <Button
        className={s.EmailAccountDeleteModal__button}
        variant={ButtonVariant.SECONDARY}
        onClick={onCancel}
      >
        Отмена
      </Button>
      <Button
        className={s.EmailAccountDeleteModal__button}
        onClick={onConfirm}
        loading={confirmLoading}
      >
        Удалить
      </Button>
    </div>
  </Modal>
);
