import { AxiosRequestConfig } from 'axios';
import { AuthStore } from '../../store/AuthStore';

export function createSetAccessTokenContextInterceptor(
  authStore: AuthStore
): (
  value: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig> {
  return (config) => {
    const { authData } = authStore.state;
    const token = authData && authData.accessToken;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  };
}
