import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import AutowidthInput from 'react-autowidth-input';
import { useClickOutside } from '@proscom/ui-react';
import { ReactComponent as IconClose } from '../../../../assets/icons/IconCloseThin.svg';

import { IconButton } from '../IconButton/IconButton';
import s from './ChipInput.module.scss';

interface ChipInputProps {
  className?: string;
  items: string[];
  setItems: (items: string[]) => any;
  currentInputValue: string;
  setCurrentInputValue: (value: string) => void;
}

export const ChipInput = ({
  className,
  items,
  setItems,
  currentInputValue,
  setCurrentInputValue
}: ChipInputProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const addItem = (item: string) => {
    if (!items.includes(item.trim())) {
      setItems([...items, item.trim()]);
    }
  };

  const onClickOutside = useCallback(() => {
    setFocused(false);
    if (currentInputValue.trim()) {
      addItem(currentInputValue);
      setCurrentInputValue('');
    }
    // eslint-disable-next-line
  }, [setFocused, setCurrentInputValue, currentInputValue]);
  useClickOutside(rootRef, onClickOutside);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentInputValue.trim()) {
        addItem(currentInputValue);
        setCurrentInputValue('');
      }
    } else if (e.key === 'Tab') {
      if (currentInputValue.trim()) {
        e.preventDefault();
        addItem(currentInputValue);
        setCurrentInputValue('');
      } else {
        setFocused(false);
      }
    } else if (e.key === 'Backspace' && !currentInputValue) {
      setItems(items.slice(0, -1));
    }
  };

  const handleDelete = (itemToDelete) => {
    setItems(items.filter((item) => item !== itemToDelete));
  };

  return (
    <div
      className={clsx(s.ChipInput, focused && s.ChipInput_focused, className)}
      ref={rootRef}
      onClick={() => inputRef.current?.focus()}
    >
      {items.map((item, i) => (
        <div
          className={clsx(
            s.ChipInput__item,
            !focused && s.ChipInput__item_inline
          )}
          key={i}
        >
          <span className={s.ChipInput__itemText}>{item}</span>
          {!focused && i !== items.length - 1 && ', '}
          <IconButton
            className={s.ChipInput__delete}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(item);
            }}
            icon={IconClose}
          />
        </div>
      ))}
      <AutowidthInput
        value={currentInputValue}
        onChange={(e) => setCurrentInputValue(e.target.value)}
        onFocus={() => setFocused(true)}
        className={s.ChipInput__input}
        wrapperClassName={s.ChipInput__inputWrapper}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />
    </div>
  );
};
