import React, { ComponentProps, ElementType } from 'react';
import clsx from 'clsx';
import s from './IconButton.module.scss';

export type IconButtonProps<ComponentType extends ElementType> = {
  icon: React.ReactNode;
  component?: ComponentType;
  innerRef?: React.ForwardedRef<ComponentType>;
} & ComponentProps<ComponentType>;

export const IconButton = <ComponentType extends ElementType = 'button'>({
  component = 'button',
  icon,
  className,
  innerRef,
  ...componentProps
}: IconButtonProps<ComponentType>) => {
  const Component = component;
  const Icon = icon;
  return (
    <Component
      ref={innerRef}
      className={clsx(s.IconButton, className)}
      {...componentProps}
    >
      <Icon className={s.IconButton__icon} />
    </Component>
  );
};
