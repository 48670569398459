import { useCallback } from 'react';
import { useMixinClassRef } from '@proscom/ui-react';
import { useCreateOauthOnetimeToken } from '../../../graphql/hooks/useCreateOauthOnetimeToken';
import { OAuthMixin, OAuthServiceRoute, OnOAuthComplete } from './OAuthMixin';

interface CreateEmailAccountWithOAuthOptions {
  serviceRoute: OAuthServiceRoute;
  email?: string;
}

export const useCreateEmailAccountWithOAuth = (
  onComplete?: OnOAuthComplete
) => {
  const { handleCreateOauthOnetimeToken, loading } =
    useCreateOauthOnetimeToken();
  const oauthMixin = useMixinClassRef(() => new OAuthMixin(onComplete));

  const createEmailAccountWithOAuth = useCallback(
    ({ serviceRoute, email }: CreateEmailAccountWithOAuthOptions) => {
      handleCreateOauthOnetimeToken().then((token) =>
        oauthMixin.handleOauth(serviceRoute, { token, email })()
      );
    },
    [handleCreateOauthOnetimeToken, oauthMixin]
  );

  return { createEmailAccountWithOAuth, loading };
};
