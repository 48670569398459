import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Divider } from '../Divider/Divider';
import s from './DropdownItems.module.scss';

export type DropdownItemsList = Array<{
  text: string | JSX.Element;
  onClick: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  focused?: boolean;
  divided?: boolean;
  personalItemClassName?: string;
  hidden?: boolean;
}>;

export interface DropdownItemsProps {
  className?: string;
  itemClassName?: string;
  dividerClassName?: string;
  items: DropdownItemsList;
}

export const DropdownItems = ({
  className,
  itemClassName,
  dividerClassName,
  items
}: DropdownItemsProps) => (
  <div className={clsx(s.DropdownItems, className)}>
    {items
      .filter((item) => !item.hidden)
      .map(
        (
          {
            text,
            onClick,
            onMouseEnter,
            onMouseLeave,
            focused,
            divided,
            personalItemClassName
          },
          i
        ) => (
          <React.Fragment key={i}>
            {divided && <Divider className={dividerClassName} />}
            <div
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className={clsx(
                s.DropdownItems__item,
                focused && s.DropdownItems__item_focused,
                itemClassName,
                personalItemClassName
              )}
            >
              {text}
            </div>
          </React.Fragment>
        )
      )}
  </div>
);
