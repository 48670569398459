import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { FileType } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { MUTATION_UPDATE_FILES_EXPIRATION_DATES } from '../../mutations/letterDrafts';

export interface UseUpdateFilesExpirationDates {
  handleUpdateFilesExpirationDates: (
    ids: number[]
  ) => Promise<FileType[] | undefined>;
  loading: boolean;
}

const UpdateFilesExpirationDatesOptions: MutationOptions<
  {
    updateFilesExpirationDates: FileType[];
  },
  {
    ids: number[];
  }
> = {
  mutation: MUTATION_UPDATE_FILES_EXPIRATION_DATES
};

export const useUpdateFilesExpirationDates =
  (): UseUpdateFilesExpirationDates => {
    const updateFilesExpirationDates = useGraphqlMutation(
      UpdateFilesExpirationDatesOptions
    );
    const updateFilesExpirationDatesRun = updateFilesExpirationDates.run;

    const handleUpdateFilesExpirationDates = useCallback(
      (ids: number[]) =>
        updateFilesExpirationDatesRun({ variables: { ids } }).then(
          ({ data }) => {
            return data?.updateFilesExpirationDates;
          }
        ),
      [updateFilesExpirationDatesRun]
    );
    return {
      handleUpdateFilesExpirationDates,
      loading: updateFilesExpirationDates.loading
    };
  };
