import ApolloClient from 'apollo-client';
import { BehaviorStore, skipIf, SubscriptionManager } from '@proscom/prostore';
import { GraphqlWatchQueryStore } from '@proscom/prostore-apollo';
import { EmailAccountType } from '../graphql/types';
import { QUERY_EMAIL_ACCOUNTS } from '../graphql/queries/emailAccounts';
import { AuthStore } from './AuthStore';

export interface EmailAccountsStoreState {
  emailAccounts: EmailAccountType[];
}

export interface EmailAccountsStoreArgs {
  authStore: AuthStore;
  client: ApolloClient<any>;
}

export interface EmailAccountsQueryVars {
  isLoggedIn: boolean;
}

export interface EmailAccountsQueryData {
  emailAccounts: EmailAccountType[];
}

export class EmailAccountsStore extends BehaviorStore<EmailAccountsStoreState> {
  authStore: AuthStore;
  query: GraphqlWatchQueryStore<EmailAccountsQueryVars, EmailAccountsQueryData>;
  sub = new SubscriptionManager();

  constructor({ authStore, client }: EmailAccountsStoreArgs) {
    super({ emailAccounts: [] });

    this.authStore = authStore;
    this.query = new GraphqlWatchQueryStore<
      EmailAccountsQueryVars,
      EmailAccountsQueryData
    >({
      client,
      query: QUERY_EMAIL_ACCOUNTS,
      mapData: (result?: {
        currentUserEmailAccounts: EmailAccountType[] | null;
      }) => ({ emailAccounts: result?.currentUserEmailAccounts || [] }),
      skipQuery: skipIf((vars) => !vars.isLoggedIn, {
        emailAccounts: []
      }),
      updateData: (data) => data
    });

    this.sub.subscribe(
      this.authStore.state$,
      this.loadEmailAccounts.bind(this)
    );

    this.sub.subscribe(
      this.query.state$,
      this.handleEmailAccountsLoaded.bind(this)
    );
  }

  async loadEmailAccounts() {
    await this.query.loadData({
      isLoggedIn: this.authStore.isLoggedIn()
    });
  }

  handleEmailAccountsLoaded() {
    if (!this.query.state.data) {
      return;
    }

    this.setState({
      emailAccounts: this.query.state.data.emailAccounts
    });
  }
}
