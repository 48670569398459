import React, { useCallback, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { groupBy, sortBy } from 'lodash-es';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { ReactComponent as IconClose } from '../../../../assets/icons/IconCloseThin.svg';
import { getDate } from '../../../../utils/date';
import { ReactComponent as IconAttachment } from '../../../../assets/icons/IconAttachment.svg';
import {
  EmailLetterTag,
  EmailTaskTag,
  FileType
} from '../../../../graphql/types';
import { FileCard } from '../../../../common/components/ui/FileCard/FileCard';
import { useDownloadFile } from '../../../../axios/hooks/files/useDownloadFile';
import { OpenLettersContext } from '../openLettersContext';
import { useOpenInOnlyOffice } from '../../../../common/hooks/tasks/useOpenInOnlyOffice';
import { useCreateFileTaskFromLetterAttachment } from '../../../../graphql/hooks/tasks/useCreateFileTaskFromLetterAttachment';
import { useUrlKey } from '../../../../common/hooks/useUrlKey';
import { URL_KEY_TASK_ID } from '../../../../store/urlKeys';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import s from './TaskFileAttachments.module.scss';

interface TaskFileAttachment extends FileType {
  emailLetterTag?: EmailLetterTag | EmailTaskTag;
  emailLetterId?: string;
  emailLetterDraftId?: string;
}

export interface TaskFileAttachmentsProps {
  className?: string;
  attachments: TaskFileAttachment[];
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  onClick: () => void;
}

export const TaskFileAttachments = ({
  className,
  attachments,
  isModalOpen,
  setModalOpen,
  onClick
}: TaskFileAttachmentsProps) => {
  const openLettersContext = useContext(OpenLettersContext);
  const { downloadFile } = useDownloadFile();

  const text = useMemo(() => {
    const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

    const cases = new Map([
      ['one', 'вложенный файл'],
      ['two', 'вложенных файла'],
      ['few', 'вложенных файла'],
      ['other', 'вложенных файлов']
    ]);

    return cases.get(pr.select(attachments.length));
  }, [attachments]);

  const attachmentGroups = useMemo(
    () =>
      Object.entries(
        groupBy(
          sortBy(
            attachments,
            (attachment) => new Date(attachment.last_modified)
          )
            .slice()
            .reverse(),
          (attachment) => getDate(attachment.last_modified)
        )
      ),
    [attachments]
  );

  const showInLetter = useCallback(
    (attachment: TaskFileAttachment) => {
      if (attachment.emailLetterDraftId) {
        if (attachment.emailLetterId) {
          openLettersContext?.setLetterOpen(attachment.emailLetterId, true);
        }
        openLettersContext?.setScrolledLetterDraftId(
          attachment.emailLetterDraftId
        );
        setModalOpen(false);
      } else if (attachment.emailLetterId) {
        openLettersContext?.setLetterOpen(attachment.emailLetterId, true);
        openLettersContext?.setScrolledLetterId(attachment.emailLetterId);
        setModalOpen(false);
      }
    },
    [setModalOpen, openLettersContext]
  );

  const { openInOnlyOffice } = useOpenInOnlyOffice();

  const [, setCurrentTaskId] = useUrlKey<string | undefined>(URL_KEY_TASK_ID);
  const { handleCreateFileTaskFromLetterAttachment } =
    useCreateFileTaskFromLetterAttachment();

  const createFileTaskFromLetterAttachment = async (attachmentId: number) => {
    try {
      const taskId = await handleCreateFileTaskFromLetterAttachment(
        attachmentId
      );
      setCurrentTaskId(taskId);
      setModalOpen(false);
    } catch (err) {
      handleDefaultError('Произошла ошибка при создании задачи', err);
    }
  };

  return (
    <>
      <div className={clsx(className, s.TaskFileAttachments)} onClick={onClick}>
        <IconAttachment />
        <span className={s.TaskFileAttachments__text}>
          {attachments.length} {text}
        </span>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        className={s.TaskFileAttachmentsModal}
        disableDefaultStyles
      >
        <IconClose
          className={s.TaskFileAttachmentsModal__close}
          onClick={() => setModalOpen(false)}
        />
        <div className={s.TaskFileAttachmentsModal__title}>
          {attachments.length} {text}
        </div>
        <div className={s.TaskFileAttachmentsModal__content}>
          {attachmentGroups.map(([date, attachments], index) => (
            <React.Fragment key={index}>
              <div className={s.TaskFileAttachmentsModal__date}>{date}</div>
              {attachments.map((attachment, index) => (
                <FileCard
                  key={index}
                  className={s.TaskFileAttachmentsModal__attachment}
                  name={attachment.file_name}
                  size={attachment.size}
                  emailLetterTag={attachment.emailLetterTag}
                  lastTimeModified={attachment.last_modified}
                  onClickName={
                    attachment.canBeOpenedInOnlyOffice
                      ? () => openInOnlyOffice(parseInt(attachment.id))
                      : undefined
                  }
                  dropdownItems={[
                    {
                      text: 'Посмотреть в письме',
                      onClick: () => showInLetter(attachment)
                    },
                    {
                      text: 'Скачать',
                      onClick: () => {
                        downloadFile(
                          parseInt(attachment.id),
                          attachment.file_name
                        );
                      }
                    },
                    {
                      text: 'Создать задачу',
                      onClick: () =>
                        createFileTaskFromLetterAttachment(
                          parseInt(attachment.id)
                        )
                    },
                    {
                      text: 'Открыть в OnlyOffice',
                      hidden: !attachment.canBeOpenedInOnlyOffice,
                      onClick: () => openInOnlyOffice(parseInt(attachment.id))
                    }
                  ]}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </Modal>
    </>
  );
};
