import React, { useState } from 'react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { format, isBefore, startOfMonth, startOfYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import { upperFirst } from 'lodash-es';
import { TasksFilterDay, TasksStoreState } from '../../../store/TasksStore';
import { STORE_CALENDAR_OPEN, STORE_TASKS } from '../../../store/storeKeys';
import { ReactComponent as IconCancel } from '../../../assets/icons/IconCancel.svg';

import { CalendarOpenStore } from '../../../store/CalendarOpenStore';
import { useResetFilters } from '../../../common/hooks/useResetFilters';
import { ReactComponent as ResetIcon } from '../../../assets/icons/IconReset.svg';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import { CalendarYearItem } from './CalendarItem';
import s from './Calendar.module.scss';

export interface CalendarTree {
  years: Array<{
    date: Date;
    label: string;
    months: Array<{
      date: Date;
      label: string;
      days: Array<{
        date: Date;
        label: string;
        tasksCount: number;
        selected: boolean;
      }>;
    }>;
  }>;
}

const createCalendarTree = (days: TasksFilterDay[]): CalendarTree => {
  const tree: CalendarTree = { years: [] };
  for (const day of days) {
    const date = new Date(day.dateString);
    const yearLabel = format(date, 'yyyy');
    const monthLabel = upperFirst(format(date, 'LLLL', { locale: ru }));
    const dayData = {
      date: date,
      label: `${format(date, 'dd')} ${upperFirst(
        format(date, 'EEEE', { locale: ru })
      )}`,
      tasksCount: day.tasksCount,
      selected: day.selected || false
    };
    const year = tree.years.find((year) => year.label === yearLabel);
    if (year) {
      const month = year.months.find((month) => month.label === monthLabel);
      if (month) {
        month.days.push(dayData);
      } else {
        year.months.push({
          label: monthLabel,
          date: startOfMonth(date),
          days: [dayData]
        });
      }
    } else {
      tree.years.push({
        date: startOfYear(date),
        label: yearLabel,
        months: [
          { date: startOfMonth(date), label: monthLabel, days: [dayData] }
        ]
      });
    }
  }

  for (const year of tree.years) {
    for (const month of year.months) {
      month.days = month.days.sort((dayA, dayB) =>
        isBefore(dayA.date, dayB.date) ? 1 : -1
      );
    }
    year.months = year.months.sort((monthA, monthB) =>
      isBefore(monthA.date, monthB.date) ? 1 : -1
    );
  }
  return {
    years: tree.years.sort((yearA, yearB) =>
      isBefore(yearA.date, yearB.date) ? 1 : -1
    )
  };
};

export const Calendar = () => {
  const { daysFilter } = useStoreState<TasksStoreState>(STORE_TASKS);
  const calendarTree = createCalendarTree(daysFilter);

  const resetFilters = useResetFilters();
  const [, calendarStore] = useStore<CalendarOpenStore>(STORE_CALENDAR_OPEN);
  const [filtersReseted, setFiltersReseted] = useState<boolean>(false);

  const handleResetFilters = () => {
    resetFilters();
    setFiltersReseted(true);
  };

  return (
    <div className={s.Calendar}>
      <IconButton
        icon={IconCancel}
        onClick={() => calendarStore.toggleOpen()}
        className={s.Calendar__closeButton}
      />
      <div className={s.Calendar__header}>
        <div
          onClick={() => window.location.replace('/')}
          className={s.Calendar__title}
        >
          NeTupi
        </div>
        <div onClick={handleResetFilters} className={s.Calendar__reset}>
          <ResetIcon className={s.Calendar__resetIcon} />
          Сбросить
        </div>
      </div>
      <div className={s.Calendar__items}>
        {calendarTree.years.map((year, i) => (
          <CalendarYearItem
            key={i}
            {...year}
            filtersReseted={filtersReseted}
            setFiltersReseted={setFiltersReseted}
          />
        ))}
      </div>
    </div>
  );
};
