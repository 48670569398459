import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import s from './Divider.module.scss';

export enum DividerColor {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY'
}
export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  color?: DividerColor;
}

export const Divider = ({
  color = DividerColor.TERTIARY,
  className,
  ...props
}: DividerProps) => (
  <hr
    className={clsx(
      s.Divider,
      color === DividerColor.PRIMARY && s.Divider_primary,
      color === DividerColor.SECONDARY && s.Divider_secondary,
      color === DividerColor.TERTIARY && s.Divider_tertiary,
      className
    )}
    {...props}
  />
);
