import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { isEqual } from 'lodash-es';
import {
  StorageValueState,
  WebStorageValueStore
} from '@proscom/prostore-local-storage';
import { SingleTimeoutManager, singleton } from '@proscom/ui-utils';
import { tryParseIso } from '@proscom/ui-utils-date';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory/lib/types';
import { distinctUntilChanged } from 'rxjs/operators';
import { isBefore, isValid } from 'date-fns';
import { FetchResult } from 'apollo-link';
import {
  AuthResponseType,
  UserAuthTokenType,
  UserType
} from '../graphql/types';
import {
  MUTATION_LOGIN,
  MUTATION_LOGOUT,
  MUTATION_USE_REFRESH_TOKEN
} from '../graphql/mutations/auth';
import { handleDefaultError } from '../utils/handleDefaultError';
import { tryParseGrqphqlErrorCodes } from '../utils/tryParseGrqphqlErrorCodes';
import { defaultRequestError } from '../common/consts';
import { LOCAL_STORAGE_KEY_AUTH } from './storageKeys';

export enum AuthStoreErrorEnum {
  REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID'
}

export interface AuthInfo {
  accessToken?: string | null;
  refreshToken?: UserAuthTokenType | null;
  user?: UserType | null;
}

export interface AuthStoreArgs {
  localStorage: Storage;
  client: any;
}

export interface AuthStoreState {
  authData: AuthInfo | null;
  loaded: boolean;
  error: AuthStoreErrorEnum | null;
}

const clearState: AuthInfo = {
  accessToken: null,
  refreshToken: null,
  user: null
};

export class AuthStore extends BehaviorStore<AuthStoreState> {
  client: ApolloClient<NormalizedCacheObject>;
  sub = new SubscriptionManager();
  refreshTimeout = new SingleTimeoutManager();
  authData: WebStorageValueStore<AuthInfo>;

  constructor({ localStorage, client }: AuthStoreArgs) {
    super({
      authData: null,
      loaded: false,
      error: null
    });

    this.authData = new WebStorageValueStore<AuthInfo>(
      localStorage,
      LOCAL_STORAGE_KEY_AUTH,
      (authData) => {
        return authData &&
          typeof authData === 'object' &&
          (authData.user ||
            (authData.refreshToken &&
              isRefreshTokenValid(authData.refreshToken)))
          ? authData
          : clearState;
      }
    );

    this.client = client;
  }

  registerListener() {
    // this.authData.registerListener();
    this.sub.subscribe(
      this.authData.state$.pipe(distinctUntilChanged(isEqual)),
      this._handleAuthDataChange
    );
  }

  unregisterListener() {
    this.authData.destroy();
    this.sub.destroy();
    this.refreshTimeout.clear();
  }

  _handleAuthDataChange = (state: StorageValueState<AuthInfo>) => {
    this.setState({
      authData: state.value,
      loaded: state.loaded,
      error: this._deriveError(state)
    });
    this._handleRefreshTimeout(state);
  };

  _handleRefreshTimeout = (state: StorageValueState<AuthInfo>) => {
    const { value: authData, loaded } = state;
    if (
      loaded &&
      authData &&
      authData.refreshToken &&
      authData.refreshToken.expires_at
    ) {
      const expiresAt = tryParseIso(authData.refreshToken.expires_at);
      if (expiresAt) {
        const timeout = Math.max(
          expiresAt.getTime() - new Date().getTime() - 60000,
          0
        );
        this.refreshTimeout.set(() => {
          this.refreshToken().catch((e) => {
            handleDefaultError(
              e,
              'Произошла ошибка сессии. Пожалуйста, войдите ещё раз'
            );
          });
        }, timeout);
      } else {
        this.refreshTimeout.clear();
      }
    } else {
      this.refreshTimeout.clear();
    }
  };

  _deriveError = (state: StorageValueState<AuthInfo>) => {
    const { value: authData, loaded } = state;
    if (loaded) {
      if (
        !authData ||
        !authData.refreshToken ||
        !isRefreshTokenValid(authData.refreshToken)
      ) {
        return AuthStoreErrorEnum.REFRESH_TOKEN_INVALID;
      }
    }
    return null;
  };

  _setAuthenticationData(authData: AuthInfo) {
    this.authData.setValue({
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      user: authData.user
    });
  }

  _setError() {
    this._setAuthenticationData({
      refreshToken: null,
      accessToken: null,
      user: this.state.authData?.user
    });
  }

  async _useRefreshToken(refreshToken: UserAuthTokenType) {
    try {
      const result = await this.client.mutate<
        { authData: AuthResponseType },
        { token: string }
      >({
        mutation: MUTATION_USE_REFRESH_TOKEN,
        variables: {
          token: refreshToken.token
        }
      });

      if (!result || !result.data) {
        throw new Error('Unexpected refreshToken response result');
      }

      const authData = result.data.authData;
      this._setAuthenticationData(authData);
      return authData;
    } catch (error: any) {
      handleDefaultError(error, 'Произошла ошибка при продлении сессии');
      this._setError();
      return null;
    }
  }

  async _performTokenRefresh() {
    const { refreshToken } = this.state.authData || {};

    if (!isRefreshTokenValid(refreshToken)) {
      this._setError();
      return;
    }

    return await this._useRefreshToken(refreshToken);
  }

  refreshToken = singleton(() => this._performTokenRefresh());

  isRefreshingToken() {
    return !!this.refreshToken.promise;
  }

  canRefreshToken() {
    const { authData } = this.state;
    return isRefreshTokenValid(authData && authData.refreshToken);
  }

  isLoggedIn() {
    const { authData } = this.state;
    return !!(this.state.loaded && authData && authData.accessToken);
  }

  /**
   * На случай если в момент вызова операции вызывался рефреш токена,
   * мы его дождёмся, чтобы не было гонок между асинхронными функциями
   *
   * (мб это можно как-то через rxjs накрутить чтобы оно само работало?)
   */
  _awaitRefreshingToken = async () => {
    try {
      await this.refreshToken.promise;
    } catch (e) {}
  };

  logOut = async () => {
    try {
      await this._awaitRefreshingToken();
      const { authData } = this.state;
      const token = authData?.refreshToken?.token;
      let result: null | FetchResult<{ success: boolean }> = null;

      if (token) {
        result = await this.client.mutate<
          { success: boolean },
          { token: string }
        >({
          mutation: MUTATION_LOGOUT,
          variables: {
            token
          }
        });
      }

      return result;
    } catch (error: any) {
      handleDefaultError(
        error,
        'Произошла ошибка при выходе. Пожалуйста, обновите страницу'
      );
      return { error };
    } finally {
      this._setAuthenticationData(clearState);
    }
  };

  login = async (username: string, password: string) => {
    await this._awaitRefreshingToken();

    let result;
    let errors;

    try {
      result = await this.client.mutate<
        { authData: AuthResponseType },
        { username: string; password: string }
      >({
        mutation: MUTATION_LOGIN,
        variables: {
          username,
          password
        }
      });
    } catch (e: any) {
      errors = e?.graphQLErrors;
    }

    if (errors) {
      if (tryParseGrqphqlErrorCodes(errors).find((code) => code === 401)) {
        throw new Error(
          'Неверно указаны логин или пароль. Попробуйте еще раз или восстановите пароль'
        );
      } else {
        throw new Error(defaultRequestError);
      }
    }

    const authData = result.data?.authData;
    if (!authData) {
      this._setError();
      throw new Error(defaultRequestError);
    }

    this._setAuthenticationData(authData);
    return authData;
  };
}

/**
 * Проверка устаревания долгосрочного токена
 */
export function isRefreshTokenValid(
  refreshToken
): refreshToken is UserAuthTokenType {
  if (!refreshToken || !refreshToken.token || !refreshToken.expires_at) {
    return false;
  }
  const expirationDate = tryParseIso(refreshToken.expires_at);
  return !!(
    expirationDate &&
    isValid(expirationDate) &&
    isBefore(new Date(), expirationDate)
  );
}
