import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import { MUTATION_DELETE_COMMENT } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseDeleteCommentReturn {
  handleDeleteComment: (logStoryId: string) => Promise<TaskType | undefined>;
  loading: boolean;
}

const DeleteCommentMutationOptions: MutationOptions<
  {
    deleteComment: TaskType;
  },
  {
    logStoryId: string;
  }
> = {
  mutation: MUTATION_DELETE_COMMENT
};

export const useDeleteComment = (): UseDeleteCommentReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const deleteComment = useGraphqlMutation(DeleteCommentMutationOptions);
  const deleteCommentRun = deleteComment.run;
  const handleDeleteComment = useCallback(
    (logStoryId: string) =>
      deleteCommentRun({ variables: { logStoryId } }).then(({ data }) => {
        const task = data?.deleteComment;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
        return task;
      }),
    [deleteCommentRun, tasksStore]
  );
  return {
    handleDeleteComment,
    loading: deleteComment.loading
  };
};
