import React, {
  ChangeEvent,
  HTMLAttributes,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { History } from 'history';
import { useFormik } from 'formik';
import { useStore } from '@proscom/prostore-react';
import { AuthStore } from '../../../../store/AuthStore';
import { STORE_AUTH } from '../../../../store/storeKeys';
import { Window } from '../Window/Window';
import { Input } from '../Input/Input';
import { Header } from '../Header/Header';
import { Button } from '../Button/Button';
import { Link as LinkComponent } from '../Link/Link';
import { useRecoverPassword } from '../../../../graphql/hooks/useRecoverPassword';
import { validateEmail } from '../../../../utils/validate';
import { defaultRequestError } from '../../../consts';
import s from './AuthForm.module.scss';

export interface IRecoverPasswordFormData {
  fullName: string;
  position: string;
  email: string;
  comment?: string;
}

export interface RecoverPasswordFormProps
  extends HTMLAttributes<HTMLDivElement> {
  history: History;
}

export const RecoverPasswordForm = ({
  history,
  className,
  ...props
}: RecoverPasswordFormProps) => {
  const [authStoreState, authStore] = useStore<AuthStore>(STORE_AUTH);

  useEffect(() => {
    if (authStore.isLoggedIn()) {
      history.push('/');
    }
  }, [authStoreState, authStore, history]);

  const { handleRecoverPassword } = useRecoverPassword();
  const [recoverPasswordError, setRecoverPasswordError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const formik = useFormik<IRecoverPasswordFormData>({
    initialValues: {
      fullName: '',
      position: '',
      email: '',
      comment: ''
    },
    onSubmit: () => {
      handleRecoverPassword(formik.values)
        .then((result) =>
          result
            ? setSuccess(true)
            : setRecoverPasswordError(defaultRequestError)
        )
        .catch(() => setRecoverPasswordError(defaultRequestError));
    },
    validate: ({ email }) =>
      !validateEmail(email) ? { email: 'Не похоже на email' } : {},
    validateOnChange: false
  });
  const handleChange = (value: string, e: ChangeEvent) =>
    formik.handleChange(e);

  const canSubmit = !!(
    formik.values.fullName &&
    formik.values.position &&
    formik.values.email
  );
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) =>
    canSubmit && e.key === 'Enter' && formik.handleSubmit();

  const isLoading = useMemo(
    () => formik.isSubmitting && !recoverPasswordError,
    [formik, recoverPasswordError]
  );

  return (
    <Window header="АВТОРИЗАЦИЯ" {...props}>
      {!success && (
        <LinkComponent
          component={Link}
          to="/login"
          className={clsx(s.AuthForm__link, s.RecoverPasswordForm__backLink)}
        >
          {'< НАЗАД'}
        </LinkComponent>
      )}
      <Header className={s.AuthForm__header}>
        {success ? 'Данные успешно отправлены' : 'Восстановление пароля'}
      </Header>
      <div className={s.AuthForm__description}>
        {success
          ? 'Пока что мы обрабатываем запросы в ручном режиме, поэтому ответ может занять до 2-х рабочих дней'
          : 'Заполните поля, чтобы мы смогли восстановить ваши данные'}
      </div>
      {success ? (
        <Button component={Link} to="login" className={s.AuthForm__button}>
          ВСЕ ПОНЯТНО
        </Button>
      ) : (
        <>
          <Input
            name="fullName"
            value={formik.values.fullName}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="ФИО:\>"
            className={s.AuthForm__input}
          />
          <Input
            name="position"
            value={formik.values.position}
            onChange={handleChange}
            label="Должность:\>"
            className={s.AuthForm__input}
          />
          <Input
            name="email"
            value={formik.values.email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="Почта для связи:\>"
            error={formik.errors.email}
            className={clsx(
              s.AuthForm__input,
              formik.errors.email && s.AuthForm__input_error
            )}
          />
          <Input
            name="comment"
            value={formik.values.comment}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="Комментарий (необязательно):\>"
            className={s.AuthForm__input}
          />
          {recoverPasswordError && (
            <div className={s.AuthForm__error}>{recoverPasswordError}</div>
          )}
          <Button
            className={s.AuthForm__button}
            onClick={() => formik.handleSubmit()}
            loading={isLoading}
            disabled={!canSubmit}
          >
            ОТПРАВИТЬ
          </Button>
        </>
      )}
    </Window>
  );
};
