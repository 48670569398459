import gql from 'graphql-tag';

export const MUTATION_CREATE_TAG = gql`
  mutation mutationCreateTag($input: TagCreateInput!) {
    createTag(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_TAG = gql`
  mutation mutationDeleteTag($tagId: ID!) {
    deleteTag(tagId: $tagId)
  }
`;

export const MUTATION_UPDATE_TAG = gql`
  mutation mutationUpdateTag($input: TagUpdateInput!) {
    updateTag(input: $input) {
      id
      name
    }
  }
`;
