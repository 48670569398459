import { useLocationQuery } from '@proscom/prostore-react-router';
import { useCallback } from 'react';
import { STORE_LOCATION } from '../../store/storeKeys';

export const useUrlKey: <T>(
  key: string
) => [value: T, setValue: (newValue: T) => void] = (key) => {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [key]);

  const changeQuery = useCallback(
    (newValue) => locationStore.changeQuery({ [key]: newValue }, true),
    [key, locationStore]
  );

  return [query[key], changeQuery];
};
