import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useFormik } from 'formik';
import { SecurityProtocol } from '../../../../../graphql/types';
import {
  Modal,
  ModalProps
} from '../../../../../common/components/ui/Modal/Modal';
import {
  Header,
  HeaderSize
} from '../../../../../common/components/ui/Header/Header';
import {
  Input,
  MaskedInput
} from '../../../../../common/components/ui/Input/Input';
import {
  ISelectOption,
  Select
} from '../../../../../common/components/ui/Select/Select';
import { Checkbox } from '../../../../../common/components/ui/Checkbox/Checkbox';
import {
  Button,
  ButtonVariant
} from '../../../../../common/components/ui/Button/Button';
import { validateCustomEmailAccountData } from './validateCustomEmailAccountData';
import s from './EmailAccountSettingModal.module.scss';

export interface IEmailAccountSettingFormData {
  email: string;
  name: string;
  imap_host: string;
  imap_port: string;
  imap_login: string;
  imap_password: string;
  imap_security_protocol: SecurityProtocol;
  smtp_host: string;
  smtp_port: string;
  smtp_login: string;
  smtp_password: string;
  smtp_security_protocol: SecurityProtocol;
}

const clearFormData = {
  email: '',
  name: '',
  imap_host: '',
  imap_port: '',
  imap_login: '',
  imap_password: '',
  imap_security_protocol: SecurityProtocol.Ssl,
  smtp_host: '',
  smtp_port: '',
  smtp_login: '',
  smtp_password: '',
  smtp_security_protocol: SecurityProtocol.Ssl
};

export interface IEmailAccountSettingFormErrors {
  email?: string;
  name?: string;
  imap_host?: string;
  imap_port?: string;
  imap_login?: string;
  imap_password?: string;
  smtp_host?: string;
  smtp_port?: string;
  smtp_login?: string;
  smtp_password?: string;
}

export type ISecurityProtocolSelectOption = ISelectOption<SecurityProtocol>;
export const securityProtocolSelectOptions: ISecurityProtocolSelectOption[] = [
  {
    label: 'SSL',
    value: SecurityProtocol.Ssl
  },
  {
    label: 'TLS',
    value: SecurityProtocol.Tls
  }
];

export interface EmailAccountSettingModalProps
  extends Omit<ModalProps, 'header'> {
  handleSubmit: (data: IEmailAccountSettingFormData) => any;
  submitButtonText?: string;
  newAccountForm?: boolean;
  initialValues?: Partial<IEmailAccountSettingFormData>;
  submitLoading?: boolean;
}

export const EmailAccountSettingModal = ({
  handleSubmit,
  onClose,
  submitButtonText = 'Подключить',
  newAccountForm = true,
  initialValues,
  submitLoading,
  ...props
}: EmailAccountSettingModalProps) => {
  const [submitError, setSubmitError] = useState<string>('');
  const [imapAndSmtpDataAreSame, setImapAndSmtpDataAreSame] =
    useState<boolean>(true);

  const formik = useFormik<IEmailAccountSettingFormData>({
    initialValues: { ...clearFormData, ...initialValues },
    onSubmit: () => {
      const values = formik.values;
      if (imapAndSmtpDataAreSame) {
        values.smtp_login = formik.values.imap_login;
        values.smtp_password = formik.values.imap_password;
      }
      handleSubmit(values)
        .then(() => formik.setValues(clearFormData))
        .catch((e) => {
          const errorData = e.extensions?.exception?.response;
          let errorMessage =
            errorData?.errorSource === 'SMTP'
              ? 'Ошибка SMTP подключения'
              : errorData?.errorSource === 'IMAP'
              ? 'Ошибка IMAP подключения'
              : 'Ошибка подключения';
          if (errorData?.email) {
            errorMessage += ` почтового аккаунта ${errorData.email}`;
          }
          if (errorData?.message) {
            errorMessage += `: ${errorData.message}`;
          }
          setSubmitError(errorMessage);
        });
    },
    validate: () => {
      const values = formik.values;
      if (imapAndSmtpDataAreSame) {
        values.smtp_login = formik.values.imap_login;
        values.smtp_password = formik.values.imap_password;
      }
      return validateCustomEmailAccountData(values, newAccountForm);
    },
    validateOnChange: false
  });
  const handleChange = (value: string, e: ChangeEvent) =>
    formik.handleChange(e);
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && formik.handleSubmit();
  const handleClose = () => {
    formik.setValues({});
    formik.setErrors({});
    setSubmitError('');
    onClose?.();
  };

  return (
    <Modal header="НАСТРОЙКА ПОЧТЫ" onClose={handleClose} {...props}>
      <Header className={s.EmailAccountSettingModal__header}>
        Ручная настройка
      </Header>
      <div className={s.EmailAccountSettingModal__description}>
        Введите данные своей учетной записи
      </div>
      <Input
        name="email"
        value={formik.values.email}
        error={formik.errors.email}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label="Адрес email:\>"
        required={true}
        className={s.EmailAccountSettingModal__input}
      />
      <Input
        name="name"
        value={formik.values.name}
        error={formik.errors.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label="Имя учетной записи:\>"
        required={true}
        className={s.EmailAccountSettingModal__input}
      />
      <Header
        size={HeaderSize.h2}
        className={s.EmailAccountSettingModal__blockHeader}
      >
        Получение почты
      </Header>
      <div className={s.EmailAccountSettingModal__hostPortInputsContainer}>
        <Input
          name="imap_host"
          value={formik.values.imap_host}
          error={formik.errors.imap_host}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          label="Хост IMAP:\>"
          required={true}
          className={s.EmailAccountSettingModal__hostInput}
        />
        <MaskedInput
          name="imap_port"
          value={formik.values.imap_port}
          error={formik.errors.imap_port}
          onChange={handleChange}
          onKeyDownCapture={handleKeyDown}
          label="Порт IMAP:\>"
          required={true}
          mask="1111"
          className={s.EmailAccountSettingModal__portInput}
        />
      </div>
      <Input
        name="imap_login"
        value={formik.values.imap_login}
        error={formik.errors.imap_login}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label="Логин:\>"
        required={true}
        className={s.EmailAccountSettingModal__input}
      />
      <Input
        name="imap_password"
        value={formik.values.imap_password}
        error={formik.errors.imap_password}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label="Пароль:\>"
        required={newAccountForm}
        type="password"
        className={s.EmailAccountSettingModal__input}
      />
      <Select
        name="imap_security_protocol"
        value={securityProtocolSelectOptions.find(
          (option) => option.value === formik.values.imap_security_protocol
        )}
        onChange={(option) =>
          formik.setFieldValue(
            'imap_security_protocol',
            (option as ISecurityProtocolSelectOption).value
          )
        }
        isSearchable={false}
        options={securityProtocolSelectOptions}
        label="Протокол защиты IMAP:\>"
        placeholder=""
      />
      <Header
        size={HeaderSize.h2}
        className={s.EmailAccountSettingModal__blockHeader}
      >
        Отправка почты
      </Header>
      <div className={s.EmailAccountSettingModal__hostPortInputsContainer}>
        <Input
          name="smtp_host"
          value={formik.values.smtp_host}
          error={formik.errors.smtp_host}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          label="Хост SMTP:\>"
          required={true}
          className={s.EmailAccountSettingModal__hostInput}
        />
        <MaskedInput
          name="smtp_port"
          value={formik.values.smtp_port}
          error={formik.errors.smtp_port}
          onChange={handleChange}
          onKeyDownCapture={handleKeyDown}
          label="Порт SMTP:\>"
          required={true}
          mask="1111"
          className={s.EmailAccountSettingModal__portInput}
        />
      </div>
      <Checkbox
        checked={imapAndSmtpDataAreSame}
        onChange={(e) => setImapAndSmtpDataAreSame(e.target.checked)}
        label="Логин и пароль совпадают с данными для получения почты"
        className={s.EmailAccountSettingModal__input}
      />
      {!imapAndSmtpDataAreSame && (
        <>
          <Input
            name="smtp_login"
            value={formik.values.smtp_login}
            error={formik.errors.smtp_login}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="Логин:\>"
            required={true}
            className={s.EmailAccountSettingModal__input}
          />
          <Input
            name="smtp_password"
            value={formik.values.smtp_password}
            error={formik.errors.smtp_password}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="Пароль:\>"
            required={newAccountForm}
            type="password"
            className={s.EmailAccountSettingModal__input}
          />
        </>
      )}
      <Select
        name="smtp_security_protocol"
        value={securityProtocolSelectOptions.find(
          (option) => option.value === formik.values.smtp_security_protocol
        )}
        onChange={(option) =>
          formik.setFieldValue(
            'smtp_security_protocol',
            (option as ISecurityProtocolSelectOption).value
          )
        }
        isSearchable={false}
        options={securityProtocolSelectOptions}
        label="Протокол защиты SMTP:\>"
        placeholder=""
      />
      {submitError && (
        <div className={s.EmailAccountSettingModal__error}>{submitError}</div>
      )}
      <div className={s.EmailAccountSettingModal__buttonContainer}>
        <Button
          variant={ButtonVariant.TERTIARY}
          className={s.EmailAccountSettingModal__button}
          onClick={handleClose}
        >
          Отменить
        </Button>
        <Button
          className={s.EmailAccountSettingModal__button}
          onClick={() => formik.handleSubmit()}
          loading={submitLoading}
        >
          {submitButtonText}
        </Button>
      </div>
    </Modal>
  );
};
