import { format } from 'date-fns';

export const createForwardedLetterHTML = (
  date: Date,
  from: string,
  html: string
) =>
  `<br/>
  <p>
    -------- Пересылаемое сообщение --------
    <br/>
    ${format(date, 'yyyy-MM-dd, HH:mm')}, ${from}:
  </p>
  <p>${html}</p>
  <p>-------- Конец пересылаемого сообщения --------</p>
  `;
