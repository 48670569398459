import { useMemo } from 'react';
import clsx from 'clsx';
import s from './CircleProgress.module.scss';

export interface CircleProgressProps {
  className?: string;
  value: number;
  size?: number;
  borderSize?: number;
  color?: string;
  backgroundColor?: string;
}

const defaultProps = {
  size: 22,
  borderSize: 2,
  color: '#ffffff',
  backgroundColor: 'rgba(224, 230, 239, 0.17)'
};

export const CircleProgress = ({
  className,
  value,
  size = defaultProps.size,
  borderSize = defaultProps.borderSize,
  color = defaultProps.color,
  backgroundColor = defaultProps.backgroundColor
}: CircleProgressProps) => {
  const progress = useMemo(() => 100 - value * 100, [value]);

  const containerStyle = {
    width: size,
    height: size
  };

  const backgroundStyle = {
    stroke: backgroundColor,
    strokeWidth: borderSize
  };

  const progressStyle = {
    stroke: color,
    strokeWidth: borderSize,
    strokeDashoffset: progress
  };

  return (
    <svg
      className={clsx(s.CircleProgress, className)}
      viewBox="2 -1 28 34"
      xmlns="http://www.w3.org/2000/svg"
      style={containerStyle}
    >
      <circle
        className={s.CircleProgress__background}
        r="16"
        cx="16"
        cy="16"
        style={backgroundStyle}
      ></circle>
      <circle
        className={s.CircleProgress__progress}
        r="16"
        cx="16"
        cy="16"
        style={progressStyle}
      ></circle>
    </svg>
  );
};
