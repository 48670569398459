import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType, CreateReplyDraftInput } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import {
  MUTATION_CREATE_REPLY_DRAFT
} from '../../mutations/letterDrafts';

export interface UseCreateReplyDraftReturn {
  handleCreateReplyDraft: (input: CreateReplyDraftInput) => Promise<void>;
  loading: boolean;
}

const CreateReplyDraftMutationOptions: MutationOptions<
  {
    createReplyDraft: TaskType;
  },
  {
    input: CreateReplyDraftInput;
  }
> = {
  mutation: MUTATION_CREATE_REPLY_DRAFT
};

export const useCreateReplyDraft = (): UseCreateReplyDraftReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const createReplyDraft = useGraphqlMutation(CreateReplyDraftMutationOptions);
  const createReplyDraftRun = createReplyDraft.run;

  const handleCreateReplyDraft = useCallback(
    (input: CreateReplyDraftInput) =>
      createReplyDraftRun({ variables: { input } }).then(({ data }) => {
        const task = data?.createReplyDraft;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [createReplyDraftRun, tasksStore]
  );
  return {
    handleCreateReplyDraft,
    loading: createReplyDraft.loading
  };
};
