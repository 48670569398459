import { stringify } from 'query-string';
import { clean } from '../../../utils/objects';
import { oauthOrigin, oauthUrl } from '../../../config';
import { handleDefaultError } from '../../../utils/handleDefaultError';

export enum OAuthServiceRoute {
  GOOGLE = 'google',
  YANDEX = 'yandex'
}

export type BuildOauthUrl = (options: {
  route: OAuthServiceRoute;
  queryParams: Record<string, any>;
}) => string;

export enum OAuthCompleteStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}
export enum OAuthError {
  EMAIL_ACCOUNT_ALREADY_ADDED = 'EMAIL_ACCOUNT_ALREADY_ADDED',
  EMAIL_ACCOUNT_LINKED_TO_ANOTHER_ACCOUNT = 'EMAIL_ACCOUNT_LINKED_TO_ANOTHER_ACCOUNT',
  SMTP_CONNECTION = 'SMTP_CONNECTION',
  IMAP_CONNECTION = 'IMAP_CONNECTION',
  EMAILS_DONT_MATCH = 'EMAILS_DONT_MATCH'
}

export interface OAuthErrorData {
  error?: OAuthError;
  data?: {
    email?: string;
    message?: string;
  };
}

export interface OAuthCompleteData extends OAuthErrorData {
  status: OAuthCompleteStatus;
}

export type OnOAuthComplete = (data: OAuthCompleteData) => any;

export class OAuthMixin {
  popup: Window | null = null;
  onComplete?: OnOAuthComplete;

  constructor(onComplete?: OnOAuthComplete) {
    this.onComplete = onComplete;
  }

  onWillUnmount(): void {
    this.removeMessageHandler();
  }

  registerMessageHandler = (): void => {
    window.addEventListener('message', this.handleOauthComplete);
  };

  removeMessageHandler = (): void => {
    window.removeEventListener('message', this.handleOauthComplete);
  };

  buildOauthUrl: BuildOauthUrl = ({ route, queryParams }) => {
    const query = stringify(
      clean({
        ...queryParams,
        origin: oauthOrigin
      })
    );
    return oauthUrl + '/oauth/' + route + '?' + query;
  };

  handleOauth = (route: OAuthServiceRoute, queryParams = {}): (() => void) => {
    return () => {
      const oauthUrl = this.buildOauthUrl({
        route,
        queryParams
      });

      this.popup = window.open(oauthUrl, `Oauth`, 'width=800,height=600');

      this.registerMessageHandler();
    };
  };

  handleOauthComplete = async (e) => {
    try {
      if (this.popup) {
        this.popup.close();
        this.popup = null;
      }
      this.removeMessageHandler();

      await this.onComplete?.(e.data);
    } catch (e: any) {
      handleDefaultError(e, 'Произошла ошибка входа. Попробуйте снова');
    }
  };
}
