import { ValueStore } from '@proscom/prostore';

export class AddingNewTagStore extends ValueStore<boolean> {
  constructor() {
    super(false);
  }

  setAddingNewTag(addingNewTag: boolean) {
    this.setState(addingNewTag);
  }
}
