import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconEmptyList } from '../../../../assets/icons/IconEmptyList.svg';
import { Card } from '../../../../common/components/ui/Card/Card';
import { Loader } from '../../../../common/components/ui/Loader/Loader';
import s from './EmptyListPlaceholder.module.scss';

export interface EmptyListPlaceholderProps {
  className?: string;
  text?: string;
  icon?: React.ElementType;
  loading?: boolean;
}

export const EmptyListPlaceholder = ({
  className,
  text = 'В этом списке списке пока пусто',
  icon: Icon = IconEmptyList,
  loading
}: EmptyListPlaceholderProps) => (
  <Card className={clsx(s.EmptyListPlaceholder, className)}>
    {loading ? (
      <Loader />
    ) : (
      <>
        <div className={s.EmptyListPlaceholder__emptyIconWrap}>
          <Icon className={s.EmptyListPlaceholder__emptyIcon} />
        </div>
        {text}
      </>
    )}
  </Card>
);
