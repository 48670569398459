import React, { useState } from 'react';
import { Input } from '../../../../common/components/ui/Input/Input';
import { Button } from '../../../../common/components/ui/Button/Button';
import { Divider } from '../../../../common/components/ui/Divider/Divider';
import { loaderColors } from '../../../../common/components/ui/Loader/Loader';
import s from './TaskCardAcceptTaskPrompt.module.scss';

export interface TaskCardAcceptTaskPromptProps {
  className?: string;
  onRespondToTaskTransfer: (
    acceptTransfer: boolean,
    rememberChoiceForCurrentTransferer: boolean
  ) => void;
  respondToTaskTransferLoading: boolean;
  currentTransferAcceptance: boolean | null;
}

export const TaskCardAcceptTaskPrompt = ({
  className,
  onRespondToTaskTransfer,
  respondToTaskTransferLoading,
  currentTransferAcceptance
}: TaskCardAcceptTaskPromptProps) => {
  const [isAnswerSaved, setAnswerSaved] = useState(false);

  const handleCheckboxChange = (_, e: React.ChangeEvent<HTMLInputElement>) =>
    setAnswerSaved(e.target.checked);
  const handleDecline = () => onRespondToTaskTransfer(false, isAnswerSaved);
  const handleAccept = () => onRespondToTaskTransfer(true, isAnswerSaved);

  return (
    <div className={className}>
      <p className={s.TaskCardAcceptTaskPrompt__question}>
        Хотите принять эту задачу?
      </p>
      <label className={s.TaskCardAcceptTaskPrompt__saveLabel}>
        <Input
          type="checkbox"
          className={s.TaskCardAcceptTaskPrompt__saveCheckbox}
          defaultChecked={isAnswerSaved}
          onChange={handleCheckboxChange}
        />
        Запомнить для этого пользователя
      </label>
      <div className={s.TaskCardAcceptTaskPrompt__buttons}>
        <Button
          className={s.TaskCardAcceptTaskPrompt__declineButton}
          onClick={handleDecline}
          loading={respondToTaskTransferLoading && !currentTransferAcceptance}
          loaderProps={{
            size: 16,
            singleColor: loaderColors.buttonSecondary
          }}
        >
          Отказаться
        </Button>
        <Button
          className={s.TaskCardAcceptTaskPrompt__acceptButton}
          onClick={handleAccept}
          loading={respondToTaskTransferLoading && !!currentTransferAcceptance}
          loaderProps={{
            size: 16
          }}
        >
          Принять
        </Button>
      </div>
      <Divider className={s.TaskCardAcceptTaskPrompt__divider} />
    </div>
  );
};
