import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocationQuery } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { STORE_ADDING_NEW_TAG, STORE_LOCATION } from '../../../store/storeKeys';
import { URL_KEY_SELECTED_TAGS } from '../../../store/urlKeys';
import { Tag } from '../../../common/components/ui/Tag/Tag';
import { ReactComponent as IconLabel } from '../../../assets/icons/IconLabel.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/IconPlus.svg';
import { ReactComponent as IconMore } from '../../../assets/icons/IconMore.svg';
import { useDetachTagFromTask } from '../../../graphql/hooks/tasks/useDetachTagFromTask';
import { TagType } from '../../../graphql/types';
import { AddingNewTagStore } from '../../../store/AddingNewTagStore';
import { NewTag } from './NewTag/NewTag';
import s from './TaskTags.module.scss';

export interface TaskTagsProps {
  taskId: number;
  tags?: TagType[];
  tagClassName?: string;
  selectedTagClassName?: string;
  onTagClick?: (e: MouseEvent) => void;
  tagIsInTaskCard?: boolean;
  needSliceTags?: boolean;
  hideAddTagButton?: boolean;
  maxWidth?: number;
}

export const TaskTags = ({
  taskId,
  tags,
  tagClassName,
  selectedTagClassName,
  onTagClick,
  tagIsInTaskCard,
  needSliceTags,
  hideAddTagButton,
  maxWidth
}: TaskTagsProps) => {
  const { handleDetachTagFromTask } = useDetachTagFromTask();
  const onRemoveTagClick = (tagId: number) =>
    handleDetachTagFromTask({ taskId, tagId });

  const newTagInputRef = useRef<HTMLInputElement>(null);
  const [addingNewTag, addingNewTagStore] =
    useStore<AddingNewTagStore>(STORE_ADDING_NEW_TAG);

  useEffect(() => {
    if (addingNewTag && newTagInputRef?.current) {
      newTagInputRef.current.focus();
    }
  }, [addingNewTag]);

  const onAddTagClick = () => {
    addingNewTagStore.setAddingNewTag(true);
  };
  const tagNames = tags?.map(({ name }) => name) || [];
  const [query] = useLocationQuery(STORE_LOCATION, [URL_KEY_SELECTED_TAGS]);
  const isTagSelected = (id: string) =>
    query[URL_KEY_SELECTED_TAGS]?.includes(id);
  const [slicedTags, setSlicedTags] = useState<TagType[]>([]);

  useEffect(() => {
    if (!maxWidth || !tags) {
      return;
    }

    const symbolWidth = 8.44;
    const moreTagWidth = 36;
    const padding = 16;
    const margin = 4;

    const slicedTags: TagType[] = [];
    let remainWidth = maxWidth;

    for (let tag of tags) {
      const width = tag.name.length * symbolWidth + padding + margin;

      if (width <= remainWidth) {
        slicedTags.push(tag);
        remainWidth -= width;
      } else {
        if (moreTagWidth > remainWidth) {
          slicedTags.pop();
        }

        break;
      }
    }

    setSlicedTags(slicedTags.length < tags.length ? slicedTags : []);
  }, [tags, maxWidth]);

  return (
    <>
      {!hideAddTagButton && (
        <Tag
          className={clsx(s.TaskTags__addTag, s.TaskTags__tag, tagClassName)}
          onClick={onAddTagClick}
        >
          <IconLabel />
          <IconPlus className={s.TaskTags__addTagIcon} />
        </Tag>
      )}

      {addingNewTag && tagIsInTaskCard && (
        <NewTag
          inputRef={newTagInputRef}
          taskId={taskId}
          existingTags={tagNames}
          onFinish={() => addingNewTagStore.setAddingNewTag(false)}
          className={clsx(s.TaskTags__tag, tagClassName)}
          onClick={onTagClick}
        />
      )}

      {((needSliceTags && slicedTags.length && slicedTags) || tags)?.map(
        (tag, i) => (
          <Tag
            key={i}
            className={clsx(
              s.TaskTags__tag,
              tagClassName,
              isTagSelected(tag.id) && selectedTagClassName
            )}
            onRemoveClick={() => onRemoveTagClick(parseInt(tag.id))}
            onClick={onTagClick}
          >
            {tag.name}
          </Tag>
        )
      )}
      {needSliceTags && Boolean(slicedTags.length) && (
        <Tag className={s.TaskTags__tag}>
          <IconMore className={s.TaskTags__iconMore} />
        </Tag>
      )}
    </>
  );
};
