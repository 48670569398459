import { useCreateOnlyOfficeAccessToken } from '../../../graphql/hooks/tasks/useCreateOnlyOfficeAccessToken';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { apiUrl } from '../../../config';

export const useOpenInOnlyOffice = () => {
  const { handleCreateOnlyOfficeAccessToken } =
    useCreateOnlyOfficeAccessToken();

  const openInOnlyOffice = (fileId: number) => {
    const openedWindow = window.open();
    handleCreateOnlyOfficeAccessToken(fileId)
      .then((token) => {
        if (openedWindow) {
          openedWindow.location = `${apiUrl}/onlyOffice/${token}`;
        }
      })
      .catch((err) => {
        handleDefaultError('Произошла ошибка при открытии файла');
      });
  };

  return {
    openInOnlyOffice
  };
};
