import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';
import { useAsyncOperation, useStoreState } from '@proscom/prostore-react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { clone, debounce, isEqual, omit, parseInt, uniqueId } from 'lodash-es';
import {
  EmailLetterDraftType,
  EmailLetterTag,
  EmailLetterType,
  FileVariant,
  TaskStatus,
  TaskTypeEnum
} from '../../../../graphql/types';
import { URL_KEY_TASK_ID } from '../../../../store/urlKeys';
import { useCreateTask } from '../../../../graphql/hooks/tasks/useCreateTask';
import { useReplyOnLetter } from '../../../../graphql/hooks/tasks/useReplyOnLetter';
import { useUrlKey } from '../../../../common/hooks/useUrlKey';
import { useToggle } from '../../../../common/hooks/useToggle';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { validateEmail } from '../../../../utils/validate';
import { ReactComponent as IconReply } from '../../../../assets/icons/IconReply.svg';
import { ReactComponent as IconReplyAll } from '../../../../assets/icons/IconReplyAll.svg';
import { ReactComponent as IconForward } from '../../../../assets/icons/IconForward.svg';
import { ReactComponent as IconChevron } from '../../../../assets/icons/IconChevron.svg';
import { ReactComponent as IconClose } from '../../../../assets/icons/IconCloseThin.svg';
import { ReactComponent as IconSendLetter } from '../../../../assets/icons/IconSendLetter.svg';
import { ReactComponent as IconExpand } from '../../../../assets/icons/IconExpand.svg';
import { DropdownItems } from '../../../../common/components/ui/DropdownItems/DropdownItems';
import { ChipInput } from '../../../../common/components/ui/ChipInput/ChipInput';
import { TextEditor } from '../../../../common/components/ui/TextEditor/TextEditor';
import { Button } from '../../../../common/components/ui/Button/Button';
import {
  UploadFilesItem,
  useUploadFiles
} from '../../../../axios/hooks/files/useUploadFiles';
import { useUpdateLetterDraft } from '../../../../graphql/hooks/tasks/useUpdateLetterDraft';
import { useAttachFileToLetterDraft } from '../../../../graphql/hooks/tasks/useAttachFileToLetterDraft';
import { useDetachFileFromLetterDraft } from '../../../../graphql/hooks/tasks/useDetachFileFromLetterDraft';
import { useSubmitLetterDraft } from '../../../../graphql/hooks/tasks/useSubmitLetterDraft';
import { normalizeEmail, wrapHtmlLetter } from '../../../../utils/stringUtils';
import { STORE_EMAIL_ACCOUNTS } from '../../../../store/storeKeys';
import { EmailAccountsStoreState } from '../../../../store/EmailAccountsStore';
import { createForwardedLetterHTML } from './createForwardedLetterHTML';
import s from './LetterForm.module.scss';

export interface ILetterFormData {
  subject: string;
  email_account_id: string | undefined;
  receivers: string[];
  receiversCurrentInputValue: string;
  copyReceivers: string[];
  copyReceiversCurrentInputValue: string;
  hiddenCopyReceivers: string[];
  hiddenCopyReceiversCurrentInputValue: string;
  editorState: EditorState;
}

export enum ReplyMode {
  REPLY = 'REPLY',
  REPLY_ALL = 'REPLY_ALL',
  FORWARD = 'FORWARD'
}
const replyModes = [
  {
    mode: ReplyMode.REPLY,
    icon: IconReply,
    label: 'Ответить'
  },
  {
    mode: ReplyMode.REPLY_ALL,
    icon: IconReplyAll,
    label: 'Ответить всем'
  },
  {
    mode: ReplyMode.FORWARD,
    icon: IconForward,
    label: 'Переслать'
  }
];

export interface LetterFormClasses {
  replyHeader?: string;
  expandButton?: string;
}

export type ReplyOnLetterData = Pick<
  EmailLetterType,
  | 'id'
  | 'to'
  | 'cc'
  | 'from'
  | 'html'
  | 'subject'
  | 'email_letter_tag'
  | 'attachments'
> & { date: Date };

export type LetterDraftData = Pick<
  EmailLetterDraftType,
  'id' | 'to' | 'cc' | 'bcc' | 'html' | 'subject' | 'attachments'
>;

export type LetterFormProps<ComponentType extends ElementType> = {
  component?: ComponentType;
  className?: string;
  close?: () => void;
  replyOnLetterData?: ReplyOnLetterData;
  replyMode?: ReplyMode;
  setReplyMode?: (mode: ReplyMode) => void;
  initialValues?: Partial<ILetterFormData>;
  onLetterSent?: () => void;
  onExpand?: () => void;
  classes?: LetterFormClasses;
  letterDraftData?: LetterDraftData;
  taskId?: string;
  onLetterDelete?: () => void;
} & ComponentProps<ComponentType>;

export interface LetterFormRef {
  onClose: () => void;
  subjectInput: HTMLInputElement | null;
  editor: Editor | null;
  container: HTMLDivElement | null;
}

const LetterForm = <ComponentType extends ElementType = 'div'>(
  {
    component = 'div',
    className,
    close,
    replyOnLetterData,
    replyMode,
    setReplyMode,
    initialValues = {},
    onLetterSent,
    onExpand,
    classes,
    letterDraftData,
    taskId,
    onLetterDelete: onLetterDeleteDefault,
    ...componentProps
  }: LetterFormProps<ComponentType>,
  ref: React.ForwardedRef<LetterFormRef>
) => {
  const Component = component;

  const isReply = !!(replyOnLetterData && replyMode && setReplyMode);
  const isDraft = !!letterDraftData?.id;
  const isReplyDraft = isReply && isDraft;

  const [, setCurrentTaskId] = useUrlKey<string | undefined>(URL_KEY_TASK_ID);
  const { handleCreateTask } = useCreateTask();
  const { handleReplyOnLetter } = useReplyOnLetter();
  const { handleSubmitLetterDraft } = useSubmitLetterDraft();

  const { handleAttachFileToLetterDraft } = useAttachFileToLetterDraft();
  const { handleDetachFileFromLetterDraft } = useDetachFileFromLetterDraft();

  const initialFiles: UploadFilesItem[] = letterDraftData?.attachments.map(
    (file) => ({
      localId: uniqueId('file'),
      id: file.id,
      size: file.size,
      name: file.file_name,
      canBeOpenedInOnlyOffice: file.canBeOpenedInOnlyOffice
    })
  );
  const {
    files,
    uploadFile,
    uploadFileCopy,
    removeFile,
    reloadFile,
    clearFiles,
    startUpdateFileExpirationDateScheduler,
    updateFileExpirationDateTimeoutIds
  } = useUploadFiles({
    fileVariant: FileVariant.EmailDraftAttachment,
    initialFiles
  });

  const fileLoading = useMemo(
    () => files.some((file) => file.loading),
    [files]
  );

  const attachmentIds = useMemo(
    () => files.filter((file) => file.id).map((file) => parseInt(file.id!)),
    [files]
  );

  const createEmailTask = useAsyncOperation(
    async (
      {
        subject,
        email_account_id,
        receivers: inputReceivers,
        receiversCurrentInputValue,
        copyReceivers: inputCopyReceivers,
        copyReceiversCurrentInputValue,
        hiddenCopyReceivers: inputHiddenCopyReceivers,
        hiddenCopyReceiversCurrentInputValue,
        editorState
      }: ILetterFormData,
      isNewLetterDraftTask: boolean = false
    ) => {
      let receivers: string[];
      let copyReceivers: string[];
      let hiddenCopyReceivers: string[];

      if (isNewLetterDraftTask) {
        receivers = inputReceivers
          .concat(receiversCurrentInputValue.trim())
          .filter(validateEmail);
        copyReceivers = inputCopyReceivers
          .concat(copyReceiversCurrentInputValue.trim())
          .filter(validateEmail);
        hiddenCopyReceivers = inputHiddenCopyReceivers
          .concat(hiddenCopyReceiversCurrentInputValue.trim())
          .filter(validateEmail);
      } else {
        if (!email_account_id) {
          return handleDefaultError('Выберите отправителя');
        }
        receivers = inputReceivers
          .concat(receiversCurrentInputValue.trim())
          .filter((v) => !!v);
        if (!receivers.length) {
          return handleDefaultError('Укажите как минимум одного получателя');
        }
        const wrongReceiverEmail = receivers.find(
          (email) => !validateEmail(email)
        );
        if (wrongReceiverEmail) {
          return handleDefaultError(
            `Адрес "${wrongReceiverEmail}" в поле "Кому" не распознан. Проверьте правильность ввода всех адресов.`
          );
        }
        copyReceivers = inputCopyReceivers
          .concat(copyReceiversCurrentInputValue)
          .filter((v) => !!v);
        const wrongCopyReceiverEmail = copyReceivers.find(
          (email) => !validateEmail(email)
        );
        if (wrongCopyReceiverEmail) {
          return handleDefaultError(
            `Адрес "${wrongCopyReceiverEmail}" в поле "Копия" не распознан. Проверьте правильность ввода всех адресов.`
          );
        }
        hiddenCopyReceivers = inputHiddenCopyReceivers
          .concat(hiddenCopyReceiversCurrentInputValue)
          .filter((v) => !!v);
        const wrongHiddenCopyReceiverEmail = hiddenCopyReceivers.find(
          (email) => !validateEmail(email)
        );
        if (wrongHiddenCopyReceiverEmail) {
          return handleDefaultError(
            `Адрес "${wrongHiddenCopyReceiverEmail}" в поле "Скрытая копия" не распознан. Проверьте правильность ввода всех адресов.`
          );
        }
      }

      const html = wrapHtmlLetter(stateToHTML(editorState.getCurrentContent()));

      try {
        if (isReply) {
          const { id } = replyOnLetterData;
          await handleReplyOnLetter({
            letterId: id,
            to: receivers,
            cc: copyReceivers,
            bcc: hiddenCopyReceivers,
            html
          });
        } else {
          const newTaskId = await handleCreateTask({
            task_type: TaskTypeEnum.Email,
            status: TaskStatus.Opened,
            favorite: false,
            [isNewLetterDraftTask ? 'emailDraftFields' : 'emailFields']: {
              attachmentIds,
              email_account_id,
              to: receivers,
              subject,
              cc: copyReceivers,
              bcc: hiddenCopyReceivers,
              html
            }
          });
          setCurrentTaskId(newTaskId);
        }

        onLetterSent?.();
        await formik.setValues({
          subject: '',
          email_account_id,
          receivers: [],
          receiversCurrentInputValue: '',
          copyReceivers: [],
          copyReceiversCurrentInputValue: '',
          hiddenCopyReceivers: [],
          hiddenCopyReceiversCurrentInputValue: '',
          editorState: EditorState.createEmpty()
        });
        setCopyReceiversInputOpen(false);
        setHiddenCopyReceiversInputOpen(false);
        clearFiles();

        const successMessage = isNewLetterDraftTask
          ? 'Черновик сохранен'
          : 'Письмо отправлено';
        toast(successMessage);
      } catch (err: any) {
        const failureMessage = isNewLetterDraftTask
          ? 'Не удалось сохранить черновик'
          : 'Не удалось отправить письмо';
        handleDefaultError(err?.message || failureMessage, err);
      }
    },
    { singleton: true }
  );

  const submitLetterDraft = useAsyncOperation(
    async ({
      subject,
      email_account_id,
      receivers: inputReceivers,
      receiversCurrentInputValue,
      copyReceivers: inputCopyReceivers,
      copyReceiversCurrentInputValue,
      hiddenCopyReceivers: inputHiddenCopyReceivers,
      hiddenCopyReceiversCurrentInputValue,
      editorState
    }: ILetterFormData) => {
      let receivers: string[];
      let copyReceivers: string[];
      let hiddenCopyReceivers: string[];

      if (!taskId) {
        return handleDefaultError('Задача не найдена');
      }

      if (!email_account_id) {
        return handleDefaultError('Выберите отправителя');
      }
      receivers = inputReceivers
        .concat(receiversCurrentInputValue.trim())
        .filter((v) => !!v);
      if (!receivers.length) {
        return handleDefaultError('Укажите как минимум одного получателя');
      }
      const wrongReceiverEmail = receivers.find(
        (email) => !validateEmail(email)
      );
      if (wrongReceiverEmail) {
        return handleDefaultError(
          `Адрес "${wrongReceiverEmail}" в поле "Кому" не распознан. Проверьте правильность ввода всех адресов.`
        );
      }
      copyReceivers = inputCopyReceivers
        .concat(copyReceiversCurrentInputValue)
        .filter((v) => !!v);
      const wrongCopyReceiverEmail = copyReceivers.find(
        (email) => !validateEmail(email)
      );
      if (wrongCopyReceiverEmail) {
        return handleDefaultError(
          `Адрес "${wrongCopyReceiverEmail}" в поле "Копия" не распознан. Проверьте правильность ввода всех адресов.`
        );
      }
      hiddenCopyReceivers = inputHiddenCopyReceivers
        .concat(hiddenCopyReceiversCurrentInputValue)
        .filter((v) => !!v);
      const wrongHiddenCopyReceiverEmail = hiddenCopyReceivers.find(
        (email) => !validateEmail(email)
      );
      if (wrongHiddenCopyReceiverEmail) {
        return handleDefaultError(
          `Адрес "${wrongHiddenCopyReceiverEmail}" в поле "Скрытая копия" не распознан. Проверьте правильность ввода всех адресов.`
        );
      }

      const html = wrapHtmlLetter(stateToHTML(editorState.getCurrentContent()));

      try {
        if (isReplyDraft) {
          const { id } = replyOnLetterData;
          await handleReplyOnLetter({
            letterId: id,
            to: receivers,
            cc: copyReceivers,
            bcc: hiddenCopyReceivers,
            html
          });
        } else {
          await handleSubmitLetterDraft({
            taskId,
            emailFields: {
              email_account_id,
              to: receivers,
              subject,
              cc: copyReceivers,
              bcc: hiddenCopyReceivers,
              html
            }
          });
        }

        onLetterSent?.();
        await formik.setValues({
          subject: '',
          email_account_id,
          receivers: [],
          receiversCurrentInputValue: '',
          copyReceivers: [],
          copyReceiversCurrentInputValue: '',
          hiddenCopyReceivers: [],
          hiddenCopyReceiversCurrentInputValue: '',
          editorState: EditorState.createEmpty()
        });
        setCopyReceiversInputOpen(false);
        setHiddenCopyReceiversInputOpen(false);
        clearFiles();

        toast('Письмо отправлено');
      } catch (err: any) {
        handleDefaultError(err?.message || 'Не удалось отправить письмо', err);
      }
    },
    {
      singleton: true
    }
  );

  const { emailAccounts } =
    useStoreState<EmailAccountsStoreState>(STORE_EMAIL_ACCOUNTS);

  const initialFormikValues = useMemo<ILetterFormData>(() => {
    const {
      subject,
      email_account_id,
      receivers,
      receiversCurrentInputValue,
      copyReceivers,
      copyReceiversCurrentInputValue,
      hiddenCopyReceivers,
      hiddenCopyReceiversCurrentInputValue,
      editorState
    } = initialValues;
    return {
      subject: subject || '',
      email_account_id,
      receivers: receivers || [],
      receiversCurrentInputValue: receiversCurrentInputValue || '',
      copyReceivers: copyReceivers || [],
      copyReceiversCurrentInputValue: copyReceiversCurrentInputValue || '',
      hiddenCopyReceivers: hiddenCopyReceivers || [],
      hiddenCopyReceiversCurrentInputValue:
        hiddenCopyReceiversCurrentInputValue || '',
      editorState: editorState || EditorState.createEmpty()
    };
    // eslint-disable-next-line
  }, []);
  const formik = useFormik<ILetterFormData>({
    initialValues: initialFormikValues,
    onSubmit: async (values) => {
      try {
        if (isDraft) {
          await submitLetterDraft.run(values);
        } else {
          await createEmailTask.run(values);
        }
      } catch (err) {}
    }
  });

  useEffect(() => {
    if (!formik.values.email_account_id && emailAccounts.length) {
      formik.setFieldValue('email_account_id', emailAccounts[0].id);
    }
  }, [formik, emailAccounts]);

  const [copyReceiversInputOpen, setCopyReceiversInputOpen] = useState(
    !!initialValues?.copyReceivers?.length
  );
  const [hiddenCopyReceiversInputOpen, setHiddenCopyReceiversInputOpen] =
    useState(!!initialValues?.hiddenCopyReceivers?.length);

  const fromEmailAccountDropdownToggle = useToggle();

  const replyModeDropdownToggle = useToggle();

  const [contentEdited, setContentEdited] = useState<boolean>(
    !!initialValues?.editorState?.getCurrentContent().getPlainText()
  );

  const prevReplyMode = useRef<ReplyMode>(replyMode);

  const updateDraftAttachmentsByReplyMode = useCallback(
    async (
      replyMode: ReplyMode,
      files: UploadFilesItem[],
      replyOnLetterData: ReplyOnLetterData,
      letterDraftData: LetterDraftData
    ) => {
      if (replyMode === ReplyMode.FORWARD) {
        for (const file of replyOnLetterData.attachments) {
          const isAttached = letterDraftData?.attachments.find(
            ({ source_file_id }) => source_file_id === file.id
          );
          if (!isAttached) {
            uploadFileCopy(file)
              .then((uploadedFile) =>
                handleAttachFileToLetterDraft(
                  parseInt(letterDraftData?.id),
                  parseInt(uploadedFile.id!)
                )
              )
              .catch((err) =>
                handleDefaultError(
                  err?.response?.data?.message ||
                    err?.message ||
                    'Произошла ошибка при копировании вложения исходного письма',
                  err
                )
              );
          }
        }
      } else {
        for (const file of letterDraftData.attachments) {
          const isAttached = replyOnLetterData.attachments.find(
            ({ id }) => file.source_file_id === id
          );
          if (isAttached) {
            const uploadFilesItem = files.find(({ id }) => id === file.id);
            if (uploadFilesItem) {
              handleDetachFileFromLetterDraft(
                parseInt(letterDraftData?.id),
                parseInt(file.id)
              ).then(() => removeFile(uploadFilesItem.localId));
            }
          }
        }
      }
    },
    [
      uploadFileCopy,
      handleAttachFileToLetterDraft,
      handleDetachFileFromLetterDraft,
      removeFile
    ]
  );

  useEffect(() => {
    if (!replyOnLetterData) {
      return;
    }

    if (replyMode) {
      updateDraftAttachmentsByReplyMode(
        replyMode,
        files,
        replyOnLetterData,
        letterDraftData
      ).catch();
    }

    if (replyMode === prevReplyMode.current) {
      return;
    }

    prevReplyMode.current = replyMode;

    const selectedEmailAccount = emailAccounts.find(
      ({ id }) => id === formik.values.email_account_id
    );
    if (!selectedEmailAccount) return;

    const { from, to, cc, html, date } = replyOnLetterData;
    const normalizedReceivers = to.map(normalizeEmail);
    const normalizedCopyReceivers = cc?.map(normalizeEmail);
    const normalizedFromEmail = normalizeEmail(from);
    const isSent = replyOnLetterData.email_letter_tag === EmailLetterTag.Sent;

    const newFormikValues: ILetterFormData = { ...formik.values };

    switch (replyMode) {
      case ReplyMode.REPLY:
        if (isSent) {
          newFormikValues.receivers = normalizedReceivers;
          newFormikValues.copyReceivers = [];
          newFormikValues.hiddenCopyReceivers = [];
        } else {
          newFormikValues.receivers = [normalizedFromEmail];
          newFormikValues.copyReceivers = [];
          newFormikValues.hiddenCopyReceivers = [];
        }

        if (!contentEdited) {
          newFormikValues.editorState = EditorState.createEmpty();
        }

        setCopyReceiversInputOpen(false);
        break;
      case ReplyMode.REPLY_ALL:
        if (isSent) {
          newFormikValues.receivers = normalizedReceivers;
          newFormikValues.copyReceivers = normalizedCopyReceivers || [];
          newFormikValues.hiddenCopyReceivers = [];
        } else {
          newFormikValues.receivers = [normalizedFromEmail];
          newFormikValues.copyReceivers = normalizedCopyReceivers
            ? [
                ...normalizedReceivers.filter(
                  (email) => email !== selectedEmailAccount.email
                ),
                ...normalizedCopyReceivers.filter(
                  (email) => email !== selectedEmailAccount.email
                )
              ]
            : normalizedReceivers.filter(
                (email) => email !== selectedEmailAccount.email
              );
          newFormikValues.hiddenCopyReceivers = [];
        }

        if (!contentEdited) {
          newFormikValues.editorState = EditorState.createEmpty();
        }

        if (newFormikValues.copyReceivers?.length) {
          setCopyReceiversInputOpen(true);
        }
        break;
      case ReplyMode.FORWARD:
        newFormikValues.receivers = [];
        newFormikValues.copyReceivers = [];
        newFormikValues.hiddenCopyReceivers = [];

        if (!contentEdited) {
          newFormikValues.editorState = EditorState.createWithContent(
            stateFromHTML(createForwardedLetterHTML(date, from, html))
          );
        }

        setCopyReceiversInputOpen(false);
    }

    setHiddenCopyReceiversInputOpen(false);
    formik.setValues(newFormikValues);
    // eslint-disable-next-line
  }, [replyMode, emailAccounts]);

  const { handleUpdateLetterDraft } = useUpdateLetterDraft();
  const [letterDraftUpdatedMessageShown, setLetterDraftUpdatedMessageShown] =
    useState<boolean>(false);
  const letterDraftUpdatedMessageShownTimeoutRef =
    useRef<NodeJS.Timeout | null>(null);
  const showLetterDraftUpdatedMessage = useCallback(() => {
    if (letterDraftUpdatedMessageShownTimeoutRef.current) {
      clearTimeout(letterDraftUpdatedMessageShownTimeoutRef.current);
    }
    setLetterDraftUpdatedMessageShown(true);
    letterDraftUpdatedMessageShownTimeoutRef.current = setTimeout(
      () => setLetterDraftUpdatedMessageShown(false),
      3000
    );
  }, [setLetterDraftUpdatedMessageShown]);
  const prevValues = useRef<
    Omit<ILetterFormData, 'editorState'> & { html?: string }
  >(omit(formik.values, 'editorState'));
  const prevHtml = useRef<string>(
    stateToHTML(formik.values.editorState.getCurrentContent())
  );

  // eslint-disable-next-line
  const updateLetterDraft = useCallback(
    debounce(async ({ editorState, ...formikValues }: ILetterFormData) => {
      try {
        const html = stateToHTML(editorState.getCurrentContent());
        const validatedValues = clone(formikValues);
        validatedValues.receivers =
          validatedValues.receivers.filter(validateEmail);
        validatedValues.copyReceivers =
          validatedValues.copyReceivers.filter(validateEmail);
        validatedValues.hiddenCopyReceivers =
          validatedValues.hiddenCopyReceivers.filter(validateEmail);
        if (
          !isEqual(validatedValues, prevValues.current) ||
          html !== prevHtml.current
        ) {
          await handleUpdateLetterDraft({
            id: parseInt(letterDraftData?.id),
            to: validatedValues.receivers,
            cc: validatedValues.copyReceivers,
            bcc: validatedValues.hiddenCopyReceivers,
            html,
            ...(!isReplyDraft && {
              email_account_id: validatedValues.email_account_id,
              subject: validatedValues.subject
            })
          });

          showLetterDraftUpdatedMessage();

          prevValues.current = validatedValues;
          prevHtml.current = html;
        }
      } catch (err) {
        handleDefaultError('Произошла ошибка при сохранении черновика', err);
      }
    }, 1000),
    [isReplyDraft]
  );

  const updateLetterDraftForwarded = async (isForwarded: boolean) => {
    try {
      await handleUpdateLetterDraft({
        id: parseInt(letterDraftData?.id),
        is_forwarded: isForwarded
      });
    } catch (err) {
      handleDefaultError('Произошла ошибка при сохранении черновика', err);
    }
  };

  useEffect(() => {
    if (isDraft) {
      updateLetterDraft(formik.values);
    }
  }, [formik, isDraft, updateLetterDraft]);

  const onLetterDelete = () => {
    onLetterDeleteDefault?.();
    updateLetterDraft.cancel();
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    if (!contentEdited) {
      const currentPlainText = formik.values.editorState
        .getCurrentContent()
        .getPlainText();
      const newPlainText = newEditorState.getCurrentContent().getPlainText();
      if (currentPlainText !== newPlainText) {
        setContentEdited(true);
      }
    }

    formik.setFieldValue('editorState', newEditorState);
  };

  const onFilesUpload = (files: FileList) => {
    Array.from(files).forEach(async (file) => {
      try {
        const uploadedFile = await uploadFile(file);

        if (!isDraft || !isReply) {
          startUpdateFileExpirationDateScheduler(uploadedFile);
        }

        if (isDraft) {
          await handleAttachFileToLetterDraft(
            parseInt(letterDraftData?.id),
            parseInt(uploadedFile.id!)
          );

          showLetterDraftUpdatedMessage();
        }
      } catch (err: any) {
        handleDefaultError(
          err?.response?.data?.message ||
            err?.message ||
            'Произошла ошибка при загрузке файла',
          err
        );
      }
    });
  };

  const onFileRemove = async (localId: string) => {
    try {
      const file = files.find((i) => i.localId === localId);
      if (isDraft && file?.id) {
        await handleDetachFileFromLetterDraft(
          parseInt(letterDraftData?.id),
          parseInt(file.id)
        );

        showLetterDraftUpdatedMessage();
      }
    } finally {
      removeFile(localId);
    }
  };

  const onReloadFile = (localId: string) => {
    reloadFile(localId).catch((err) => {
      handleDefaultError(
        err?.response?.data?.message || 'Произошла ошибка при загрузке файла',
        err
      );
    });
  };

  const subjectInputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<Editor>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    onClose: async () => {
      try {
        await createEmailTask.run(
          {
            ...formik.values
          },
          true
        );
      } finally {
        updateFileExpirationDateTimeoutIds.current.forEach((timeoutId) =>
          clearTimeout(timeoutId)
        );
        updateFileExpirationDateTimeoutIds.current = [];
      }
    },
    get subjectInput() {
      return subjectInputRef.current;
    },
    get editor() {
      return editorRef.current;
    },
    get container() {
      return containerRef.current;
    }
  }));

  const isSubmitLoading = useMemo(
    () => (isDraft ? submitLetterDraft.loading : createEmailTask.loading),
    [submitLetterDraft, createEmailTask, isDraft]
  );

  return (
    <Component
      className={clsx(s.LetterForm, className)}
      {...componentProps}
      {...(Component === 'div' && { ref: containerRef })}
    >
      {isReply ? (
        <div className={clsx(s.LetterForm__replyHeader, classes?.replyHeader)}>
          <div className={s.LetterForm__replyHeaderText}>
            {replyOnLetterData?.subject || 'Без темы'}
          </div>
          {close && (
            <IconClose className={s.LetterForm__closeIcon} onClick={close} />
          )}
        </div>
      ) : (
        <>
          <div className={s.LetterForm__subject}>
            <label className={s.LetterForm__inputLabel}>Тема:</label>
            <input
              className={s.LetterForm__subjectInput}
              value={formik.values.subject}
              onChange={(e) => formik.setFieldValue('subject', e.target.value)}
              ref={subjectInputRef}
            />
            {close && (
              <IconClose className={s.LetterForm__closeIcon} onClick={close} />
            )}
          </div>
          <div className={s.LetterForm__fromEmail}>
            <label className={s.LetterForm__inputLabel}>Отправитель:</label>
            <Tippy
              content={
                <DropdownItems
                  className={s.LetterForm__fromEmailDropdown}
                  itemClassName={s.LetterForm__fromEmailDropdownItem}
                  items={emailAccounts.map(({ id, name, email }) => ({
                    text: (
                      <>
                        <div>{email}</div>
                        <div
                          className={s.LetterForm__fromEmailDropdownItemCaption}
                        >
                          {name}
                        </div>
                      </>
                    ),
                    onClick: () => {
                      formik.setFieldValue('email_account_id', id);
                      fromEmailAccountDropdownToggle.unset();
                    }
                  }))}
                />
              }
              interactive
              visible={fromEmailAccountDropdownToggle.value}
              onClickOutside={fromEmailAccountDropdownToggle.unset}
              placement="bottom-start"
            >
              <div
                className={s.LetterForm__fromEmailValueContainer}
                onClick={fromEmailAccountDropdownToggle.toggle}
              >
                <div className={s.LetterForm__fromEmailValue}>
                  {
                    emailAccounts.find(
                      ({ id }) => id === formik.values.email_account_id
                    )?.email
                  }
                </div>
                <IconChevron
                  className={clsx(
                    s.LetterForm__openFromEmailDropdownOpenIcon,
                    fromEmailAccountDropdownToggle.value &&
                      s.LetterForm__openFromEmailDropdownOpenIcon_dropdownOpen
                  )}
                />
              </div>
            </Tippy>
          </div>
        </>
      )}
      <div className={s.LetterForm__receivers}>
        {isReply && (
          <Tippy
            content={
              <DropdownItems
                items={replyModes.map(({ icon: Icon, label, mode }) => ({
                  text: (
                    <div className={s.LetterForm__replyModeDropdownItem}>
                      <Icon
                        className={s.LetterForm__replyModeDropdownItemIcon}
                      />
                      <div>{label}</div>
                    </div>
                  ),
                  onClick: () => {
                    setReplyMode(mode);
                    replyModeDropdownToggle.unset();
                    updateLetterDraftForwarded(mode === ReplyMode.FORWARD);
                  }
                }))}
              />
            }
            interactive
            visible={replyModeDropdownToggle.value}
            onClickOutside={replyModeDropdownToggle.unset}
            placement="bottom-start"
          >
            <div
              className={s.LetterForm__replyMode}
              onClick={replyModeDropdownToggle.toggle}
            >
              {replyMode === ReplyMode.REPLY ? (
                <IconReply />
              ) : replyMode === ReplyMode.REPLY_ALL ? (
                <IconReplyAll />
              ) : (
                <IconForward />
              )}
              <IconChevron
                className={clsx(
                  s.LetterForm__replyModeDropdownOpenIcon,
                  replyModeDropdownToggle.value &&
                    s.LetterForm__replyModeDropdownOpenIcon_dropdownOpen
                )}
              />
            </div>
          </Tippy>
        )}
        <label
          className={clsx(
            s.LetterForm__inputLabel,
            s.LetterForm__receiversInputLabel
          )}
        >
          Кому:
        </label>
        <ChipInput
          className={s.LetterForm__receiversInput}
          items={formik.values.receivers}
          setItems={(receivers) => formik.setFieldValue('receivers', receivers)}
          currentInputValue={formik.values.receiversCurrentInputValue}
          setCurrentInputValue={(value) =>
            formik.setFieldValue('receiversCurrentInputValue', value)
          }
        />
        {!copyReceiversInputOpen && !letterDraftUpdatedMessageShown && (
          <>
            <button
              className={s.LetterForm__openCopyReceiversInputButton}
              onClick={() => setCopyReceiversInputOpen(true)}
            >
              Копия
            </button>
            <button
              className={clsx(
                s.LetterForm__openCopyReceiversInputButton_mobile,
                hiddenCopyReceiversInputOpen &&
                  s.LetterForm__openCopyReceiversInputButton_mobile_hiddenCopyReceiversInputOpen
              )}
              onClick={() => setCopyReceiversInputOpen(true)}
            >
              К:
            </button>
          </>
        )}
        {!hiddenCopyReceiversInputOpen && !letterDraftUpdatedMessageShown && (
          <>
            <button
              className={s.LetterForm__openHiddenCopyReceiversInputButton}
              onClick={() => setHiddenCopyReceiversInputOpen(true)}
            >
              Скрытая копия
            </button>
            <button
              className={clsx(
                s.LetterForm__openHiddenCopyReceiversInputButton_mobile,
                copyReceiversInputOpen &&
                  s.LetterForm__openHiddenCopyReceiversInputButton_mobile_copyReceiversInputOpen
              )}
              onClick={() => setHiddenCopyReceiversInputOpen(true)}
            >
              СК:
            </button>
          </>
        )}
        {letterDraftUpdatedMessageShown && (
          <div className={s.LetterForm__letterDraftUpdatedMessage}>
            Черновик сохранен
          </div>
        )}
        {onExpand && (
          <div
            className={clsx(s.LetterForm__expandButton, classes?.expandButton)}
            onClick={onExpand}
          >
            <IconExpand />
          </div>
        )}
      </div>
      {copyReceiversInputOpen && (
        <div className={s.LetterForm__copyReceivers}>
          <label
            className={clsx(
              s.LetterForm__inputLabel,
              s.LetterForm__copyReceiversInputLabel
            )}
          >
            Копия:
          </label>
          <ChipInput
            className={s.LetterForm__copyReceiversInput}
            items={formik.values.copyReceivers}
            setItems={(copyReceivers) =>
              formik.setFieldValue('copyReceivers', copyReceivers)
            }
            currentInputValue={formik.values.copyReceiversCurrentInputValue}
            setCurrentInputValue={(value) =>
              formik.setFieldValue('copyReceiversCurrentInputValue', value)
            }
          />
        </div>
      )}
      {hiddenCopyReceiversInputOpen && (
        <div className={s.LetterForm__hiddenCopyReceivers}>
          <label
            className={clsx(
              s.LetterForm__inputLabel,
              s.LetterForm__hiddenCopyReceiversInputLabel
            )}
          >
            Ск. копия:
          </label>
          <ChipInput
            className={s.LetterForm__hiddenCopyReceiversInput}
            items={formik.values.hiddenCopyReceivers}
            setItems={(hiddenCopyReceivers) =>
              formik.setFieldValue('hiddenCopyReceivers', hiddenCopyReceivers)
            }
            currentInputValue={
              formik.values.hiddenCopyReceiversCurrentInputValue
            }
            setCurrentInputValue={(value) =>
              formik.setFieldValue(
                'hiddenCopyReceiversCurrentInputValue',
                value
              )
            }
          />
        </div>
      )}
      <TextEditor
        className={s.LetterForm__textEditor}
        value={formik.values.editorState}
        onChange={onEditorStateChange}
        submitButton={
          <Button
            onClick={formik.submitForm}
            loading={isSubmitLoading}
            rightIcon={IconSendLetter}
            disabled={fileLoading}
          >
            Отправить
          </Button>
        }
        attachments={files}
        onFilesUpload={onFilesUpload}
        onFileRemove={onFileRemove}
        onFileReload={onReloadFile}
        onLetterDelete={onLetterDelete}
        ref={editorRef}
      />
    </Component>
  );
};

const LetterFormWithRef = forwardRef<LetterFormRef>(LetterForm) as <
  ComponentType extends ElementType
>(
  props: LetterFormProps<ComponentType> & { ref?: Ref<LetterFormRef> }
) => ReactElement;
export { LetterFormWithRef as LetterForm };
