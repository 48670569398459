import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { min, parseInt } from 'lodash-es';
import { useUploadFiles } from '../../../axios/hooks/files/useUploadFiles';
import {
  FileVariant,
  TaskCreateInput,
  TaskTypeEnum
} from '../../../graphql/types';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { FileCard } from '../../components/ui/FileCard/FileCard';

export enum UploadTaskFileVariant {
  CREATE_TASK = 'CREATE_TASK',
  CONVERT_TEXT_TASK_TO_FILE = 'CONVERT_TEXT_TASK_TO_FILE'
}

interface UseUploadTaskFileOptions {
  variant: UploadTaskFileVariant;
  handleCreateTask?: (values?: Partial<TaskCreateInput>) => Promise<void>;
  handleConvertTextTaskToFile?: (
    fileId: number,
    taskId: number
  ) => Promise<void>;
}

export const useUploadTaskFile = ({
  variant,
  handleCreateTask,
  handleConvertTextTaskToFile
}: UseUploadTaskFileOptions) => {
  const { files, uploadFile, removeFile } = useUploadFiles({
    fileVariant: FileVariant.FileTask,
    showProgress: true
  });

  const loadingFile = useMemo(
    () => (files[0] && files[0].loading ? files[0] : undefined),
    [files]
  );

  const onFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    taskId?: number
  ) => {
    e.preventDefault();
    if (loadingFile || !e.target.files) {
      return;
    }

    try {
      const file = await uploadFile(e.target.files[0]);
      if (
        variant === UploadTaskFileVariant.CONVERT_TEXT_TASK_TO_FILE &&
        handleConvertTextTaskToFile &&
        typeof taskId === 'number'
      ) {
        await handleConvertTextTaskToFile(parseInt(file.id!), taskId);
      } else if (
        variant === UploadTaskFileVariant.CREATE_TASK &&
        handleCreateTask
      ) {
        await handleCreateTask({
          task_type: TaskTypeEnum.File,
          name: file.name,
          fileId: parseInt(file.id!)
        });
      } else return;

      setTimeout(() => {
        toast.dismiss(file.localId);
        removeFile(file.localId);
      }, 1000);
    } catch (err: any) {
      handleDefaultError(
        err?.response?.data?.message ||
          err?.message ||
          'Произошла ошибка при загрузке файла',
        err
      );
    }

    e.target.value = '';
  };

  useEffect(() => {
    if (loadingFile) {
      const FileComponent = () => (
        <FileCard
          key={loadingFile.id}
          name={loadingFile.name}
          size={loadingFile.size}
          progress={min([loadingFile.progress, 1])}
          dark
        />
      );

      if (toast.isActive(loadingFile.localId!)) {
        toast.update(loadingFile.localId!, {
          render: FileComponent
        });
      } else {
        toast(FileComponent, {
          toastId: loadingFile.localId,
          className: 'Toastify__toast_file',
          autoClose: false
        });
      }
    }
  }, [loadingFile]);

  useEffect(() => {
    files.forEach((file) => {
      if (file.error) {
        toast.dismiss(file.localId);
        removeFile(file.localId);
      }
    });
  }, [files, removeFile]);

  return {
    onFileUpload
  };
};
