import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { ReplyLetterInput, TaskType } from '../../types';
import { MUTATION_REPLY_ON_LETTER } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseReplyOnLetterReturn {
  handleReplyOnLetter: (input: ReplyLetterInput) => Promise<void>;
  loading: boolean;
}

const ReplyOnLetterMutationOptions: MutationOptions<
  {
    replyLetter: TaskType;
  },
  {
    input: ReplyLetterInput;
  }
> = {
  mutation: MUTATION_REPLY_ON_LETTER
};

export const useReplyOnLetter = (): UseReplyOnLetterReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const replyOnLetter = useGraphqlMutation(ReplyOnLetterMutationOptions, '', {
    singleton: false
  });
  const replyOnLetterRun = replyOnLetter.run;
  const handleReplyOnLetter = useCallback(
    (input: ReplyLetterInput) =>
      replyOnLetterRun({ variables: { input } }).then(({ data }) => {
        const updatedTask = data?.replyLetter;
        if (updatedTask) {
          tasksStore.handleTaskUpdated(updatedTask);
        }
      }),
    [replyOnLetterRun, tasksStore]
  );
  return {
    handleReplyOnLetter,
    loading: replyOnLetter.loading
  };
};
