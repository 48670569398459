import React, { forwardRef } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import clsx from 'clsx';
import s from './Tooltip.module.scss';

export type TooltipProps = TippyProps & {
  text: string | React.ReactElement;
  children: React.ReactElement<any>;
};

export const Tooltip = ({
  text,
  placement = 'bottom',
  className,
  children,
  ...props
}: TooltipProps) => {
  const ReferencedComponent = forwardRef((props, ref) => {
    const refProps =
      typeof children.type === 'string' ? { ref } : { innerRef: ref };
    return React.cloneElement(children, { ...refProps, ...props });
  });
  const Tooltip = (
    <span
      className={clsx(s.Tooltip, s[`Tooltip__arrow_${placement}`], className)}
    >
      {text}
    </span>
  );

  return (
    <Tippy content={Tooltip} placement={placement} {...props}>
      <ReferencedComponent />
    </Tippy>
  );
};
