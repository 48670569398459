import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconDelete } from '../../../../assets/icons/IconDelete.svg';
import { ReactComponent as IconTransfer } from '../../../../assets/icons/IconTransfer.svg';
import { ReactComponent as IconStar } from '../../../../assets/icons/IconStar.svg';
import { ReactComponent as IconStarOutlined } from '../../../../assets/icons/IconStarOutlined.svg';
import { ReactComponent as IconClose } from '../../../../assets/icons/IconCancel.svg';
import { ReactComponent as IconPostpone } from '../../../../assets/icons/IconPostpone.svg';
import { ReactComponent as IconAttachment } from '../../../../assets/icons/IconAttachment.svg';
import { IconButton } from '../../../../common/components/ui/IconButton/IconButton';
import { Tooltip } from '../../../../common/components/ui/Tooltip/Tooltip';
import s from './TaskCardControlButtons.module.scss';

export interface TaskCardControlButtonsProps {
  className?: string;
  isLetterTask?: boolean;
  isFavoriteTask?: boolean;
  onDeleteClick?: () => any;
  onTransferClick?: () => any;
  onFavoriteClick?: () => any;
  onPostponeClick?: () => any;
  onCloseClick?: () => any;
  isTextTask?: boolean;
  onFileInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

export const TaskCardControlButtons = ({
  className,
  isLetterTask,
  isFavoriteTask,
  onDeleteClick,
  onTransferClick,
  onFavoriteClick,
  onPostponeClick,
  onCloseClick,
  isTextTask,
  onFileInputChange
}: TaskCardControlButtonsProps) => (
  <div className={clsx(s.TaskCardControlButtons, className)}>
    {isTextTask && (
      <Tooltip delay={[500, 0]} text="Прикрепить файл">
        <div>
          <IconButton
            component={'label'}
            icon={IconAttachment}
            htmlFor="attachFileToTask"
          />
          <input
            id="attachFileToTask"
            type="file"
            className={s.TaskCardControlButtons__fileInput}
            onChange={onFileInputChange}
          />
        </div>
      </Tooltip>
    )}
    <Tooltip delay={[500, 0]} text="Удалить">
      <IconButton icon={IconDelete} onClick={onDeleteClick} />
    </Tooltip>
    {!isLetterTask && (
      <Tooltip delay={[500, 0]} text="Передать">
        <IconButton icon={IconTransfer} onClick={onTransferClick} />
      </Tooltip>
    )}
    <Tooltip delay={[500, 0]} text="В избранное">
      <IconButton
        className={
          isFavoriteTask
            ? s.TaskCardControlButtons__favoriteButton_active
            : undefined
        }
        icon={isFavoriteTask ? IconStar : IconStarOutlined}
        onClick={onFavoriteClick}
      />
    </Tooltip>
    <Tooltip delay={[500, 0]} text="Отложить">
      <IconButton icon={IconPostpone} onClick={onPostponeClick} />
    </Tooltip>
    <Tooltip delay={[500, 0]} text="Закрыть">
      <IconButton
        className={s.TaskCardControlButtons__closeButton}
        icon={IconClose}
        onClick={onCloseClick}
      />
    </Tooltip>
  </div>
);
