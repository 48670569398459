import React from 'react';
import { IRequestState } from '@proscom/prostore';
import { Loader, LoaderProps } from '../ui/Loader/Loader';
import { QueryErrorMessage } from '../ui/QueryErrorMessage/QueryErrorMessage';

export type QueryLoaderOptions = {
  onReload?: () => void;
  loaderProps?: LoaderProps;
};

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param state - состояние запроса
 * @param options - параметры
 *
 * @usage В jsx-коде:
 *  queryLoader(query) || <div>Data</div>
 */
export function queryLoader(
  state: IRequestState<any, any>,
  options?: QueryLoaderOptions
) {
  return state.loading ? (
    <Loader {...options?.loaderProps} />
  ) : state.error ? (
    <QueryErrorMessage error={state.error} onReload={options?.onReload} />
  ) : null;
}

/**
 * Рендерит мини-лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param state - состояние запроса
 * @param options - параметры
 *
 * @usage В jsx-коде:
 *  queryLoaderInline(query) || <div>Data</div>
 */
export function queryLoaderInline(
  state: IRequestState<any, any>,
  options?: QueryLoaderOptions
) {
  return state.loading ? (
    '...'
  ) : state.error ? (
    <QueryErrorMessage error={state.error} onReload={options?.onReload} />
  ) : null;
}

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен,
 * специальный контент, если данных нет,
 * и возвращает null, если все ок.
 *
 * Если требуется более сложное условие на существование данных, то
 * лучше его реализовать в коде компонента, или отдельной функцией.
 *
 * @param state - состояние запроса
 * @param options - параметры
 *
 * @usage В jsx-коде:
 *  queryLoaderWithExistenceCheck(query) || <div>Data</div>
 */
export function queryLoaderWithExistenceCheck(
  state: IRequestState<any, any>,
  options?: QueryLoaderOptions & {
    renderNotFound?: () => React.ReactNode;
  }
) {
  const renderNotFound = options?.renderNotFound || (() => 'Не найдено');
  return (
    queryLoader(state, options) || (!state.data ? renderNotFound() : undefined)
  );
}

/**
 * Рендерит лоадер, если хотя бы один из переданных запросов грузится,
 * рендерит ошибку, если хотя бы один из переданных запросов ошибочек,
 * возвращает null, если все ок
 *
 * @param states - состояния запросов
 * @param options - параметры
 *
 * @usage В jsx-коде:
 *  multiQueryLoader([query1, query2]) || <div>Data</div>
 */
export function multiQueryLoader(
  states: IRequestState<any, any>[],
  options?: QueryLoaderOptions
) {
  const spinner = states.some((s) => s.loading);
  const errorQuery = states.find((s) => s.error);
  return spinner ? (
    <Loader />
  ) : errorQuery ? (
    <QueryErrorMessage error={errorQuery.error} onReload={options?.onReload} />
  ) : null;
}
