// В этом файле перечисляются константы, через которые можно
// инжектировать глобальные сторы в компонент через ProstoreContext


export const STORE_LOCATION = 'STORE_LOCATION';
export const STORE_AUTH = 'STORE_AUTH';
export const STORE_TASKS = 'STORE_TASKS';
export const STORE_SETTINGS = 'STORE_SETTINGS';
export const STORE_CALENDAR_OPEN = 'STORE_CALENDAR_OPEN';
export const STORE_ADDING_NEW_TAG = 'STORE_ADDING_NEW_TAG';
export const STORE_SYSTEM_LOGS_ENABLED = 'STORE_SYSTEM_LOGS_ENABLE';
export const STORE_EMAIL_ACCOUNTS = 'STORE_EMAIL_ACCOUNTS';
