import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { MUTATION_DETACH_FILE_FROM_LETTER_DRAFT } from '../../mutations/letterDrafts';
import { TasksStore } from '../../../store/TasksStore';
import { STORE_TASKS } from '../../../store/storeKeys';

export interface UseDetachFileFromLetterDraftReturn {
  handleDetachFileFromLetterDraft: (
    draftId: number,
    fileId: number
  ) => Promise<void>;
  loading: boolean;
}

const DetachFileFromLetterDraftMutationOptions: MutationOptions<
  {
    detachFileFromLetterDraft: TaskType;
  },
  {
    draftId: number;
    fileId: number;
  }
> = {
  mutation: MUTATION_DETACH_FILE_FROM_LETTER_DRAFT
};

export const useDetachFileFromLetterDraft =
  (): UseDetachFileFromLetterDraftReturn => {
    const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

    const detachFileFromLetterDraft = useGraphqlMutation(
      DetachFileFromLetterDraftMutationOptions
    );
    const detachFileFromLetterDraftRun = detachFileFromLetterDraft.run;

    const handleDetachFileFromLetterDraft = useCallback(
      (draftId: number, fileId: number) =>
        detachFileFromLetterDraftRun({ variables: { draftId, fileId } }).then(
          ({ data }) => {
            const task = data?.detachFileFromLetterDraft;
            if (task) {
              tasksStore.handleTaskUpdated(task);
            }
          }
        ),
      [detachFileFromLetterDraftRun, tasksStore]
    );
    return {
      handleDetachFileFromLetterDraft,
      loading: detachFileFromLetterDraft.loading
    };
  };
