import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { EmailLetterDraftType } from '../../../../../graphql/types';

import { Modal } from '../../../../../common/components/ui/Modal/Modal';
import { LetterForm } from '../../LetterForm/LetterForm';
import { OpenLettersContext } from '../../openLettersContext';
import s from './TaskLogStoryLetterDraft.module.scss';

export interface TaskLogStoryLetterDraftProps extends EmailLetterDraftType {
  className?: string;
  date: Date;
  taskId?: string;
}

export enum LetterDraftShowMode {
  SHOW_IN_LOG,
  SHOW_IN_MODAL
}

export const TaskLogStoryLetterDraft = ({
  className,
  id,
  email_account_id,
  subject,
  html,
  to,
  cc,
  bcc,
  attachments,
  taskId
}: TaskLogStoryLetterDraftProps) => {
  const openLettersContext = useContext(OpenLettersContext);
  const scrolled = openLettersContext?.scrolledLetterDraftId === id;
  const [showMode, setShowMode] = useState<LetterDraftShowMode>(
    LetterDraftShowMode.SHOW_IN_LOG
  );
  const elem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrolled) {
      elem.current?.scrollIntoView({ block: 'start', behavior: 'auto' });
      openLettersContext?.setScrolledLetterId(null);
    }
  }, [scrolled, openLettersContext]);

  const close = () => {
    if (showMode === LetterDraftShowMode.SHOW_IN_MODAL) {
      setShowMode(LetterDraftShowMode.SHOW_IN_LOG);
    }
  };

  const letterDraftData = useMemo(() => {
    return { id, to, cc, bcc, html, subject, attachments };
  }, [id, subject, to, bcc, cc, html, attachments]);

  const editorState = useMemo(
    () => EditorState.createWithContent(stateFromHTML(html || '')),
    [html]
  );

  return (
    <div className={clsx(s.TaskLogStoryLetterDraft, className)} ref={elem}>
      <LetterForm
        component={
          showMode === LetterDraftShowMode.SHOW_IN_MODAL ? Modal : 'div'
        }
        className={clsx(
          s.TaskLogStoryLetterDraft__letterForm,
          showMode === LetterDraftShowMode.SHOW_IN_MODAL &&
            s.TaskLogStoryLetterDraft__letterForm_modal
        )}
        letterDraftData={letterDraftData}
        initialValues={{
          email_account_id,
          subject,
          receivers: to,
          copyReceivers: cc,
          hiddenCopyReceivers: bcc,
          editorState
        }}
        onExpand={
          showMode === LetterDraftShowMode.SHOW_IN_LOG &&
          (() => setShowMode(LetterDraftShowMode.SHOW_IN_MODAL))
        }
        onLetterSent={() => {
          openLettersContext?.setLetterOpen(id, false);
        }}
        classes={{
          expandButton:
            showMode === LetterDraftShowMode.SHOW_IN_MODAL &&
            s.TaskLogStoryLetter__replyLetterFormExpandButton_hidden
        }}
        {...(showMode === LetterDraftShowMode.SHOW_IN_MODAL && {
          isOpen: true,
          disableDefaultStyles: true,
          close
        })}
        taskId={taskId}
      />
    </div>
  );
};
