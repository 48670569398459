import Tippy from '@tippyjs/react';
import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { DropdownItems } from '../../../../common/components/ui/DropdownItems/DropdownItems';
import { useToggle } from '../../../../common/hooks/useToggle';
import { useTaskTransferPermissions } from '../../../../graphql/hooks/taskTransferPermission/useTaskTransferPermissions';
import { useUpdateTaskTransferPermission } from '../../../../graphql/hooks/taskTransferPermission/useUpdateTaskTransferPermission';
import { useDeleteTaskTransferPermission } from '../../../../graphql/hooks/taskTransferPermission/useDeleteTaskTransferPermission';
import { ReactComponent as IconCheck } from '../../../../assets/icons/IconCheck.svg';
import { ReactComponent as IconChevronDown } from '../../../../assets/icons/IconChevron.svg';

import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { SettingsTaskTransferPermissionsModal } from '../SettingsTaskTransferPermissionsModal/SettingsTaskTransferPermissionsModal';
import { Divider } from '../../../../common/components/ui/Divider/Divider';
import { EmptyListPlaceholder } from '../EmptyListPlaceholder/EmptyListPlaceholder';
import { TaskTransferPermissionType } from '../../../../graphql/types';
import s from './SettingsTaskTransferPermissions.module.scss';

export const SettingsTaskTransferPermissions = () => {
  const permissionsRequestResult = useTaskTransferPermissions();
  const permissions = permissionsRequestResult.state.data;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentPermission, setCurrentPermission] = useState(
    {} as TaskTransferPermissionType
  );

  const { handleUpdateTaskTransferPermission } =
    useUpdateTaskTransferPermission();
  const {
    handleDeleteTaskTransferPermission,
    loading: handleDeleteTaskTransferPermissionLoading
  } = useDeleteTaskTransferPermission();

  const updateTaskTransferPermission = async (
    id: string,
    transfer_allowed: boolean
  ) => {
    try {
      await handleUpdateTaskTransferPermission({ id, transfer_allowed });
    } catch (e) {
      handleDefaultError(
        'Произошла ошибка при обновлении разрешения. Попробуйте снова',
        e
      );
    }
  };

  const deleteTaskTransferPermission = async (permissionId: string) => {
    setModalOpen(false);
    try {
      await handleDeleteTaskTransferPermission(permissionId);
    } catch (e) {
      handleDefaultError(
        'Произошла ошибка при удалении разрешения. Попробуйте снова',
        e
      );
    }
  };

  const onDeleteTaskTransferPermissionClick = (
    permission: TaskTransferPermissionType
  ) => {
    setCurrentPermission(permission);
    setModalOpen(true);
  };

  const dropdownToggle = useToggle();

  return (
    <>
      <div>
        <p className={s.TaskTransferPermissions__description}>
          От кого принимать или отклонять задачи по умолчанию:
        </p>
        {permissions?.length ? (
          permissions?.map((permission) => {
            return (
              <Fragment key={permission.id}>
                <div className={s.TaskTransferPermissions__permission}>
                  <div className={s.TaskTransferPermissions__username}>
                    {permission.transfererUsername}
                  </div>
                  <Tippy
                    content={
                      <DropdownItems
                        itemClassName={s.TaskTransferPermissions__dropdownItem}
                        items={[
                          {
                            text: (
                              <>
                                Всегда разрешать
                                {permission.transfer_allowed && (
                                  <IconCheck
                                    className={
                                      s.TaskTransferPermissions__iconCheck
                                    }
                                  />
                                )}
                              </>
                            ),
                            onClick: () =>
                              updateTaskTransferPermission(permission.id, true)
                          },
                          {
                            text: (
                              <>
                                Блокировать
                                {!permission.transfer_allowed && (
                                  <IconCheck
                                    className={
                                      s.TaskTransferPermissions__iconCheck
                                    }
                                  />
                                )}
                              </>
                            ),
                            onClick: () =>
                              updateTaskTransferPermission(permission.id, false)
                          },
                          {
                            text: 'Убрать из списка',
                            onClick: () =>
                              onDeleteTaskTransferPermissionClick(permission),
                            divided: true,
                            personalItemClassName:
                              s.TaskTransferPermissions__forgetChoice
                          }
                        ]}
                      />
                    }
                    interactive
                    visible={dropdownToggle.value}
                    onClickOutside={dropdownToggle.unset}
                    placement="bottom-end"
                  >
                    <div
                      className={s.TaskTransferPermissions__dropdown}
                      onClick={dropdownToggle.toggle}
                    >
                      {permission.transfer_allowed
                        ? 'Всегда разрешать'
                        : 'Блокировать'}
                      <IconChevronDown
                        className={clsx(
                          s.TaskTransferPermissions__dropdownIcon,
                          dropdownToggle.value &&
                            s.TaskTransferPermissions__dropdownIcon_open
                        )}
                      />
                    </div>
                  </Tippy>
                </div>
                <Divider />
              </Fragment>
            );
          })
        ) : (
          <EmptyListPlaceholder className={s.TaskTransferPermissions__empty} />
        )}
      </div>
      <SettingsTaskTransferPermissionsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        currentPermission={currentPermission}
        onPermissionDelete={() =>
          deleteTaskTransferPermission(currentPermission.id)
        }
        permissionDeleteLoading={handleDeleteTaskTransferPermissionLoading}
      />
    </>
  );
};
