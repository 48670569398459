import React, { useEffect } from 'react';
import { History } from 'history';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { ILocationStoreState } from '@proscom/prostore-react-router';
import { AuthStore } from '../../store/AuthStore';
import { STORE_AUTH, STORE_LOCATION } from '../../store/storeKeys';
import { SignInForm } from '../../common/components/ui/AuthForm/SignInForm';
import { PageFooter } from '../../common/components/ui/PageFooter/PageFooter';
import s from './LoginPage.module.scss';

export interface LoginPageProps {
  history: History;
}

export default function LoginPage({ history }: LoginPageProps) {
  const [authStoreState, authStore] = useStore<AuthStore>(STORE_AUTH);
  const locationStoreState = useStoreState<ILocationStoreState>(STORE_LOCATION);
  const target = locationStoreState.query?.target as string | undefined;

  useEffect(() => {
    if (authStore.isLoggedIn()) {
      history.push(target || '/');
    }
  }, [authStoreState, authStore, history, target]);

  return (
    <>
      <div className={s.LoginPage__formContainer}>
        <SignInForm
          className={s.LoginPage__form}
          onSuccess={() => history.push(target || '/')}
        />
      </div>
      <PageFooter />
    </>
  );
}
