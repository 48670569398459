import { addMinutes, format } from 'date-fns';

export const utcToCurrentTimezone = (date: Date | string) =>
  addMinutes(
    typeof date === 'string' ? new Date(date) : date,
    -new Date().getTimezoneOffset()
  );

export const currentTimezoneToUtc = (date: Date | string) =>
  addMinutes(
    typeof date === 'string' ? new Date(date) : date,
    new Date().getTimezoneOffset()
  );

export const getTodayDate = () => format(new Date(), 'yyyy-MM-dd');

export const getDate = (date: Date | string) =>
  format(new Date(date), 'yyyy-MM-dd');

export const getTime = (date: Date | string) => format(new Date(date), 'HH:mm');
