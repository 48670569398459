import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocationQuery } from '@proscom/prostore-react-router';
import { isThisMonth, isThisYear } from 'date-fns';
import { ReactComponent as IconChevron } from '../../../assets/icons/IconChevron.svg';
import { STORE_LOCATION } from '../../../store/storeKeys';
import { URL_KEY_SELECTED_DAYS } from '../../../store/urlKeys';
import s from './Calendar.module.scss';

export interface CalendarDayItemProps {
  date: Date;
  label: string;
  tasksCount: number;
  selected: boolean;
}

export const CalendarDayItem = ({
  date,
  label,
  tasksCount,
  selected
}: CalendarDayItemProps) => {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_SELECTED_DAYS
  ]);
  const dateString = date.toISOString().split('T')[0];
  const onClick = () => {
    if (selected) {
      const newSelectedDays = query[URL_KEY_SELECTED_DAYS].filter(
        (day) => day !== dateString
      );
      const newQueryValue =
        newSelectedDays.length === 0 ? ['all'] : newSelectedDays;
      locationStore.changeQuery({
        [URL_KEY_SELECTED_DAYS]: newQueryValue
      });
    } else {
      locationStore.changeQuery({
        [URL_KEY_SELECTED_DAYS]: [
          ...query[URL_KEY_SELECTED_DAYS].filter((item) => item !== 'all'),
          dateString
        ]
      });
    }
  };

  return (
    <div
      onClick={onClick}
      className={clsx(
        s.CalendarItem,
        s.CalendarItem_day,
        selected && s.CalendarItem_selected
      )}
    >
      <div>{label}</div>
      <div className={s.CalendarItem__tasksCount}>{tasksCount}</div>
    </div>
  );
};

export interface CalendarMonthItemProps {
  label: string;
  days: CalendarDayItemProps[];
  date: Date;
  filtersReseted?: boolean;
  setFiltersReseted?: Dispatch<SetStateAction<boolean>>;
}

export const CalendarMonthItem = ({
  label,
  days,
  date,
  filtersReseted,
  setFiltersReseted
}: CalendarMonthItemProps) => {
  const [open, setOpen] = useState<boolean>(() => isThisMonth(date));

  useEffect(() => {
    if (filtersReseted) {
      setOpen(isThisMonth(date));
    }
  }, [filtersReseted, date]);

  const handleClick = () => {
    setFiltersReseted && setFiltersReseted(false);
    setOpen(!open);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(
          s.CalendarItem,
          s.CalendarItem_month,
          open && s.CalendarItem_open
        )}
      >
        <div>{label}</div>
        <IconChevron className={s.CalendarItem__icon} />
      </div>
      {open && days.map((day, i) => <CalendarDayItem key={i} {...day} />)}
    </>
  );
};

export interface CalendarYearItemProps {
  label: string;
  months: CalendarMonthItemProps[];
  date: Date;
  filtersReseted: boolean;
  setFiltersReseted: Dispatch<SetStateAction<boolean>>;
}

export const CalendarYearItem = ({
  label,
  months,
  date,
  filtersReseted,
  setFiltersReseted
}: CalendarYearItemProps) => {
  const [open, setOpen] = useState<boolean>(() => isThisYear(date));

  useEffect(() => {
    if (filtersReseted) {
      setOpen(isThisYear(date));
    }
  }, [filtersReseted, date]);

  const handleClick = () => {
    setFiltersReseted(false);
    setOpen(!open);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(s.CalendarItem, open && s.CalendarItem_open)}
      >
        <div>{label}</div>
        <IconChevron className={s.CalendarItem__icon} />
      </div>
      {open &&
        months.map((month, i) => (
          <CalendarMonthItem
            key={i}
            {...month}
            filtersReseted={filtersReseted}
            setFiltersReseted={setFiltersReseted}
          />
        ))}
    </>
  );
};
