import React, { useMemo, useState } from 'react';
import { upperFirst } from 'lodash-es';
import { useStore } from '@proscom/prostore-react';
import { useGraphqlMutation } from '../../../../../common/hooks/utils/useGraphqlMutation';
import { EmailServiceName } from '../../../../../graphql/types';
import { EmailAccounts } from '../../../../../graphql/requestsCollections/EmailAccounts';
import { Card } from '../../../../../common/components/ui/Card/Card';
import { EmptyListPlaceholder } from '../../EmptyListPlaceholder/EmptyListPlaceholder';
import {
  EmailAccountSettingModal,
  IEmailAccountSettingFormData
} from '../EmailAccountSettingModal/EmailAccountSettingModal';
import { ReactComponent as IconEmailEdit } from '../../../../../assets/icons/IconEmailEdit.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/IconDelete.svg';
import { ReactComponent as IconSettings } from '../../../../../assets/icons/IconSettings.svg';
import { ReactComponent as IconInformation } from '../../../../../assets/icons/IconInformation.svg';
import { Tooltip } from '../../../../../common/components/ui/Tooltip/Tooltip';
import { EmailAccountsStore } from '../../../../../store/EmailAccountsStore';
import { STORE_EMAIL_ACCOUNTS } from '../../../../../store/storeKeys';
import { EmailAccountDeleteModal } from './EmailAccountDeleteModal';
import s from './EmailAccountsList.module.scss';

interface EmailAccountsListProps {
  reloadGoogleEmailAccount: (email: string) => void;
  reloadYandexEmailAccount: (email: string) => void;
}

export const EmailAccountsList = ({
  reloadGoogleEmailAccount,
  reloadYandexEmailAccount
}: EmailAccountsListProps) => {
  const [{ emailAccounts }, emailAccountsStore] =
    useStore<EmailAccountsStore>(STORE_EMAIL_ACCOUNTS);

  const [editedEmailAccountId, setEditedEmailAccountId] = useState<
    string | null
  >(null);
  const editedEmailAccountFormInitialValues = useMemo<
    Partial<IEmailAccountSettingFormData> | undefined
  >(() => {
    const editedEmailAccount = emailAccounts.find(
      ({ id }) => id === editedEmailAccountId
    );
    if (!editedEmailAccount) return;

    const { __typename, service, imap_port, smtp_port, ...data } =
      editedEmailAccount;
    return {
      ...data,
      imap_port: imap_port.toString(),
      smtp_port: smtp_port.toString()
    };
  }, [emailAccounts, editedEmailAccountId]);

  const {
    run: updateCustomEmailAccountRun,
    loading: updateCustomEmailAccountLoading
  } = useGraphqlMutation(EmailAccounts.updateEmailAccount);
  const handleUpdateCustomAccountSubmit = ({
    smtp_port,
    imap_port,
    imap_password,
    smtp_password,
    ...input
  }: IEmailAccountSettingFormData) =>
    typeof editedEmailAccountId === 'string' &&
    updateCustomEmailAccountRun({
      variables: {
        input: {
          id: editedEmailAccountId,
          ...input,
          smtp_port: parseInt(smtp_port),
          imap_port: parseInt(imap_port),
          imap_password: imap_password || undefined,
          smtp_password: smtp_password || undefined
        }
      }
    }).then(() => setEditedEmailAccountId(null));

  const {
    run: deleteCustomEmailAccountRun,
    loading: deleteCustomEmailAccountLoading
  } = useGraphqlMutation(EmailAccounts.deleteEmailAccount);
  const [deletedEmailAccountId, setDeletedEmailAccountId] = useState<
    string | null
  >(null);
  const deletedEmailAccount = useMemo(() => {
    return emailAccounts.find(({ id }) => id === deletedEmailAccountId);
  }, [emailAccounts, deletedEmailAccountId]);

  return (
    <>
      {editedEmailAccountFormInitialValues && (
        <EmailAccountSettingModal
          isOpen={true}
          onClose={() => setEditedEmailAccountId(null)}
          handleSubmit={handleUpdateCustomAccountSubmit}
          initialValues={editedEmailAccountFormInitialValues}
          submitButtonText="Сохранить"
          newAccountForm={false}
          submitLoading={updateCustomEmailAccountLoading}
        />
      )}
      <EmailAccountDeleteModal
        isOpen={!!deletedEmailAccount}
        onClose={() => setDeletedEmailAccountId(null)}
        onCancel={() => setDeletedEmailAccountId(null)}
        onConfirm={
          deletedEmailAccount &&
          (() =>
            deleteCustomEmailAccountRun({
              variables: { id: deletedEmailAccount.id }
            }))
        }
        email={deletedEmailAccount?.email}
        confirmLoading={deleteCustomEmailAccountLoading}
      />
      {emailAccounts.length ? (
        <div className={s.EmailAccountsList}>
          {emailAccounts.map(
            (
              {
                id,
                name,
                service,
                email,
                imap_error,
                last_successful_mail_update_date
              },
              i
            ) => (
              <Card
                clickable={true}
                className={s.EmailAccountsList__item}
                key={i}
              >
                <div className={s.EmailAccountsList__itemContent}>
                  <Tooltip
                    delay={[200, 200]}
                    text={
                      <>
                        {service === EmailServiceName.Custom
                          ? 'Проверьте настройки. '
                          : 'Авторизируйтесь повторно. '}
                        Для этого нажмите{' '}
                        <span
                          className={s.EmailAccountsList__itemErrorTipButton}
                          onClick={() =>
                            service === EmailServiceName.Custom
                              ? setEditedEmailAccountId(id)
                              : service === EmailServiceName.Google
                              ? reloadGoogleEmailAccount(email)
                              : reloadYandexEmailAccount(email)
                          }
                        >
                          сюда
                        </span>
                      </>
                    }
                    className={s.EmailAccountsList__itemErrorTip}
                    placement="top-start"
                    offset={[0, 0]}
                    disabled={!imap_error}
                    interactive
                  >
                    {imap_error ? (
                      <div className={s.EmailAccountsList__itemError}>
                        Ошибка синхронизации
                        <IconInformation />
                      </div>
                    ) : (
                      <div className={s.EmailAccountsList__itemService}>
                        {name}
                      </div>
                    )}
                  </Tooltip>

                  <div className={s.EmailAccountsList__itemLogin}>
                    {upperFirst(email)}
                  </div>
                </div>
                {service === EmailServiceName.Custom && (
                  <IconSettings
                    className={s.EmailAccountsList__itemEditIcon}
                    onClick={() => setEditedEmailAccountId(id)}
                  />
                )}
                <IconDelete
                  className={s.EmailAccountsList__itemDeleteIcon}
                  onClick={() => setDeletedEmailAccountId(id)}
                />
              </Card>
            )
          )}
        </div>
      ) : (
        <EmptyListPlaceholder
          className={s.EmailAccountsList__empty}
          icon={IconEmailEdit}
          text={
            'У вас пока нет подключенной почты. Воспользуйтесь блоком ниже, чтобы добавить'
          }
          loading={emailAccountsStore.query.state.loading}
        />
      )}
    </>
  );
};
