import React, { ComponentProps, ElementType } from 'react';
import clsx from 'clsx';

import { ReactComponent as IconClose } from '../../../../assets/icons/IconClose.svg';
import s from './Tag.module.scss';

export type TagProps<ComponentType extends ElementType> = {
  component?: ComponentType;
  onRemoveClick?: () => any;
} & ComponentProps<ComponentType>;

export const Tag = <ComponentType extends ElementType = 'div'>({
  component = 'div',
  onRemoveClick,
  className,
  children,
  ...props
}: TagProps<ComponentType>) => {
  const Component = component;
  return (
    <Component className={clsx(s.Tag, className)} {...props}>
      {children}
      {onRemoveClick && (
        <IconClose className={s.Tag__deleteIcon} onClick={onRemoveClick} />
      )}
    </Component>
  );
};
