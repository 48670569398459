import React, { ComponentProps, ElementType, useMemo } from 'react';
import clsx from 'clsx';
import { Loader, loaderColors, LoaderProps } from '../Loader/Loader';
import s from './Button.module.scss';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small'
}

export const ButtonLoaderSize = {
  [ButtonSize.DEFAULT]: 20,
  [ButtonSize.SMALL]: 20
};

export type ButtonProps<ComponentType extends ElementType> = {
  component?: ComponentType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  loaderProps?: LoaderProps;
} & ComponentProps<ComponentType>;

export const Button = <ComponentType extends ElementType = 'button'>({
  children,
  component = 'button',
  variant = ButtonVariant.PRIMARY,
  size = ButtonSize.DEFAULT,
  leftIcon,
  rightIcon,
  className,
  loading,
  loaderProps,
  ...componentProps
}: ButtonProps<ComponentType>) => {
  const Component = component;
  const RightIcon = rightIcon;
  const LeftIcon = leftIcon;

  const loaderColor = useMemo(() => {
    switch (variant) {
      case ButtonVariant.PRIMARY:
        return loaderColors.buttonPrimary;
      case ButtonVariant.SECONDARY:
        return loaderColors.buttonSecondary;
      case ButtonVariant.TERTIARY:
        return loaderColors.buttonTertiary;
      default:
        return loaderColors.buttonPrimary;
    }
  }, [variant]);

  return (
    <Component
      className={clsx(
        s.Button,
        s[`Button_${variant}`],
        s[`Button_${size}`],
        className
      )}
      {...componentProps}
    >
      {loading ? (
        <Loader
          singleColor={loaderColor}
          size={ButtonLoaderSize[size]}
          {...loaderProps}
        />
      ) : (
        <>
          {leftIcon && <LeftIcon className={s.Button__leftIcon} />}
          {children}
          {rightIcon && <RightIcon className={s.Button__rightIcon} />}
        </>
      )}
    </Component>
  );
};
