import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as IconClose } from '../../../assets/icons/IconClose.svg';

import { SettingsStore } from '../../../store/SettingsStore';
import { STORE_SETTINGS } from '../../../store/storeKeys';
import { usePreventWindowScroll } from '../../../common/hooks/usePreventWindowScroll';
import { SettingsTaskTransferPermissions } from './SettingsTaskTransferPermissions/SettingsTaskTransferPermissions';
import { SettingsTags } from './SettingsTags/SettingsTags';
import { SettingsEmail } from './SettingsEmail/SettingsEmail';
import s from './Settings.module.scss';

const settingsTabs = [
  {
    title: 'Передача задач',
    selected: false,
    TabContent: SettingsTaskTransferPermissions
  },
  {
    title: 'Тэги',
    selected: false,
    TabContent: SettingsTags
  },
  {
    title: 'Почта',
    selected: false,
    TabContent: SettingsEmail
  }
];

export interface SettingsProps {
  open: boolean;
  onSettingsClose: () => void;
}

export const Settings = ({ open, onSettingsClose }: SettingsProps) => {
  const [settingsState, settingsStore] =
    useStore<SettingsStore>(STORE_SETTINGS);
  const [tabs, setTabs] = useState(() =>
    settingsTabs.map((tab) =>
      tab.title === settingsState ? { ...tab, selected: true } : tab
    )
  );

  useEffect(() => {
    setTabs(
      settingsTabs.map((tab) =>
        tab.title === settingsState ? { ...tab, selected: true } : tab
      )
    );
  }, [settingsState]);
  usePreventWindowScroll(open);

  return (
    <>
      <div className={clsx(s.Settings, open && s.Settings_open)}>
        <div className={s.Settings__header}>
          <div className={s.Settings__title}>Настройки</div>
          <IconClose
            onClick={onSettingsClose}
            className={s.Settings__closeIcon}
          />
        </div>
        <div className={s.SettingsHeader__tabs}>
          {tabs.map(({ title, selected }, i) => (
            <div
              onClick={() => settingsStore.setSettingTitle(title)}
              className={clsx(
                s.SettingsHeader__tab,
                selected && s.SettingsHeader__tab_selected
              )}
              key={i}
            >
              {title}
            </div>
          ))}
        </div>
        {tabs.map(({ selected, TabContent }, i) => (
          <React.Fragment key={i}>{selected && <TabContent />}</React.Fragment>
        ))}
      </div>
      {open && (
        <div onClick={onSettingsClose} className={s.Settings__overlay} />
      )}
    </>
  );
};
