import React from 'react';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { Button } from '../../../../common/components/ui/Button/Button';

import s from './TaskCardDeleteTaskModal.module.scss';

export interface TaskCardDeleteTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskDelete: () => void;
  deleteTaskLoading: boolean;
}

export const TaskCardDeleteTaskModal = ({
  isOpen,
  onClose,
  onTaskDelete,
  deleteTaskLoading
}: TaskCardDeleteTaskModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <p className={s.TaskCardDeleteTaskModal____description}>
        Вы уверены, что хотите удалить задачу? Это действие нельзя отменить.
      </p>

      <div className={s.TaskCardDeleteTaskModal__buttons}>
        <Button
          className={s.TaskCardDeleteTaskModal__buttonCancel}
          onClick={onClose}
        >
          Отменить
        </Button>
        <Button
          className={s.TaskCardDeleteTaskModal__buttonConfirm}
          onClick={onTaskDelete}
          loading={deleteTaskLoading}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};
