import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import { MUTATION_UPDATE_TASK_SORT_DATE } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseUpdateTaskSortDateReturn {
  handleUpdateTaskSortDate: (taskId: string) => Promise<void>;
  loading: boolean;
}

const UpdateTaskSortDateMutationOptions: MutationOptions<
  {
    updateTaskSortDate: TaskType;
  },
  {
    taskId: string;
  }
> = {
  mutation: MUTATION_UPDATE_TASK_SORT_DATE
};

export const useUpdateTaskSortDate = (): UseUpdateTaskSortDateReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const updateTaskSortDate = useGraphqlMutation(
    UpdateTaskSortDateMutationOptions
  );
  const updateTaskSortDateRun = updateTaskSortDate.run;
  const handleUpdateTaskSortDate = useCallback(
    (taskId) =>
      updateTaskSortDateRun({ variables: { taskId } }).then(({ data }) => {
        const task = data?.updateTaskSortDate;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [updateTaskSortDateRun, tasksStore]
  );
  return {
    handleUpdateTaskSortDate,
    loading: updateTaskSortDate.loading
  };
};
