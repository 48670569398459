import React from 'react';
import clsx from 'clsx';

import { useStore, useStoreState } from '@proscom/prostore-react';
import { useLocationQuery } from '@proscom/prostore-react-router';

import { Tag } from '../../../common/components/ui/Tag/Tag';

import { TasksStoreState } from '../../../store/TasksStore';
import {
  STORE_CALENDAR_OPEN,
  STORE_LOCATION,
  STORE_TASKS
} from '../../../store/storeKeys';
import { URL_KEY_SELECTED_TAGS } from '../../../store/urlKeys';
import { Menu } from '../../../common/components/ui/Menu/Menu';
import { CalendarOpenStore } from '../../../store/CalendarOpenStore';
import { useResetFilters } from '../../../common/hooks/useResetFilters';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/IconCalendar.svg';
import { ReactComponent as ResetIcon } from '../../../assets/icons/IconReset.svg';
import { SearchInput } from './SearchInput/SearchInput';

import s from './IndexPageHeader.module.scss';

export interface IndexPageHeaderProps {
  searchValue: string;
  setSearchValue: (str: string) => void;
  handleCreateTask: () => void;
  createTaskLoading: boolean;
  onSettingsOpen: () => void;
}

export const IndexPageHeader: React.FC<IndexPageHeaderProps> = ({
  searchValue,
  handleCreateTask,
  createTaskLoading,
  setSearchValue,
  onSettingsOpen
}) => {
  const { tagsFilter } = useStoreState<TasksStoreState>(STORE_TASKS);
  const [, calendarStore] = useStore<CalendarOpenStore>(STORE_CALENDAR_OPEN);

  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_SELECTED_TAGS
  ]);
  const onTagClick = (id: string, selected: boolean) => {
    if (selected) {
      locationStore.changeQuery({
        [URL_KEY_SELECTED_TAGS]: query[URL_KEY_SELECTED_TAGS]
          ? query[URL_KEY_SELECTED_TAGS].filter(
              (selectedTagId) => selectedTagId !== id
            )
          : []
      });
    } else {
      locationStore.changeQuery({
        [URL_KEY_SELECTED_TAGS]: query[URL_KEY_SELECTED_TAGS]
          ? [...query[URL_KEY_SELECTED_TAGS], id]
          : [id]
      });
    }
  };

  const resetFilters = useResetFilters();

  return (
    <div className={s.IndexPageHeader}>
      <div onClick={resetFilters} className={s.IndexPageHeader__reset}>
        <ResetIcon className={s.IndexPageHeader__resetIcon} />
        Сбросить фильтры
      </div>

      <SearchInput
        className={s.IndexPageHeader__search}
        value={searchValue}
        setValue={setSearchValue}
        handleCreateTask={handleCreateTask}
        createTaskLoading={createTaskLoading}
      />

      <div
        onClick={() => calendarStore.toggleOpen()}
        className={s.IndexPageHeader__calendar}
      >
        <CalendarIcon />
      </div>

      <Menu
        onSettingsOpen={onSettingsOpen}
        className={s.IndexPageHeader__menu}
      />
      <div className={s.IndexPageHeader__tags}>
        {tagsFilter.map(({ name, id, selected }, i) => (
          <Tag
            key={i}
            className={clsx(
              s.IndexPageHeader__tag,
              selected && s.IndexPageHeader__tag_selected
            )}
            onClick={() => onTagClick(id, selected)}
          >
            {name}
          </Tag>
        ))}
      </div>
    </div>
  );
};
