import React, { useState } from 'react';

import { useStore } from '@proscom/prostore-react';
import {
  OAuthCompleteData,
  OAuthCompleteStatus,
  OAuthErrorData,
  OAuthServiceRoute
} from '../../../../common/hooks/oauth/OAuthMixin';
import { EmailAccountsStore } from '../../../../store/EmailAccountsStore';
import { STORE_EMAIL_ACCOUNTS } from '../../../../store/storeKeys';
import { useGraphqlMutation } from '../../../../common/hooks/utils/useGraphqlMutation';
import { EmailAccounts } from '../../../../graphql/requestsCollections/EmailAccounts';
import { useCreateEmailAccountWithOAuth } from '../../../../common/hooks/oauth/useCreateEmailAccountWithOAuth';
import {
  EmailAccountSettingModal,
  IEmailAccountSettingFormData
} from './EmailAccountSettingModal/EmailAccountSettingModal';
import { CreateEmailAccount } from './CreateEmailAccount/CreateEmailAccount';
import { EmailAccountsList } from './EmailAccountsList/EmailAccountsList';
import { OAuthErrorModal } from './CreateEmailAccount/OAuthErrorModal';
import s from './SettingsEmail.module.scss';

export const SettingsEmail = () => {
  const [oauthError, setOAuthError] = useState<OAuthErrorData | null>(null);
  const [oauthErrorModalOpen, setOAuthErrorModalOpen] =
    useState<boolean>(false);
  const [createCustomAccountModalOpen, setCreateCustomAccountModalOpen] =
    useState<boolean>(false);
  const onCloseCreateCustomAccountModal = () =>
    setCreateCustomAccountModalOpen(false);

  const [, emailAccountsStore] =
    useStore<EmailAccountsStore>(STORE_EMAIL_ACCOUNTS);

  const {
    run: createCustomEmailAccountRun,
    loading: createCustomEmailAccountLoading
  } = useGraphqlMutation(EmailAccounts.createCustomEmailAccount);
  const handleCreateCustomAccountSubmit = ({
    smtp_port,
    imap_port,
    ...input
  }: IEmailAccountSettingFormData) =>
    createCustomEmailAccountRun({
      variables: {
        input: {
          ...input,
          smtp_port: parseInt(smtp_port),
          imap_port: parseInt(imap_port)
        }
      }
    }).then(onCloseCreateCustomAccountModal);

  const handleEmailAccountCreated = (data: OAuthCompleteData) => {
    if (data.status === OAuthCompleteStatus.SUCCESS) {
      emailAccountsStore.loadEmailAccounts();
    } else {
      setOAuthError(data);
      setOAuthErrorModalOpen(true);
    }
  };

  const { createEmailAccountWithOAuth } = useCreateEmailAccountWithOAuth(
    handleEmailAccountCreated
  );

  const createGoogleEmailAccount = () =>
    createEmailAccountWithOAuth({ serviceRoute: OAuthServiceRoute.GOOGLE });
  const createYandexEmailAccount = () =>
    createEmailAccountWithOAuth({ serviceRoute: OAuthServiceRoute.YANDEX });

  const reloadGoogleEmailAccount = (email: string) =>
    createEmailAccountWithOAuth({
      serviceRoute: OAuthServiceRoute.GOOGLE,
      email
    });
  const reloadYandexEmailAccount = (email: string) =>
    createEmailAccountWithOAuth({
      serviceRoute: OAuthServiceRoute.YANDEX,
      email
    });

  return (
    <>
      <OAuthErrorModal
        isOpen={!!oauthError && oauthErrorModalOpen}
        onClose={() => setOAuthErrorModalOpen(false)}
        {...oauthError}
      />
      <EmailAccountSettingModal
        isOpen={createCustomAccountModalOpen}
        onClose={onCloseCreateCustomAccountModal}
        handleSubmit={handleCreateCustomAccountSubmit}
        submitLoading={createCustomEmailAccountLoading}
      />
      <div className={s.SettingsEmail__accounts}>
        <p className={s.SettingsEmail__description}>Ваши почты:</p>
        <EmailAccountsList
          reloadGoogleEmailAccount={reloadGoogleEmailAccount}
          reloadYandexEmailAccount={reloadYandexEmailAccount}
        />
      </div>
      <div className={s.SettingsEmail__addAccount}>
        <p className={s.SettingsEmail__description}>Добавить:</p>
        <CreateEmailAccount
          createYandexEmailAccount={createYandexEmailAccount}
          createGoogleEmailAccount={createGoogleEmailAccount}
          setCreateCustomAccountModalOpen={setCreateCustomAccountModalOpen}
        />
      </div>
    </>
  );
};
