import React from 'react';
import { Button } from '../../../../../common/components/ui/Button/Button';
import { Modal } from '../../../../../common/components/ui/Modal/Modal';
import { TagType } from '../../../../../graphql/types';
import s from './SettingsDeleteTagModal.module.scss';

export interface TaskCardTransferTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTagDelete: (tagId: string) => void;
  currentTag: TagType;
  deleteTagLoading?: boolean;
}

export const SettingsDeleteTagModal = ({
  isOpen,
  onClose,
  onTagDelete,
  currentTag,
  deleteTagLoading
}: TaskCardTransferTaskModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <p className={s.SettingsDeleteTagModal____description}>
        Вы уверены, что хотите удалить тег «{currentTag.name}»? Это действие
        нельзя будет отменить
      </p>

      <div className={s.SettingsDeleteTagModal__buttons}>
        <Button
          className={s.SettingsDeleteTagModal__buttonCancel}
          onClick={onClose}
        >
          Оставить
        </Button>
        <Button
          className={s.SettingsDeleteTagModal__buttonConfirm}
          onClick={() => onTagDelete(currentTag.id)}
          loading={deleteTagLoading}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};
