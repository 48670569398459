export const normalizeString = (str: string) => str.trim().toLowerCase();

export const htmlToPlainText = (str: string) => str.replace(/<[^>]*>/g, '');
export const emailTextToPreview = (input: string) => {
  return input
    .replace(/^(\r\n|\n|\r)/gm, '')
    .split(/(\r\n|\n|\r)/gm)[0]
    .replace(/^(&nbsp;|&zwnj;|[\s\u200C\u00A0 ⠀])+/gi, '')
    .split(/([\s\u200C\u00A0]|&nbsp;|&zwnj;){2,}/gi)[0]
    .replace(/(&nbsp;|&zwnj;|[\s\u200C\u00A0⠀])+/gi, ' ');
};

export const htmlToInlineText = (input: string) => {
  return input
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/<script.*<\/script>/gi, '')
    .replace(/<head>.*<\/head>/gi, '')
    .replace(/<style[^>]*>[^<]*<\/style>/gi, '')
    .replace(/<[^>]*>/g, '')
    .replace(/\uFFFD+/gi, '')
    .replace(/^(&nbsp;|&zwnj;|[\s\u200C\u00A0 ⠀])+/gi, '')
    .split(/([\s\u200C\u00A0]|&nbsp;|&zwnj;){2,}/gi)[0]
    .replace(/(&nbsp;|&zwnj;|[\s\u200C\u00A0⠀])+/gi, ' ');
};

export const normalizeLetterHtml = (input: string) => {
  let bodyStyle = 'margin:0;padding:0;overflow-y:hidden;width:fit-content;';
  const bodyStyleMatch = /<body style="([^"]+)"/gim.exec(input);
  if (bodyStyleMatch && bodyStyleMatch[1]) {
    bodyStyle += bodyStyleMatch[1];
  }

  return `<div style="${bodyStyle}">${input}</div>`;
};

// Вытаскивает email-адрес из строки "Имя Фамилия <email@gmail.com>"
export const normalizeEmail = (str: string) =>
  str
    .slice(str.indexOf('<') + 1)
    .replace('>', '')
    .trim();

export const wrapHtmlLetter = (content: string) => {
  return `
    <!DOCTYPE html>
    <html
      lang='en'
      xmlns="http://www.w3.org/1999/xhtml" 
      xmlns:o="urn:schemas-microsoft-com:office:office"
    >
      <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      </head>
      <body>
        ${content}
      </body>
    </html>
  `;
};
