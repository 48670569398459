import gql from 'graphql-tag';

export const QUERY_TASK_TRANSFER_PERMISSIONS = gql`
  query queryTaskTransferPermissions {
    taskTransferPermissions {
      id
      transfer_allowed
      transfererUsername
    }
  }
`;
