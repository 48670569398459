import React, {
  KeyboardEvent,
  ChangeEvent,
  useState,
  RefObject,
  useMemo,
  HTMLAttributes
} from 'react';
import clsx from 'clsx';
import Tippy from '@tippyjs/react';
import { useCreateTag } from '../../../../graphql/hooks/tags/useCreateTag';
import { useAttachTagToTask } from '../../../../graphql/hooks/tasks/useAttachTagToTask';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import { AutosizeInput } from '../../../../common/components/ui/AutosizeInput/AutosizeInput';
import { useSearchTags } from '../../../../graphql/hooks/tags/useSearchTags';
import { DropdownItems } from '../../../../common/components/ui/DropdownItems/DropdownItems';
import s from './NewTag.module.scss';

type searchIndexType = number | null;
export interface NewTagProps
  extends Omit<
    HTMLAttributes<HTMLInputElement>,
    'style' | 'value' | 'onChange' | 'onKeyDown' | 'onBlur'
  > {
  taskId: number;
  existingTags: string[];
  onFinish?: () => any;
  inputRef?: RefObject<HTMLInputElement>;
}

const attachTagErrorMessage =
  'Произошла ошибка при добавлении тега, пожалуйста, попробуйте еще раз или перезагрузите страницу';

export const NewTag = ({
  taskId,
  onFinish,
  className,
  inputRef,
  existingTags,
  ...props
}: NewTagProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [visibleValue, setVisibleValue] = useState('');

  const searchTagsRequestResult = useSearchTags(searchValue, [taskId]);
  const searchResult = useMemo(() => {
    if (!searchTagsRequestResult.state.data) {
      return [];
    }

    return searchTagsRequestResult.state.data;
  }, [searchTagsRequestResult.state.data]);

  const [focusedTagI, setFocusedTagI] = useState<searchIndexType>(null);

  const { handleCreateTag } = useCreateTag();
  const { handleAttachTagToTask } = useAttachTagToTask();
  const attachNewTagToTask = async (tagId?: number) => {
    try {
      if (typeof tagId === 'number') {
        await handleAttachTagToTask({ taskId, tagId });
        return onFinish?.();
      }

      if (focusedTagI !== null && searchResult[focusedTagI]) {
        await handleAttachTagToTask({
          taskId,
          tagId: parseInt(searchResult[focusedTagI].id)
        });
        return onFinish?.();
      }

      if (!searchValue || existingTags.includes(searchValue)) {
        return onFinish?.();
      }

      const tagWithCurrentName = searchResult.find(
        ({ name: tagName }) => tagName === searchValue
      );
      if (tagWithCurrentName) {
        await handleAttachTagToTask({
          taskId,
          tagId: parseInt(tagWithCurrentName.id)
        });
        return onFinish?.();
      }

      const createTagResult = await handleCreateTag({ name: searchValue });
      if (!createTagResult) {
        handleDefaultError(attachTagErrorMessage);
      } else {
        await handleAttachTagToTask({
          taskId,
          tagId: parseInt(createTagResult.id)
        });
        return onFinish?.();
      }
    } catch (e) {
      handleDefaultError(attachTagErrorMessage, e);
    }
  };

  const handleFocusOnItem = (i: searchIndexType) => {
    setFocusedTagI(i);
    const inputValue = searchResult[i as number]?.name || searchValue;
    setVisibleValue(inputValue);
  };

  const getNextIndex = {
    ArrowUp: (currentIdx: searchIndexType, length: number) =>
      currentIdx === 0
        ? null
        : currentIdx === null
        ? length - 1
        : currentIdx - 1,
    ArrowDown: (currenIdx: searchIndexType, length: number) =>
      currenIdx === length - 1 ? null : currenIdx === null ? 0 : currenIdx + 1
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setVisibleValue(e.target.value);
    setFocusedTagI(null);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        attachNewTagToTask();
        break;
      case 'Escape':
        onFinish?.();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();
        if (!searchResult.length) {
          return;
        }
        const nextItemIndex = getNextIndex[e.key](
          focusedTagI,
          searchResult.length
        );
        handleFocusOnItem(nextItemIndex);
    }
  };

  const onInputBlur = () => {
    if (!searchResult.length) {
      attachNewTagToTask();
    }
  };

  const onClickOutsideDropdown = () => {
    attachNewTagToTask();
  };

  const onItemClick = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation();
    attachNewTagToTask(parseInt(id));
  };

  return (
    <Tippy
      content={
        <DropdownItems
          className={s.NewTag__dropdown}
          itemClassName={s.NewTag__dropdownItem}
          items={searchResult.map(({ id, name }, i) => ({
            text: name,
            onMouseEnter: () => handleFocusOnItem(i),
            onMouseLeave: () => handleFocusOnItem(null),
            onClick: (e) => onItemClick(e, id),
            focused: i === focusedTagI
          }))}
        />
      }
      interactive
      visible={searchResult.length > 0}
      placement="bottom-end"
      onClickOutside={onClickOutsideDropdown}
    >
      <AutosizeInput
        ref={inputRef}
        style={{
          paddingRight: 7,
          paddingLeft: 7
        }}
        className={clsx(s.NewTag, className)}
        value={visibleValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onInputBlur}
        maxLength={40}
        {...props}
      />
    </Tippy>
  );
};
