import {
  defineQueryTransformers,
  ExtractTransformedQueryParams,
  IQueryTransformer
} from '@proscom/prostore-react-router';
import { encodeQueryArray, parseQueryArray } from '@proscom/ui-utils';

// Рекомендуется перечислять все query-параметры, используемые в приложении,
// в виде констант и использовать их только через эти константы.
// Так легче находить места в коде, где они используются.

export const URL_KEY_TARGET = 'target' as const;
export const URL_KEY_TASK_ID = 'taskId' as const;
export const URL_KEY_SELECTED_DAYS = 'selectedDays' as const;
export const URL_KEY_SELECTED_TAGS = 'selectedTags' as const;
export const URL_KEY_SEARCH_VALUE = 'searchValue' as const;

const parseQuerySimple = <T>(query: T) => {
  return query || undefined;
};
const stringifyQuerySimple = <T>(data: T) => {
  return data ? String(data) : undefined;
};
const defaultTransformer: IQueryTransformer = {
  parse: parseQuerySimple,
  stringify: stringifyQuerySimple
};
const booleanTransformer: IQueryTransformer<boolean | undefined> = {
  parse(value) {
    return value === 'true' ? true : value === 'false' ? false : undefined;
  },
  stringify(value: boolean | undefined) {
    return value === true ? 'true' : value === false ? 'false' : undefined;
  }
};
const numberTransformer: IQueryTransformer<number | undefined> = {
  parse(value) {
    return typeof value === 'string' ? +value : undefined;
  },
  stringify(value: number | undefined) {
    return value ? String(value) : undefined;
  }
};
const arrayTransformer: IQueryTransformer = {
  parse: (queryValue) =>
    typeof queryValue === 'string' ? parseQueryArray(queryValue) : queryValue,
  stringify: encodeQueryArray
};

// Трансформеры определяют, как парсить и сериализовать query-параметры из адресной строки
export const urlTransformers = defineQueryTransformers({
  [URL_KEY_TARGET]: defaultTransformer,
  [URL_KEY_TASK_ID]: numberTransformer,
  [URL_KEY_SELECTED_DAYS]: arrayTransformer,
  [URL_KEY_SELECTED_TAGS]: arrayTransformer,
  [URL_KEY_SEARCH_VALUE]: defaultTransformer
});

type UrlTransformersType = typeof urlTransformers;

export type TransformedQueryParams =
  ExtractTransformedQueryParams<UrlTransformersType>;
