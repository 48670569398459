import { KeyboardEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { format } from 'date-fns';
import { useStore } from '@proscom/prostore-react';
import { STORE_SYSTEM_LOGS_ENABLED } from '../../../../store/storeKeys';
import { SystemLogsEnabledStore } from '../../../../store/SystemLogsEnabledStore';
import { ReactComponent as IconChevron } from '../../../../assets/icons/IconChevron.svg';
import { handleDefaultError } from '../../../../utils/handleDefaultError';
import {
  TaskLogStoryVariant,
  TaskType,
  TaskTypeEnum
} from '../../../../graphql/types';
import { useCreateComment } from '../../../../graphql/hooks/tasks/useCreateComment';
import { useDeleteComment } from '../../../../graphql/hooks/tasks/useDeleteComment';
import { Input } from '../../../../common/components/ui/Input/Input';
import { DropdownItems } from '../../../../common/components/ui/DropdownItems/DropdownItems';
import { TaskLogStoryLetter } from './TaskLogStoryLetter/TaskLogStoryLetter';
import { TaskLogStoryLetterDraft } from './TaskLogStoryLetterDraft/TaskLogStoryLetterDraft';
import s from './TaskLog.module.scss';

const addCommentErrorMessage =
  'Произошла ошибка при добавлении комментария, пожалуйста, попробуйте еще раз или перезагрузите страницу';
const deleteCommentErrorMessage =
  'Произошла ошибка при удаление комментария, пожалуйста, попробуйте еще раз или перезагрузите страницу';

export interface TaskLogProps {
  className?: string;
  task: TaskType;
}

export const TaskLog = ({ className, task }: TaskLogProps) => {
  const [commentInputValue, setCommentInputValue] = useState<string>('');
  const [dropdownOpenCommentId, setDropdownOpenCommentId] = useState<
    string | null
  >(null);

  const [systemLogsEnabled] = useStore<SystemLogsEnabledStore>(
    STORE_SYSTEM_LOGS_ENABLED
  );

  const { handleCreateComment } = useCreateComment();
  const { handleDeleteComment } = useDeleteComment();

  const createNewComment = useCallback(
    async (commentInputValue: string) => {
      try {
        await handleCreateComment({
          taskId: task.id.toString(),
          comment: commentInputValue
        });
      } catch (e) {
        handleDefaultError(addCommentErrorMessage, e);
      }
    },
    [task, handleCreateComment]
  );

  const onCommentInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      switch (e.key) {
        case 'Enter':
          createNewComment(target.value);
          setCommentInputValue('');
          break;
      }
    },
    [createNewComment]
  );

  const deleteComment = async (logStoryId: string) => {
    try {
      await handleDeleteComment(logStoryId);
      setDropdownOpenCommentId(null);
      toast('Комментарий удален');
    } catch (e) {
      handleDefaultError(deleteCommentErrorMessage, e);
    }
  };

  return (
    <div className={clsx(s.TaskLog, className)}>
      {task.task_type !== TaskTypeEnum.Email && (
        <Input
          value={commentInputValue}
          onChange={setCommentInputValue}
          onKeyDown={onCommentInputKeyDown}
          placeholder="Комментарий..."
          className={s.TaskLog__commentInput}
          maxLength={255}
        />
      )}
      {task.log.map(
        (logStory, i) =>
          (logStory.variant === TaskLogStoryVariant.Email ||
            logStory.variant === TaskLogStoryVariant.EmailDraft ||
            logStory.variant === TaskLogStoryVariant.Comment ||
            systemLogsEnabled.value) &&
          (logStory.variant === TaskLogStoryVariant.Email &&
          logStory.emailLetter ? (
            <TaskLogStoryLetter
              date={new Date(logStory.created_at)}
              emailAccountDeleted={task.email_account_deleted || undefined}
              key={logStory.id}
              taskId={task.id}
              taskLogStoryId={logStory.id}
              emailLetter={logStory.emailLetter}
              emailLetterDraft={logStory.emailLetterDraft}
            />
          ) : logStory.variant === TaskLogStoryVariant.EmailDraft &&
            logStory.emailLetterDraft ? (
            <TaskLogStoryLetterDraft
              {...logStory.emailLetterDraft}
              date={new Date(logStory.created_at)}
              taskId={task.id}
              key={logStory.id}
            />
          ) : (
            <div
              className={clsx(
                s.TaskLog__logStory,
                logStory.variant === TaskLogStoryVariant.Comment &&
                  s.TaskLog__logStory_comment
              )}
              key={i}
            >
              {logStory.message}
              <div className={s.TaskLog__logStoryCaption}>
                <div className={s.TaskLog__logStoryDate}>
                  {format(new Date(logStory.created_at), 'yyyy-MM-dd, HH:mm')}
                </div>
                {logStory.variant === TaskLogStoryVariant.Comment && (
                  <Tippy
                    content={
                      <DropdownItems
                        itemClassName={s.Message__dropdownItem}
                        items={[
                          {
                            text: 'Удалить',
                            onClick: () => deleteComment(logStory.id)
                          }
                        ]}
                      />
                    }
                    interactive
                    visible={dropdownOpenCommentId === logStory.id}
                    placement="bottom-end"
                    onClickOutside={() => setDropdownOpenCommentId(null)}
                  >
                    <IconChevron
                      onClick={() =>
                        setDropdownOpenCommentId(
                          dropdownOpenCommentId === logStory.id
                            ? null
                            : logStory.id
                        )
                      }
                      className={clsx(
                        s.TaskLog__commentDropdownToggleIcon,
                        dropdownOpenCommentId === logStory.id &&
                          s.TaskLog__commentDropdownToggleIcon_dropdownOpen
                      )}
                    />
                  </Tippy>
                )}
              </div>
            </div>
          ))
      )}
    </div>
  );
};
