import React, { useCallback, useRef } from 'react';
import { Portal } from 'react-portal';
import clsx from 'clsx';
import { useClickOutside } from '@proscom/ui-react';
import { usePreventWindowScroll } from '../../../hooks/usePreventWindowScroll';
import { Window, WindowProps } from '../Window/Window';
import s from './Modal.module.scss';

export interface ModalProps extends Omit<WindowProps, 'windowRef'> {
  isOpen?: boolean;
}

export const Modal = ({ isOpen, className, onClose, ...props }: ModalProps) => {
  const windowRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  usePreventWindowScroll(isOpen);

  useClickOutside(windowRef, handleClickOutside);

  return isOpen ? (
    <Portal>
      <div className={s.ModalOverlay}>
        <Window
          windowRef={windowRef}
          className={clsx(s.Modal, className)}
          onClose={onClose}
          {...props}
        />
      </div>
    </Portal>
  ) : null;
};
