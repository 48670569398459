import React, { ComponentProps, ElementType } from 'react';
import clsx from 'clsx';

import s from './Text.module.scss';

export enum TextVariantsEnum {
  BODY_S = 'bodyS',
  BODY_M = 'bodyM',
  CAPTION_M = 'captionM'
}

export type TextProps<ComponentType extends ElementType> = {
  component?: ComponentType;
  variant?: TextVariantsEnum;
} & ComponentProps<ComponentType>;

export const Text = <ComponentType extends ElementType = 'p'>({
  component = 'p',
  variant = TextVariantsEnum.BODY_M,
  className,
  ...props
}: TextProps<ComponentType>) => {
  const Component = component;
  return (
    <Component
      className={clsx(s.Text, s[`Text_${variant}`], className)}
      {...props}
    />
  );
};
