import gql from 'graphql-tag';
import { FRAGMENT_FILE_FULL } from './files';

export const EMAIL_LETTER_FULL = gql`
  fragment emailLetterFull on EmailLetterType {
    attachments {
      ...fileFull
    }
    email_account_id
    bcc
    cc
    from
    html
    id
    subject
    to
    email_letter_tag
    plain_text
    htmlFile {
      ...fileFull
    }
  }

  ${FRAGMENT_FILE_FULL}
`;

export const EMAIL_LETTER_DRAFT_FULL = gql`
  fragment emailLetterDraftFull on EmailLetterDraftType {
    attachments {
      ...fileFull
    }
    email_account_id
    bcc
    cc
    html
    id
    subject
    to
    is_forwarded
  }

  ${FRAGMENT_FILE_FULL}
`;

export const FRAGMENT_TASK_FULL = gql`
  fragment taskFull on TaskType {
    id
    name
    status
    due_date
    sort_date
    favorite
    transfer_pending
    email_account_deleted
    tags {
      id
      name
    }
    log {
      id
      variant
      message
      created_at
      search_indexing_value
      emailLetter {
        ...emailLetterFull
      }
      emailLetterDraft {
        ...emailLetterDraftFull
      }
    }
    task_type
    email_task_tag
    file {
      ...fileFull
    }
    file_id
  }

  ${FRAGMENT_FILE_FULL}
  ${EMAIL_LETTER_FULL}
  ${EMAIL_LETTER_DRAFT_FULL}
`;
