import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType, SubmitNewLetterDraftInput } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { MUTATION_SUBMIT_NEW_LETTER_DRAFT } from '../../mutations/letterDrafts';

export interface UseSubmitLetterDraftReturn {
  handleSubmitLetterDraft: (input: SubmitNewLetterDraftInput) => Promise<void>;
  loading: boolean;
}

const SubmitLetterDraftMutationOptions: MutationOptions<
  {
    submitNewLetterDraft: TaskType;
  },
  {
    input: SubmitNewLetterDraftInput;
  }
> = {
  mutation: MUTATION_SUBMIT_NEW_LETTER_DRAFT
};

export const useSubmitLetterDraft = (): UseSubmitLetterDraftReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const submitLetterDraft = useGraphqlMutation(
    SubmitLetterDraftMutationOptions
  );
  const submitLetterDraftRun = submitLetterDraft.run;

  const handleSubmitLetterDraft = useCallback(
    (input: SubmitNewLetterDraftInput) =>
      submitLetterDraftRun({ variables: { input } }).then(({ data }) => {
        const task = data?.submitNewLetterDraft;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [submitLetterDraftRun, tasksStore]
  );
  return {
    handleSubmitLetterDraft,
    loading: submitLetterDraft.loading
  };
};
