import { BehaviorStore, skipIf, SubscriptionManager } from '@proscom/prostore';
import ApolloClient from 'apollo-client';
import { GraphqlWatchQueryStore } from '@proscom/prostore-apollo';
import { LocationStore } from '@proscom/prostore-react-router';
import { concat, flatMap, uniqBy } from 'lodash-es';
import { isAfter } from 'date-fns';
import { getDate, getTodayDate } from '../utils/date';
import { QUERY_TASKS } from '../graphql/queries/tasks';
import { TagType, TaskStatus, TaskType, TaskTypeEnum } from '../graphql/types';
import { normalizeString } from '../utils/stringUtils';
import { AuthStore } from './AuthStore';
import {
  URL_KEY_SEARCH_VALUE,
  URL_KEY_SELECTED_DAYS,
  URL_KEY_SELECTED_TAGS
} from './urlKeys';

export interface TasksFilterDay {
  dateString: string;
  tasksCount: number;
  selected?: boolean;
}
export interface TasksStoreState {
  tasks: Array<TaskType & { filtered?: boolean }>;
  daysFilter: TasksFilterDay[];
  tagsFilter: Array<TagType & { selected: boolean }>;
}
export interface TasksStoreArgs {
  authStore: AuthStore;
  locationStore: LocationStore;
  client: ApolloClient<any>;
}

export interface TasksQueryVars {
  isLoggedIn: boolean;
}
export interface TasksQueryData {
  tasks: TaskType[];
  days: TasksFilterDay[];
  tags: TagType[];
}

const taskTypesSearchIndexingValues = {
  [TaskTypeEnum.Text]: 'задача',
  [TaskTypeEnum.Email]: 'письмо',
  [TaskTypeEnum.File]: 'файл'
};

export class TasksStore extends BehaviorStore<TasksStoreState> {
  authStore: AuthStore;
  locationStore: LocationStore;
  sub = new SubscriptionManager();
  tasksQuery: GraphqlWatchQueryStore<TasksQueryVars, TasksQueryData>;
  wasRedirectedToCurrentDay = false;

  constructor({ authStore, locationStore, client }: TasksStoreArgs) {
    super({ tasks: [], daysFilter: [], tagsFilter: [] });
    this.authStore = authStore;
    this.locationStore = locationStore;
    this.tasksQuery = new GraphqlWatchQueryStore<
      TasksQueryVars,
      TasksQueryData
    >({
      client,
      query: QUERY_TASKS,
      mapData: (result: { currentUserTasks: TaskType[] | null }) =>
        this.parseTasks(result.currentUserTasks),
      skipQuery: skipIf((vars) => !vars.isLoggedIn, {
        tasks: [],
        days: [],
        tags: []
      }),
      updateData: (data) => data,
      apolloOptions: {
        fetchPolicy: 'network-only'
      }
    });
    this.sub.subscribe(this.authStore.state$, this.loadTasks.bind(this));
    this.sub.subscribe(
      this.tasksQuery.state$,
      this.handleTasksLoaded.bind(this)
    );
    this.sub.subscribe(this.locationStore.state$, this.applyFilters.bind(this));
  }

  async loadTasks() {
    await this.tasksQuery.loadData({ isLoggedIn: this.authStore.isLoggedIn() });
  }

  async handleTasksLoaded() {
    if (!this.tasksQuery.state.data) return;
    const { tasks, days, tags } = this.tasksQuery.state.data;
    this.setState({
      tasks,
      daysFilter: days.map((day) => ({ ...day, selected: false })),
      tagsFilter: tags.map((tag) => ({ ...tag, selected: false }))
    });
    this.applyFilters();
    this.applySort();
  }

  parseTasks(rawTasks: TaskType[] | null): TasksQueryData {
    const tasks = rawTasks
      ? rawTasks.map(
          (task) =>
            task && {
              ...task,
              due_date: new Date(task.due_date).toISOString()
            }
        )
      : [];
    const days = this.parseTasksDays(tasks);
    const tags = uniqBy(concat([], ...tasks.map(({ tags }) => tags)), 'id');
    return {
      tasks,
      days,
      tags
    };
  }

  parseTasksDays(tasks: TaskType[]) {
    const days: TasksFilterDay[] = [];
    for (const task of tasks) {
      const dateString = getDate(task.due_date);
      const day = days.find((day) => day.dateString === dateString);
      if (day) {
        day.tasksCount++;
      } else {
        days.push({
          dateString,
          tasksCount: 1
        });
      }
    }
    return days;
  }

  applyFilters() {
    if (!this.locationStore.state.query) return;
    if (!this.authStore.isLoggedIn()) return;

    const { tasks, daysFilter } = this.state;
    const newState: TasksStoreState = {
      tasks: tasks.map((task) => ({ ...task, filtered: false })),
      daysFilter: daysFilter.map((day) => ({ ...day, selected: false })),
      tagsFilter: uniqBy(
        concat([], ...tasks.map(({ tags }) => tags)),
        'id'
      ).map((tag) => ({ ...tag, selected: false }))
    };

    const selectedDaysQueryValue: string[] =
      this.locationStore.state.query[URL_KEY_SELECTED_DAYS];
    if (
      this.locationStore.state.location?.pathname === '/' &&
      !selectedDaysQueryValue?.length
    ) {
      const today = getTodayDate();
      this.locationStore.changeQuery(
        {
          [URL_KEY_SELECTED_DAYS]: [today]
        },
        true
      );
      return;
    }

    if (newState?.daysFilter.length) {
      const selectedDays = this.locationStore.state.query[
        URL_KEY_SELECTED_DAYS
      ].reduce(
        (accum, selectedDay) => {
          if (selectedDay === 'all') return accum;
          const isSelectedDayInCalendar = newState.daysFilter.some(
            (dayFilter) => dayFilter.dateString === selectedDay
          );
          isSelectedDayInCalendar
            ? accum.rightDays.push(selectedDay)
            : accum.wrongDays.push(selectedDay);
          return accum;
        },
        { rightDays: [], wrongDays: [] }
      );

      if (selectedDays.wrongDays.length) {
        this.locationStore.changeQuery(
          {
            [URL_KEY_SELECTED_DAYS]: [
              selectedDays.rightDays.length ? selectedDays.rightDays : 'all'
            ]
          },
          true
        );
      }
    }

    let hasSelectedDays = false;
    if (selectedDaysQueryValue) {
      for (const selectedDay of selectedDaysQueryValue) {
        const day = newState.daysFilter.find(
          (day) => day.dateString === selectedDay
        );
        if (day) {
          day.selected = true;
          hasSelectedDays = true;
        }
      }
    }

    const selectedTagIds: string[] =
      this.locationStore.state.query[URL_KEY_SELECTED_TAGS];
    let hasSelectedTags = false;
    if (selectedTagIds) {
      for (const tagId of selectedTagIds) {
        const tag = newState.tagsFilter.find((tag) => tag.id === tagId);
        if (tag) {
          tag.selected = true;
          hasSelectedTags = true;
        }
      }
    }

    const searchValue: string =
      this.locationStore.state.query[URL_KEY_SEARCH_VALUE];
    const hasSearchValue = !!searchValue;

    for (const task of newState.tasks) {
      const numberOfSelectedTagsInTask = task.tags.reduce(
        (accum, tag) => (selectedTagIds?.includes(tag.id) ? accum + 1 : accum),
        0
      );
      const matchesTagsFilter =
        !hasSelectedTags ||
        task.tags.some(
          (taskTag) =>
            selectedTagIds?.includes(taskTag.id) &&
            numberOfSelectedTagsInTask === selectedTagIds?.length
        );

      if (hasSearchValue) {
        const normalizedSearchValue = normalizeString(searchValue);
        const nameIncludes = normalizeString(task.name || '').includes(
          normalizedSearchValue
        );
        const taskTypeIncludes = normalizeString(
          taskTypesSearchIndexingValues[task.task_type]
        ).includes(normalizedSearchValue);
        const matchesSearchFilter =
          nameIncludes ||
          taskTypeIncludes ||
          task.log.some(
            (logStory) =>
              logStory.search_indexing_value &&
              normalizeString(logStory.search_indexing_value).includes(
                normalizedSearchValue
              )
          ) ||
          task.tags.some((tag) =>
            normalizeString(tag.name).includes(normalizedSearchValue)
          );
        task.filtered = !matchesTagsFilter || !matchesSearchFilter;
        continue;
      }

      const matchesDaysFilter =
        !hasSelectedDays ||
        newState.daysFilter.find(
          (day) => day.dateString === getDate(task.due_date)
        )?.selected;

      task.filtered = !matchesTagsFilter || !matchesDaysFilter;
    }

    newState.tagsFilter = uniqBy(
      flatMap(
        newState.tasks.filter(({ filtered }) => !filtered),
        ({ tags }) =>
          tags.map((tag) => ({
            ...tag,
            selected: selectedTagIds?.includes(tag.id)
          }))
      ),
      'id'
    ).filter((tag) =>
      newState.tasks.some(
        ({ tags, filtered }) =>
          !filtered && tags.find((taskTag) => taskTag.id === tag.id)
      )
    );

    this.setState(newState);
  }

  applySort() {
    this.setState({
      tasks: this.state.tasks.sort((taskA, taskB) => {
        if (
          taskA.status === TaskStatus.Opened &&
          taskB.status !== TaskStatus.Opened
        ) {
          return -1;
        }
        if (
          taskB.status === TaskStatus.Opened &&
          taskA.status !== TaskStatus.Opened
        ) {
          return 1;
        }
        if (taskA.favorite && !taskB.favorite) {
          return -1;
        }
        if (taskB.favorite && !taskA.favorite) {
          return 1;
        }
        if (isAfter(new Date(taskB.sort_date), new Date(taskA.sort_date))) {
          return -1;
        }
        if (isAfter(new Date(taskA.sort_date), new Date(taskB.sort_date))) {
          return 1;
        }
        if ((taskA.name || '') > (taskB.name || '')) {
          return 1;
        }
        if ((taskA.name || '') < (taskB.name || '')) {
          return -1;
        }
        return 0;
      })
    });
  }

  handleTaskCreated(task: TaskType) {
    const tasks = [task, ...this.state.tasks];
    this.setState({ tasks, daysFilter: this.parseTasksDays(tasks) });
    this.applyFilters();
    this.applySort();
  }

  handleTaskUpdated(task: TaskType) {
    const tasks = [
      ...this.state.tasks.filter(({ id }) => id !== task.id),
      task
    ];
    this.setState({ tasks, daysFilter: this.parseTasksDays(tasks) });
    this.applyFilters();
    this.applySort();
  }

  handleTagDeleted(tagId: string) {
    const selectedTagIds =
      this.locationStore.state.query &&
      this.locationStore.state.query[URL_KEY_SELECTED_TAGS];

    this.locationStore.changeQuery({
      [URL_KEY_SELECTED_TAGS]: selectedTagIds?.filter((id) => id !== tagId)
    });

    this.setState({
      tasks: this.state.tasks.map(({ tags, ...task }) => ({
        ...task,
        tags: tags.filter((tag) => tag.id !== tagId)
      }))
    });
  }

  handleTaskDeleted(taskId: string) {
    const tasks = this.state.tasks.filter((task) => task.id !== taskId);
    this.setState({
      tasks,
      daysFilter: this.parseTasksDays(tasks)
    });
    this.applyFilters();
    this.applySort();
  }

  handleTagUpdated(updatedTag: TagType) {
    this.setState({
      tasks: this.state.tasks.map(({ tags, ...task }) => ({
        ...task,
        tags: tags.map((tag) => (tag.id === updatedTag.id ? updatedTag : tag))
      }))
    });
  }
}
