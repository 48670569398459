import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import {
  LocationStore
} from '@proscom/prostore-react-router';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_LOCATION, STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { TaskType } from '../../types';
import { MUTATION_DELETE_LETTER } from '../../mutations/tasks';
import { URL_KEY_TASK_ID } from '../../../store/urlKeys';

export interface UseDeleteLetterReturn {
  handleDeleteLetter: (taskId: string, letterId: string) => Promise<void>;
  loading: boolean;
}

const DeleteLetterMutationOptions: MutationOptions<
  {
    deleteLetter: TaskType;
  },
  {
    letterId: string;
  }
> = {
  mutation: MUTATION_DELETE_LETTER
};

export const useDeleteLetter = (): UseDeleteLetterReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);

  const deleteLetter = useGraphqlMutation(DeleteLetterMutationOptions);
  const deleteLetterRun = deleteLetter.run;

  const handleDeleteLetter = useCallback(
    (taskId: string, letterId: string) =>
      deleteLetterRun({ variables: { letterId } }).then(({ data }) => {
        if (data) {
          const task = data.deleteLetter;
          if (task) {
            tasksStore.handleTaskUpdated(task);
          } else {
            locationStore.changeQuery({
              [URL_KEY_TASK_ID]: undefined
            });
            tasksStore.handleTaskDeleted(taskId);
          }
        }
      }),
    [deleteLetterRun, tasksStore, locationStore]
  );
  return {
    handleDeleteLetter,
    loading: deleteLetter.loading
  };
};
