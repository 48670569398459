import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType, UpdateLetterDraftInput } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { MUTATION_UPDATE_LETTER_DRAFT } from '../../mutations/letterDrafts';
import { TasksStore } from '../../../store/TasksStore';
import { STORE_TASKS } from '../../../store/storeKeys';

export interface UseUpdateLetterDraftReturn {
  handleUpdateLetterDraft: (input: UpdateLetterDraftInput) => Promise<void>;
  loading: boolean;
}

const UpdateLetterDraftMutationOptions: MutationOptions<
  {
    updateLetterDraft: TaskType;
  },
  {
    input: UpdateLetterDraftInput;
  }
> = {
  mutation: MUTATION_UPDATE_LETTER_DRAFT
};

export const useUpdateLetterDraft = (): UseUpdateLetterDraftReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const updateLetterDraft = useGraphqlMutation(
    UpdateLetterDraftMutationOptions
  );
  const updateLetterDraftRun = updateLetterDraft.run;

  const handleUpdateLetterDraft = useCallback(
    (input: UpdateLetterDraftInput) =>
      updateLetterDraftRun({ variables: { input } }).then(({ data }) => {
        const task = data?.updateLetterDraft;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [updateLetterDraftRun, tasksStore]
  );
  return {
    handleUpdateLetterDraft,
    loading: updateLetterDraft.loading
  };
};
