import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType, DetachTagInput } from '../../types';
import { MUTATION_DETACH_TAG_FROM_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { clearCache } from '../../queriesUtils';

export interface UseDetachTagFromTaskReturn {
  handleDetachTagFromTask: (input: DetachTagInput) => Promise<void>;
  loading: boolean;
}

const DetachTagFromTaskMutationOptions: MutationOptions<
  {
    detachTagFromTask: TaskType;
  },
  {
    input: DetachTagInput;
  }
> = {
  mutation: MUTATION_DETACH_TAG_FROM_TASK,
  refetchQueries: ['queryCurrentUserTags'],
  update: clearCache(['currentUserTags'])
};

export const useDetachTagFromTask = (): UseDetachTagFromTaskReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const detachTagFromTask = useGraphqlMutation(
    DetachTagFromTaskMutationOptions
  );
  const detachTagFromTaskRun = detachTagFromTask.run;
  const handleDetachTagFromTask = useCallback(
    (input: DetachTagInput) =>
      detachTagFromTaskRun({ variables: { input } }).then(({ data }) => {
        const task = data?.detachTagFromTask;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [detachTagFromTaskRun, tasksStore]
  );
  return {
    handleDetachTagFromTask,
    loading: detachTagFromTask.loading
  };
};
