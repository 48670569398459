import { useState } from 'react';

export interface UseToggleType {
  value: boolean;
  set: () => void;
  unset: () => void;
  toggle: () => void;
}

export const useToggle = (defaultVal?: boolean) => {
  const [val, setVal] = useState(defaultVal || false);

  return {
    value: val,
    set: function () {
      setVal(true);
    },
    unset: function () {
      setVal(false);
    },
    toggle: function () {
      setVal(!val);
    }
  };
};
