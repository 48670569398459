import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import s from './Header.module.scss';

export enum HeaderSize {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3'
}

export interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: HeaderSize;
}

export const Header = ({
  size = HeaderSize.h1,
  className,
  children,
  ...headerProps
}: HeaderProps) =>
  size === HeaderSize.h1 ? (
    <h1 className={clsx(s.Header_h1, className)} {...headerProps}>
      {children}
    </h1>
  ) : size === HeaderSize.h2 ? (
    <h2 className={clsx(s.Header_h2, className)} {...headerProps}>
      {children}
    </h2>
  ) : (
    <h3 className={clsx(s.Header_h3, className)} {...headerProps}>
      {children}
    </h3>
  );
