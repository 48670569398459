import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { TransferTaskInput } from '../../types';
import { MUTATION_TRANSFER_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';

export interface UseTransferTaskReturn {
  handleTransferTask: (input: TransferTaskInput) => Promise<void>;
  loading: boolean;
}

const TransferTaskMutationOptions: MutationOptions<
  {
    transferTask: boolean;
  },
  {
    input: TransferTaskInput;
  }
> = {
  mutation: MUTATION_TRANSFER_TASK
};

export const useTransferTask = (): UseTransferTaskReturn => {
  const transferTask = useGraphqlMutation(TransferTaskMutationOptions);
  const transferTaskRun = transferTask.run;
  const handleTransferTask = useCallback(
    async (input: TransferTaskInput) => {
      await transferTaskRun({ variables: { input } });
    },
    [transferTaskRun]
  );
  return {
    handleTransferTask,
    loading: transferTask.loading
  };
};
