import {
  IUseGraphqlWatchQueryOptions,
  useGraphqlWatchQuery
} from '@proscom/prostore-apollo-react';
import { TagType } from '../../types';
import { QUERY_CURRENT_USER_TAGS } from '../../queries/tags';

const queryOptions: IUseGraphqlWatchQueryOptions<null, TagType[]> = {
  query: QUERY_CURRENT_USER_TAGS,
  mapData: (result) => result.currentUserTags
};

export const useCurrentUserTags = () => {
  return useGraphqlWatchQuery({
    queryOptions
  });
};
