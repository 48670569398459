import React, { HTMLAttributes, MutableRefObject } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconClose } from '../../../../assets/icons/IconClosePixel.svg';
import s from './Window.module.scss';

export interface WindowProps extends HTMLAttributes<HTMLDivElement> {
  header?: string;
  onClose?: () => void;
  closeIcon?: any;
  contentClassName?: string;
  windowRef?: MutableRefObject<any>;
  disableDefaultStyles?: boolean;
}

export const Window = ({
  className,
  header,
  onClose,
  closeIcon,
  contentClassName,
  windowRef,
  children,
  disableDefaultStyles,
  ...props
}: WindowProps) => {
  const closeModalContent =
    closeIcon ||
    (onClose && (
      <div className={s.Window__closeIconContainer}>
        <IconClose onClick={onClose} className={s.Window__closeIcon} />
      </div>
    ));

  return (
    <div ref={windowRef} className={clsx(className, s.Window)} {...props}>
      {disableDefaultStyles ? (
        <>{children}</>
      ) : (
        <>
          {header && <div className={s.Window__header}>{header}</div>}
          <div className={s.Window__contentWrapper}>
            <div className={clsx(s.Window__content, contentClassName)}>
              {closeModalContent}
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
