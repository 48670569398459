import { createContext, useCallback, useState } from 'react';

interface OpenLettersContextProps {
  openedLetterIds: string[];
  scrolledLetterId: string | null;
  scrolledLetterDraftId: string | null;
  setLetterOpen: (id: string, value: boolean) => void;
  setScrolledLetterId: (id: string | null) => void;
  setScrolledLetterDraftId: (id: string | null) => void;
}

export const OpenLettersContext = createContext<OpenLettersContextProps | null>(
  null
);

export const useOpenLettersContextValue = (): OpenLettersContextProps => {
  const [openedLetterIds, setOpenedLetterIds] = useState<string[]>([]);
  const [scrolledLetterId, setScrolledLetterId] = useState<string | null>(null);
  const [scrolledLetterDraftId, setScrolledLetterDraftId] = useState<
    string | null
  >(null);

  const setLetterOpen = useCallback(
    (id: string, value: boolean) => {
      if (value) {
        setOpenedLetterIds((letters) =>
          !letters.includes(id) ? [...letters, id] : letters
        );
      } else {
        setOpenedLetterIds((letters) =>
          letters.includes(id)
            ? letters.filter((letterId) => letterId !== id)
            : letters
        );
      }
    },
    [setOpenedLetterIds]
  );

  return {
    openedLetterIds,
    scrolledLetterId,
    scrolledLetterDraftId,
    setLetterOpen,
    setScrolledLetterId,
    setScrolledLetterDraftId
  };
};
