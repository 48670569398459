import gql from 'graphql-tag';

export const QUERY_SEARCH_TAGS = gql`
  query querySearchTags($query: String!, $excludedTasksIds: [Int!]) {
    searchTags(query: $query, excludedTasksIds: $excludedTasksIds) {
      id
      name
    }
  }
`;

export const QUERY_CURRENT_USER_TAGS = gql`
  query queryCurrentUserTags {
    currentUserTags {
      id
      name
      owner_id
    }
  }
`;
