import gql from 'graphql-tag';

export const QUERY_FILE_UPLOAD_OPTIONS = gql`
  query queryFileUploadOptions {
    fileUploadOptions {
      emailAttachmentSizeLimit
      taskFileSizeLimit
    }
  }
`;
