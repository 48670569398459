import gql from 'graphql-tag';
import { EMAIL_LETTER_FULL, FRAGMENT_TASK_FULL } from '../fragments/tasks';
import { FRAGMENT_FILE_FULL } from '../fragments/files';

export const MUTATION_CREATE_TASK = gql`
  mutation mutationCreateTask($input: TaskCreateInput!) {
    createTask(input: $input) {
      ...taskFull
      log {
        emailLetter {
          ...emailLetterFull
        }
      }
    }
  }

  ${FRAGMENT_TASK_FULL}
  ${EMAIL_LETTER_FULL}
`;

export const MUTATION_ATTACH_TAG_TO_TASK = gql`
  mutation mutationAttachTagToTask($input: AttachTagInput!) {
    attachTagToTask(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_DETACH_TAG_FROM_TASK = gql`
  mutation mutationDetachTagFromTask($input: DetachTagInput!) {
    detachTagFromTask(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_UPDATE_TASK = gql`
  mutation mutationUpdateTask($input: TaskUpdateInput!) {
    updateTask(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_CREATE_COMMENT = gql`
  mutation mutationCreateComment($input: CommentCreateInput!) {
    createComment(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_DELETE_COMMENT = gql`
  mutation mutationCreateComment($logStoryId: ID!) {
    deleteComment(logStoryId: $logStoryId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_UPDATE_TASK_SORT_DATE = gql`
  mutation mutationUpdateTaskSortDate($taskId: ID!) {
    updateTaskSortDate(taskId: $taskId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_TRANSFER_TASK = gql`
  mutation mutationTransferTask($input: TransferTaskInput!) {
    transferTask(input: $input)
  }
`;

export const MUTATION_RESPOND_TO_TASK_TRANSFER = gql`
  mutation mutationRespondToTaskTransfer($input: RespondToTaskTransferInput!) {
    respondToTaskTransfer(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_REPLY_ON_LETTER = gql`
  mutation mutationReplyOnLetter($input: ReplyLetterInput!) {
    replyLetter(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_UPDATE_TASK_TRANSFER_PERMISSION = gql`
  mutation mutationUpdateTaskTransferPermission(
    $input: TaskTransferPermissionUpdateInput!
  ) {
    updateTaskTransferPermission(input: $input) {
      id
      transfer_allowed
      transfererUsername
    }
  }
`;

export const MUTATION_DELETE_TASK_TRANSFER_PERMISSION = gql`
  mutation mutationDeleteTaskTransferPermission($permissionId: ID!) {
    deleteTaskTransferPermission(permissionId: $permissionId)
  }
`;

export const MUTATION_DELETE_TASK = gql`
  mutation mutationDeleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId)
  }
`;

export const MUTATION_DELETE_LETTER = gql`
  mutation mutationDeleteLetter($letterId: ID!) {
    deleteLetter(letterId: $letterId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_CREATE_ONLY_OFFICE_DOCUMENT = gql`
  mutation mutationCreateOnlyOfficeDocument($input: DocumentCreateInput!) {
    createOnlyOfficeDocument(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_CREATE_ONLY_OFFICE_ACCESS_TOKEN = gql`
  mutation mutationCreateOnlyOfficeAccessToken($fileId: Int!) {
    createOnlyOfficeAccessToken(fileId: $fileId)
  }
`;

export const MUTATION_CREATE_FILE_TASK_FROM_LETTER_ATTACHMENT = gql`
  mutation mutationCreateFileTaskFromLetterAttachment($attachmentId: Int!) {
    createFileTaskFromLetterAttachment(attachmentId: $attachmentId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_CONVERT_TEXT_TASK_TO_FILE = gql`
  mutation mutationConvertTextTaskToFile($fileId: Int!, $taskId: Int!) {
    convertTextTaskToFile(fileId: $fileId, taskId: $taskId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_COPY_FILE = gql`
  mutation mutationCopyFile($fileId: Int!) {
    copyFile(id: $fileId) {
      ...fileFull
    }
  }

  ${FRAGMENT_FILE_FULL}
`;
