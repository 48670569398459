import clsx from 'clsx';
import React, { KeyboardEvent, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import triggerChange from 'react-trigger-change';
import { TaskUpdateInput } from '../../../../graphql/types';
import s from './TaskCardNameInput.module.scss';

export interface TaskCardNameInputProps {
  initValue: string;
  handleUpdateTask: (updateObj: Partial<TaskUpdateInput>) => void;
  className?: string;
}

export const TaskCardNameInput = ({
  initValue,
  handleUpdateTask,
  className
}: TaskCardNameInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = initValue;
      triggerChange(inputRef.current);
      inputRef.current.classList.remove('focus-visible');
    }
  }, [initValue]);

  const onBlur = () => {
    if (inputRef.current && inputRef.current.value !== initValue) {
      handleUpdateTask({ name: inputRef.current.value });
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const input = e.target as HTMLTextAreaElement;

    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        input.blur();
        break;
      case 'Escape':
        input.value = initValue;
        triggerChange(input); // Чтобы отработало изменение высоты textarea
        input.blur();
        break;
    }
  };

  return (
    <TextareaAutosize
      className={clsx(s.TaskCardNameInput, className)}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      ref={inputRef}
      placeholder="Без названия"
      maxLength={255}
    />
  );
};
