import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TagUpdateInput, TagType } from '../../types';
import { MUTATION_UPDATE_TAG } from '../../mutations/tags';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseUpdateTagReturn {
  handleUpdateTag: (input: TagUpdateInput) => Promise<TagType | undefined>;
  loading: boolean;
}

const UpdateTagMutationOptions: MutationOptions<
  {
    updateTag: TagType;
  },
  {
    input: TagUpdateInput;
  }
> = {
  mutation: MUTATION_UPDATE_TAG
};

export const useUpdateTag = (): UseUpdateTagReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const updateTag = useGraphqlMutation(UpdateTagMutationOptions);
  const updateTagRun = updateTag.run;
  const handleUpdateTag = useCallback(
    (input: TagUpdateInput) =>
      updateTagRun({ variables: { input } }).then(({ data }) => {
        const tag = data?.updateTag;
        if (tag) {
          tasksStore.handleTagUpdated(tag);
        }
        return tag;
      }),
    [updateTagRun, tasksStore]
  );
  return {
    handleUpdateTag,
    loading: updateTag.loading
  };
};
