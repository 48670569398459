import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';

import { ToastContainer } from './common/components/ui/ToastContainer/ToastContainer';
import AppRoutes from './routes';
import { Background } from './common/components/ui/Background/Background';

const siteTitle = 'NeTupi v3.0';

export function App() {
  return (
    <Fragment>
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s ${EMDASH} ${siteTitle}`}
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Background />
      <ToastContainer />
      <AppRoutes />
    </Fragment>
  );
}
