import { useCallback } from 'react';
import { apiUrl } from '../../../config';
import { useCreateDownloadFileToken } from '../../../graphql/hooks/files/useCreateDownloadFileToken';

export interface UseDownloadFileReturn {
  downloadFile: (id: number, fileName: string) => void;
  loading: boolean;
}

export const useDownloadFile = (): UseDownloadFileReturn => {
  const { handleCreateDownloadFileToken, loading } =
    useCreateDownloadFileToken();

  const downloadFile = useCallback(
    async (id: number, fileName: string) => {
      handleCreateDownloadFileToken(id).then((token) => {
        const a = document.createElement('a');
        a.href = `${apiUrl}/files/download/${token}`;
        a.setAttribute('download', fileName);
        a.click();
      });
    },
    [handleCreateDownloadFileToken]
  );

  return { downloadFile, loading };
};
