import React, { ComponentProps, ElementType } from 'react';
import clsx from 'clsx';
import s from './Card.module.scss';

export type CardProps<ComponentType extends ElementType> = {
  component?: ComponentType;
  clickable?: boolean;
} & ComponentProps<ComponentType>;

export const Card = <ComponentType extends ElementType = 'div'>({
  component = 'div',
  clickable,
  className,
  ...componentProps
}: CardProps<ComponentType>) => {
  const Component = component;

  return (
    <Component
      className={clsx(s.Card, clickable && s.Card_clickable, className)}
      {...componentProps}
    />
  );
};
