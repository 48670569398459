import { ValueStore } from '@proscom/prostore';

export class CalendarOpenStore extends ValueStore<boolean> {
  constructor() {
    super(false);
  }

  toggleOpen() {
    this.setState(!this.state);
  }
}
