import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskUpdateInput, TaskType } from '../../types';
import { MUTATION_UPDATE_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseUpdateTaskReturn {
  handleUpdateTask: (input: TaskUpdateInput) => Promise<void>;
  loading: boolean;
}

const UpdateTaskMutationOptions: MutationOptions<
  {
    updateTask: TaskType;
  },
  {
    input: TaskUpdateInput;
  }
> = {
  mutation: MUTATION_UPDATE_TASK
};

export const useUpdateTask = (): UseUpdateTaskReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const updateTask = useGraphqlMutation(UpdateTaskMutationOptions);
  const updateTaskRun = updateTask.run;
  const handleUpdateTask = useCallback(
    (input: TaskUpdateInput) =>
      updateTaskRun({ variables: { input } }).then(({ data }) => {
        const task = data?.updateTask;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [updateTaskRun, tasksStore]
  );
  return {
    handleUpdateTask,
    loading: updateTask.loading
  };
};
