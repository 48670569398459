import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import MaskedInputComponent, {
  MaskedInputProps as MaskedInputComponentProps
} from 'react-maskedinput';
import s from './Input.module.scss';

export interface InputClasses {
  label?: string;
  input?: string;
  error?: string;
}

export interface InputBaseProps {
  label?: string;
  classes?: InputClasses;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  focusAfterRender?: boolean;
}

export interface InputOwnProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  inputRef?: React.RefObject<HTMLInputElement>;
}
export type InputProps = InputBaseProps & InputOwnProps;

export const Input = ({
  label,
  classes,
  inputRef: inputRefProp,
  onChange,
  className,
  error,
  required,
  focusAfterRender,
  ...inputProps
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (focusAfterRender) {
      (inputRefProp || inputRef).current?.focus();
    }
  }, [inputRefProp, inputRef, focusAfterRender]);

  return (
    <div className={clsx(s.Input, className)}>
      {label && (
        <label className={clsx(s.Input__label, classes?.label)}>
          {label}
          <span className={s.Input__requiredSign}>{required && '*'}</span>
        </label>
      )}
      <input
        className={clsx(s.Input__input, classes?.input)}
        ref={inputRefProp || inputRef}
        onChange={(e) => {
          onChange?.(e.target.value, e);
        }}
        required={required}
        {...inputProps}
      />
      {error && <div className={s.Input__error}>{error}</div>}
    </div>
  );
};

export interface MaskedInputOwnProps
  extends Omit<MaskedInputComponentProps, 'onChange' | 'placeholderChar'> {
  inputRef?: React.ForwardedRef<MaskedInputComponent>;
}
export type MaskedInputProps = InputBaseProps & MaskedInputOwnProps;

export const MaskedInput = ({
  label,
  classes,
  inputRef,
  onChange,
  className,
  error,
  required,
  ...inputProps
}: MaskedInputProps) => (
  <div className={clsx(s.Input, className)}>
    {label && (
      <label className={clsx(s.Input__label, classes?.label)}>
        {label}
        <span className={s.Input__requiredSign}>{required && '*'}</span>
      </label>
    )}
    <MaskedInputComponent
      ref={inputRef}
      className={clsx(s.Input__input, classes?.input)}
      onChange={(e) => {
        onChange?.(e.target.value, e);
      }}
      required={required}
      placeholderChar=" "
      {...inputProps}
    />
    {error && <div className={s.Input__error}>{error}</div>}
  </div>
);
