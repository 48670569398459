import gql from 'graphql-tag';
import { EMAIL_LETTER_FULL, FRAGMENT_TASK_FULL } from '../fragments/tasks';

export const QUERY_TASKS = gql`
  query queryTasks {
    currentUserTasks {
      ...taskFull
      log {
        emailLetter {
          ...emailLetterFull
        }
      }
    }
  }

  ${FRAGMENT_TASK_FULL}
  ${EMAIL_LETTER_FULL}
`;
