import {
  IUseGraphqlWatchQueryOptions,
  useGraphqlWatchQuery
} from '@proscom/prostore-apollo-react';
import { QUERY_TASK_TRANSFER_PERMISSIONS } from '../../queries/taskTransferPermissions';
import { TaskTransferPermissionType } from '../../types';

const queryOptions: IUseGraphqlWatchQueryOptions<
  null,
  TaskTransferPermissionType[]
> = {
  query: QUERY_TASK_TRANSFER_PERMISSIONS,
  mapData: (result) => result?.taskTransferPermissions
};

export const useTaskTransferPermissions = () => {
  return useGraphqlWatchQuery({
    queryOptions
  });
};
