import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { MUTATION_ATTACH_FILE_TO_LETTER_DRAFT } from '../../mutations/letterDrafts';
import { TasksStore } from '../../../store/TasksStore';
import { STORE_TASKS } from '../../../store/storeKeys';

export interface UseAttachFileToLetterDraftReturn {
  handleAttachFileToLetterDraft: (
    draftId: number,
    fileId: number
  ) => Promise<void>;
  loading: boolean;
}

const AttachFileToLetterDraftMutationOptions: MutationOptions<
  {
    attachFileToLetterDraft: TaskType;
  },
  {
    draftId: number;
    fileId: number;
  }
> = {
  mutation: MUTATION_ATTACH_FILE_TO_LETTER_DRAFT
};

export const useAttachFileToLetterDraft =
  (): UseAttachFileToLetterDraftReturn => {
    const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

    const attachFileToLetterDraft = useGraphqlMutation(
      AttachFileToLetterDraftMutationOptions,
      undefined,
      {
        singleton: false
      }
    );
    const attachFileToLetterDraftRun = attachFileToLetterDraft.run;

    const handleAttachFileToLetterDraft = useCallback(
      (draftId: number, fileId: number) =>
        attachFileToLetterDraftRun({ variables: { draftId, fileId } }).then(
          ({ data }) => {
            const task = data?.attachFileToLetterDraft;
            if (task) {
              tasksStore.handleTaskUpdated(task);
            }
          }
        ),
      [attachFileToLetterDraftRun, tasksStore]
    );
    return {
      handleAttachFileToLetterDraft,
      loading: attachFileToLetterDraft.loading
    };
  };
