import { ValueStore } from '@proscom/prostore';

export class SettingsStore extends ValueStore<string> {
  constructor() {
    super('Передача задач');
  }

  setSettingTitle(settingTitle: string) {
    this.setState(settingTitle);
  }
}
