import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { CommentCreateInput, TaskType } from '../../types';
import { MUTATION_CREATE_COMMENT } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseCreateCommentReturn {
  handleCreateComment: (input: CommentCreateInput) => Promise<void>;
  loading: boolean;
}

const CreateCommentMutationOptions: MutationOptions<
  {
    createComment: TaskType;
  },
  {
    input: CommentCreateInput;
  }
> = {
  mutation: MUTATION_CREATE_COMMENT
};

export const useCreateComment = (): UseCreateCommentReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const createComment = useGraphqlMutation(CreateCommentMutationOptions, '', {
    singleton: false
  });
  const createCommentRun = createComment.run;
  const handleCreateComment = useCallback(
    (input: CommentCreateInput) =>
      createCommentRun({ variables: { input } }).then(({ data }) => {
        const updatedTask = data?.createComment;
        if (updatedTask) {
          tasksStore.handleTaskUpdated(updatedTask);
        }
      }),
    [createCommentRun, tasksStore]
  );
  return {
    handleCreateComment,
    loading: createComment.loading
  };
};
