import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { useStore } from '@proscom/prostore-react';
import clsx from 'clsx';
import { AuthStore } from '../../store/AuthStore';
import { STORE_AUTH, STORE_CALENDAR_OPEN } from '../../store/storeKeys';
import { URL_KEY_TARGET } from '../../store/urlKeys';
import { useUrlKey } from '../../common/hooks/useUrlKey';
import { Sidebar } from '../../common/components/ui/Sidebar/Sidebar';
import { Calendar } from '../index/Calendar/Calendar';
import { CalendarOpenStore } from '../../store/CalendarOpenStore';
import s from './UserLayout.module.scss';

export function withUserLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    const [, authStore] = useStore<AuthStore>(STORE_AUTH);
    const location = useLocation();
    const [target, setTarget] = useUrlKey<string | undefined>(URL_KEY_TARGET);

    if (target) {
      setTarget(undefined);
    }

    const [calendarOpen, calendarStore] =
      useStore<CalendarOpenStore>(STORE_CALENDAR_OPEN);

    return authStore.isLoggedIn() ? (
      <div className={s.UserLayout}>
        <div
          className={clsx(
            s.UserLayout__sideBar,
            calendarOpen && s.UserLayout__sideBar_active
          )}
        >
          <Sidebar>
            <Calendar />
          </Sidebar>
        </div>
        <div className={s.UserLayout__pageContent}>
          <Comp {...props} />
        </div>
        {calendarOpen && (
          <div
            onClick={() => calendarStore.toggleOpen()}
            className={s.UserLayout__sideBar__overlay}
          ></div>
        )}
      </div>
    ) : (
      <Redirect to={`/login?target=${location.pathname}${location.search}`} />
    );
  };
}
