import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { FileType } from '../../types';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { MUTATION_COPY_FILE } from '../../mutations/tasks';

export interface UseCopyFileReturn {
  handleCopyFile: (fileId: number) => Promise<FileType | undefined>;
  loading: boolean;
}

const CopyFileMutationOptions: MutationOptions<
  {
    copyFile: FileType;
  },
  {
    fileId: number;
  }
> = {
  mutation: MUTATION_COPY_FILE
};

export const useCopyFile = (): UseCopyFileReturn => {
  const copyFile = useGraphqlMutation(CopyFileMutationOptions, undefined, {
    singleton: false
  });
  const copyFileRun = copyFile.run;

  const handleCopyFile = useCallback(
    (fileId: number) =>
      copyFileRun({ variables: { fileId } }).then(({ data }) => {
        return data?.copyFile;
      }),
    [copyFileRun]
  );
  return {
    handleCopyFile,
    loading: copyFile.loading
  };
};
