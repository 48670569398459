import React, { forwardRef, InputHTMLAttributes } from 'react';

export interface AutosizeInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
}

export const AutosizeInput = forwardRef<HTMLInputElement, AutosizeInputProps>(
  ({ value, style, ...props }, ref) => (
    <input
      ref={ref}
      value={value}
      style={{
        ...style,
        width: `calc(${value.length}ch + ${style?.paddingRight || 0}px + ${
          style?.paddingLeft || 0
        }px + 3px)`
      }}
      {...props}
    />
  )
);
