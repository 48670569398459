export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type AttachTagInput = {
  tagId: Scalars['Int'];
  taskId: Scalars['Int'];
};

export type AuthResponseType = {
  __typename?: 'AuthResponseType';
  accessToken: Scalars['String'];
  refreshToken: UserAuthTokenType;
  user?: Maybe<UserType>;
};

export type CommentCreateInput = {
  comment: Scalars['String'];
  taskId: Scalars['ID'];
};

export type CreateReplyDraftInput = {
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  html?: Maybe<Scalars['String']>;
  is_forwarded?: Maybe<Scalars['Boolean']>;
  taskLogStoryId: Scalars['Int'];
  to?: Maybe<Array<Scalars['String']>>;
};

export type DetachTagInput = {
  tagId: Scalars['Int'];
  taskId: Scalars['Int'];
};

export type DocumentCreateInput = {
  documentType: DocumentType;
};

/** Типы документов OnlyOffice */
export enum DocumentType {
  Document = 'DOCUMENT',
  Presentation = 'PRESENTATION',
  Sheet = 'SHEET'
}

export type EmailAccountCreateInput = {
  email: Scalars['String'];
  /** У Яндекса imap.yandex.ru, у Google imap.google.com */
  imap_host: Scalars['String'];
  imap_login: Scalars['String'];
  imap_password: Scalars['String'];
  /** У Яндекса и Google одинаковый дефолтный порт 993 */
  imap_port?: Maybe<Scalars['Int']>;
  imap_security_protocol?: Maybe<SecurityProtocol>;
  name: Scalars['String'];
  /** У Яндекса smtp.yandex.ru, у Google smtp.google.com */
  smtp_host: Scalars['String'];
  smtp_login: Scalars['String'];
  smtp_password: Scalars['String'];
  /** У Яндекса и Google одинаковый дефолтный порт 465 */
  smtp_port?: Maybe<Scalars['Int']>;
  smtp_security_protocol?: Maybe<SecurityProtocol>;
};

export type EmailAccountType = {
  __typename?: 'EmailAccountType';
  email: Scalars['String'];
  id: Scalars['ID'];
  imap_error?: Maybe<Scalars['String']>;
  imap_error_date?: Maybe<Scalars['DateTime']>;
  /** У Яндекса imap.yandex.ru, у Google imap.google.com */
  imap_host: Scalars['String'];
  imap_login: Scalars['String'];
  /** У Яндекса и Google одинаковый дефолтный порт 993 */
  imap_port: Scalars['Int'];
  imap_security_protocol: SecurityProtocol;
  last_successful_mail_update_date: Scalars['DateTime'];
  name: Scalars['String'];
  service: EmailServiceName;
  /** У Яндекса smtp.yandex.ru, у Google smtp.google.com */
  smtp_host: Scalars['String'];
  smtp_login: Scalars['String'];
  /** У Яндекса и Google одинаковый дефолтный порт 465 */
  smtp_port: Scalars['Int'];
  smtp_security_protocol: SecurityProtocol;
};

export type EmailAccountUpdateInput = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** У Яндекса imap.yandex.ru, у Google imap.google.com */
  imap_host?: Maybe<Scalars['String']>;
  imap_login?: Maybe<Scalars['String']>;
  imap_password?: Maybe<Scalars['String']>;
  /** У Яндекса и Google одинаковый дефолтный порт 993 */
  imap_port?: Maybe<Scalars['Int']>;
  imap_security_protocol?: Maybe<SecurityProtocol>;
  name?: Maybe<Scalars['String']>;
  /** У Яндекса smtp.yandex.ru, у Google smtp.google.com */
  smtp_host?: Maybe<Scalars['String']>;
  smtp_login?: Maybe<Scalars['String']>;
  smtp_password?: Maybe<Scalars['String']>;
  /** У Яндекса и Google одинаковый дефолтный порт 465 */
  smtp_port?: Maybe<Scalars['Int']>;
  smtp_security_protocol?: Maybe<SecurityProtocol>;
};

export type EmailCreateInput = {
  attachmentIds?: Maybe<Array<Scalars['Int']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id: Scalars['ID'];
  html: Scalars['String'];
  subject: Scalars['String'];
  to: Array<Scalars['String']>;
};

export type EmailDraftCreateInput = {
  attachmentIds?: Maybe<Array<Scalars['Int']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id?: Maybe<Scalars['ID']>;
  html?: Maybe<Scalars['String']>;
  is_forwarded?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
};

export type EmailLetterCommonFields = {
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id: Scalars['ID'];
  html: Scalars['String'];
  subject: Scalars['String'];
  to: Array<Scalars['String']>;
};

export type EmailLetterDraftType = {
  __typename?: 'EmailLetterDraftType';
  attachments: Array<FileType>;
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id?: Maybe<Scalars['ID']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_forwarded?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
};

/** Текущий тег для письма (входящее / исходящее) */
export enum EmailLetterTag {
  Inbox = 'INBOX',
  Sent = 'SENT'
}

export type EmailLetterType = {
  __typename?: 'EmailLetterType';
  attachments: Array<FileType>;
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id?: Maybe<Scalars['ID']>;
  email_letter_tag: EmailLetterTag;
  from: Scalars['String'];
  html: Scalars['String'];
  htmlFile?: Maybe<FileType>;
  id: Scalars['ID'];
  inline_content: Scalars['String'];
  plain_text: Scalars['String'];
  subject: Scalars['String'];
  to: Array<Scalars['String']>;
};

/** Название почтового сервиса */
export enum EmailServiceName {
  Custom = 'CUSTOM',
  Google = 'GOOGLE',
  Protonmail = 'PROTONMAIL',
  Yandex = 'YANDEX'
}

/** Текущий тег для задачи письма (входящее / исходящее / черновик) */
export enum EmailTaskTag {
  Draft = 'DRAFT',
  Inbox = 'INBOX',
  Sent = 'SENT'
}

export type FileType = {
  __typename?: 'FileType';
  canBeOpenedInOnlyOffice?: Maybe<Scalars['Boolean']>;
  expired_at?: Maybe<Scalars['DateTime']>;
  file_name: Scalars['String'];
  id: Scalars['ID'];
  last_modified: Scalars['DateTime'];
  size: Scalars['Int'];
  source_file_id?: Maybe<Scalars['ID']>;
  variant?: Maybe<FileVariant>;
};

export type FileUploadOptionsType = {
  __typename?: 'FileUploadOptionsType';
  emailAttachmentSizeLimit: Scalars['Int'];
  taskFileSizeLimit: Scalars['Int'];
};

export enum FileVariant {
  EmailAttachment = 'EMAIL_ATTACHMENT',
  EmailDraftAttachment = 'EMAIL_DRAFT_ATTACHMENT',
  EmailHtml = 'EMAIL_HTML',
  FileTask = 'FILE_TASK'
}

export type Mutation = {
  __typename?: 'Mutation';
  attachFileToLetterDraft: TaskType;
  attachTagToTask: TaskType;
  convertTextTaskToFile: TaskType;
  copyFile: FileType;
  createComment: TaskType;
  createCustomEmailAccount: EmailAccountType;
  createDownloadFileToken: Scalars['String'];
  createFileTaskFromLetterAttachment: TaskType;
  createOAuthOneTimeToken: Scalars['String'];
  createOnlyOfficeAccessToken: Scalars['String'];
  createOnlyOfficeDocument: TaskType;
  createReplyDraft: TaskType;
  createTag: TagType;
  createTask: TaskType;
  deleteComment: TaskType;
  deleteEmailAccount: Scalars['Boolean'];
  deleteLetter?: Maybe<TaskType>;
  deleteReplyDraft: TaskType;
  deleteTag: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  deleteTaskTransferPermission: Scalars['Boolean'];
  detachFileFromLetterDraft: TaskType;
  detachTagFromTask: TaskType;
  /** Аутентификация пользователя. Может возвращать ошибку авторизации, если при мутации были запрошены поля, недоступные без токена */
  login: AuthResponseType;
  loginWithId: AuthResponseType;
  logout: Scalars['Boolean'];
  recoverPassword: Scalars['Boolean'];
  removeFiles: Scalars['Boolean'];
  replyLetter: TaskType;
  respondToTaskTransfer: TaskType;
  submitNewLetterDraft: TaskType;
  transferTask: Scalars['Boolean'];
  updateEmailAccount: EmailAccountType;
  updateFilesExpirationDates: Array<FileType>;
  updateLetterDraft: TaskType;
  updateTag: TagType;
  updateTask: TaskType;
  updateTaskSortDate: TaskType;
  updateTaskTransferPermission: TaskTransferPermissionType;
  useRefreshToken: AuthResponseType;
};

export type MutationAttachFileToLetterDraftArgs = {
  draftId: Scalars['Int'];
  fileId: Scalars['Int'];
};

export type MutationAttachTagToTaskArgs = {
  input: AttachTagInput;
};

export type MutationConvertTextTaskToFileArgs = {
  fileId: Scalars['Int'];
  taskId: Scalars['Int'];
};

export type MutationCopyFileArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommentArgs = {
  input: CommentCreateInput;
};

export type MutationCreateCustomEmailAccountArgs = {
  input: EmailAccountCreateInput;
};

export type MutationCreateDownloadFileTokenArgs = {
  fileId: Scalars['Int'];
};

export type MutationCreateFileTaskFromLetterAttachmentArgs = {
  attachmentId: Scalars['Int'];
};

export type MutationCreateOnlyOfficeAccessTokenArgs = {
  fileId: Scalars['Int'];
};

export type MutationCreateOnlyOfficeDocumentArgs = {
  input: DocumentCreateInput;
};

export type MutationCreateReplyDraftArgs = {
  input: CreateReplyDraftInput;
};

export type MutationCreateTagArgs = {
  input: TagCreateInput;
};

export type MutationCreateTaskArgs = {
  input: TaskCreateInput;
};

export type MutationDeleteCommentArgs = {
  logStoryId: Scalars['ID'];
};

export type MutationDeleteEmailAccountArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteLetterArgs = {
  letterId: Scalars['ID'];
};

export type MutationDeleteReplyDraftArgs = {
  draftId: Scalars['Int'];
};

export type MutationDeleteTagArgs = {
  tagId: Scalars['ID'];
};

export type MutationDeleteTaskArgs = {
  taskId: Scalars['ID'];
};

export type MutationDeleteTaskTransferPermissionArgs = {
  permissionId: Scalars['ID'];
};

export type MutationDetachFileFromLetterDraftArgs = {
  draftId: Scalars['Int'];
  fileId: Scalars['Int'];
};

export type MutationDetachTagFromTaskArgs = {
  input: DetachTagInput;
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationLoginWithIdArgs = {
  id: Scalars['Int'];
};

export type MutationLogoutArgs = {
  token: Scalars['String'];
};

export type MutationRecoverPasswordArgs = {
  input: RecoverPasswordInput;
};

export type MutationRemoveFilesArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationReplyLetterArgs = {
  input: ReplyLetterInput;
};

export type MutationRespondToTaskTransferArgs = {
  input: RespondToTaskTransferInput;
};

export type MutationSubmitNewLetterDraftArgs = {
  input: SubmitNewLetterDraftInput;
};

export type MutationTransferTaskArgs = {
  input: TransferTaskInput;
};

export type MutationUpdateEmailAccountArgs = {
  input: EmailAccountUpdateInput;
};

export type MutationUpdateFilesExpirationDatesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationUpdateLetterDraftArgs = {
  input: UpdateLetterDraftInput;
};

export type MutationUpdateTagArgs = {
  input: TagUpdateInput;
};

export type MutationUpdateTaskArgs = {
  input: TaskUpdateInput;
};

export type MutationUpdateTaskSortDateArgs = {
  taskId: Scalars['ID'];
};

export type MutationUpdateTaskTransferPermissionArgs = {
  input: TaskTransferPermissionUpdateInput;
};

export type MutationUseRefreshTokenArgs = {
  token: Scalars['String'];
};

export type PaginationHeaderType = {
  __typename?: 'PaginationHeaderType';
  hasNext?: Maybe<Scalars['Boolean']>;
  onePage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** Данные пагинации */
export type PaginationInput = {
  onePage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  currentUserEmailAccounts: Array<EmailAccountType>;
  currentUserTags: Array<TagType>;
  currentUserTasks: Array<TaskType>;
  emailAccount?: Maybe<EmailAccountType>;
  fileUploadOptions: FileUploadOptionsType;
  searchTags: Array<TagType>;
  tag: TagType;
  task: TaskType;
  taskTransferPermissions: Array<TaskTransferPermissionType>;
  user?: Maybe<UserType>;
  usersPage?: Maybe<UserPageType>;
};

export type QueryEmailAccountArgs = {
  id: Scalars['ID'];
};

export type QuerySearchTagsArgs = {
  excludedTasksIds?: Maybe<Array<Scalars['Int']>>;
  query: Scalars['String'];
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryTaskArgs = {
  id: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersPageArgs = {
  input?: Maybe<UserPageInput>;
};

export type RecoverPasswordInput = {
  comment?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  position: Scalars['String'];
};

export type ReplyLetterInput = {
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  html: Scalars['String'];
  letterId: Scalars['ID'];
  to: Array<Scalars['String']>;
};

export type RespondToTaskTransferInput = {
  acceptTransfer: Scalars['Boolean'];
  rememberChoiceForCurrentTransferer?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['ID'];
};

/** Протокол шифрования */
export enum SecurityProtocol {
  Ssl = 'SSL',
  Tls = 'TLS'
}

export type SubmitNewLetterDraftInput = {
  emailFields: EmailLetterCommonFields;
  taskId: Scalars['ID'];
};

export type TagCreateInput = {
  name: Scalars['String'];
};

export type TagType = {
  __typename?: 'TagType';
  id: Scalars['ID'];
  name: Scalars['String'];
  owner_id: Scalars['ID'];
};

export type TagUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TaskCreateInput = {
  due_date?: Maybe<Scalars['DateTime']>;
  emailDraftFields?: Maybe<EmailDraftCreateInput>;
  emailFields?: Maybe<EmailCreateInput>;
  favorite: Scalars['Boolean'];
  fileId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status: TaskStatus;
  task_type?: Maybe<TaskTypeEnum>;
};

export type TaskLogStoryType = {
  __typename?: 'TaskLogStoryType';
  actor_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  emailLetter?: Maybe<EmailLetterType>;
  emailLetterDraft?: Maybe<EmailLetterDraftType>;
  email_draft_id?: Maybe<Scalars['ID']>;
  email_letter_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  search_indexing_value?: Maybe<Scalars['String']>;
  task_id: Scalars['ID'];
  updated_field?: Maybe<Scalars['String']>;
  updated_value?: Maybe<Scalars['String']>;
  variant: TaskLogStoryVariant;
};

export enum TaskLogStoryVariant {
  Comment = 'COMMENT',
  ConvertedToFile = 'CONVERTED_TO_FILE',
  Created = 'CREATED',
  Email = 'EMAIL',
  EmailAccountDeleted = 'EMAIL_ACCOUNT_DELETED',
  EmailDraft = 'EMAIL_DRAFT',
  EmailLetterDeleted = 'EMAIL_LETTER_DELETED',
  TagAttached = 'TAG_ATTACHED',
  TagDetached = 'TAG_DETACHED',
  Transfer = 'TRANSFER',
  TransferAccepted = 'TRANSFER_ACCEPTED',
  TransferRefused = 'TRANSFER_REFUSED',
  Updated = 'UPDATED'
}

/** Статусы задач */
export enum TaskStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type TaskTransferPermissionType = {
  __typename?: 'TaskTransferPermissionType';
  id: Scalars['ID'];
  transfer_allowed: Scalars['Boolean'];
  transfererUsername: Scalars['String'];
};

export type TaskTransferPermissionUpdateInput = {
  id: Scalars['ID'];
  transfer_allowed: Scalars['Boolean'];
};

export type TaskType = {
  __typename?: 'TaskType';
  due_date: Scalars['DateTime'];
  email_account_deleted?: Maybe<Scalars['Boolean']>;
  email_task_tag?: Maybe<EmailTaskTag>;
  favorite: Scalars['Boolean'];
  file?: Maybe<FileType>;
  file_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  log: Array<TaskLogStoryType>;
  name?: Maybe<Scalars['String']>;
  sort_date: Scalars['DateTime'];
  status: TaskStatus;
  tags: Array<TagType>;
  task_type: TaskTypeEnum;
  transfer_pending: Scalars['Boolean'];
};

/** Типы задач */
export enum TaskTypeEnum {
  Email = 'EMAIL',
  File = 'FILE',
  Text = 'TEXT'
}

export type TaskUpdateInput = {
  due_date?: Maybe<Scalars['DateTime']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<TaskStatus>;
};

export enum TokenType {
  Refresh = 'REFRESH'
}

export type TransferTaskInput = {
  taskId: Scalars['ID'];
  transfereeUsername: Scalars['String'];
};

export type UpdateLetterDraftInput = {
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  email_account_id?: Maybe<Scalars['ID']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_forwarded?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
};

export type UserAuthTokenType = {
  __typename?: 'UserAuthTokenType';
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  token: Scalars['String'];
  type: TokenType;
};

export type UserFilter = {
  creator_user_ids?: Maybe<Array<Scalars['ID']>>;
  exclude_ids?: Maybe<Array<Scalars['ID']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
};

export type UserPageInput = {
  filter?: Maybe<UserFilter>;
  pagination?: Maybe<PaginationInput>;
};

export type UserPageType = {
  __typename?: 'UserPageType';
  header: PaginationHeaderType;
  list: Array<UserType>;
};

/** Роль пользователя */
export enum UserRolesEnum {
  Admin = 'ADMIN',
  Default = 'DEFAULT'
}

export type UserType = {
  __typename?: 'UserType';
  emailAccounts: Array<EmailAccountType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRolesEnum>;
  username?: Maybe<Scalars['String']>;
};
