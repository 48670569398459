import gql from 'graphql-tag';
import { FRAGMENT_TASK_FULL } from '../fragments/tasks';
import { FRAGMENT_FILE_FULL } from '../fragments/files';

export const MUTATION_SUBMIT_NEW_LETTER_DRAFT = gql`
  mutation mutationSubmitNewLetterDraft($input: SubmitNewLetterDraftInput!) {
    submitNewLetterDraft(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_ATTACH_FILE_TO_LETTER_DRAFT = gql`
  mutation mutationAttachFileToLetterDraft($draftId: Int!, $fileId: Int!) {
    attachFileToLetterDraft(draftId: $draftId, fileId: $fileId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_DETACH_FILE_FROM_LETTER_DRAFT = gql`
  mutation mutationDetachFileFromLetterDraft($draftId: Int!, $fileId: Int!) {
    detachFileFromLetterDraft(draftId: $draftId, fileId: $fileId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_UPDATE_LETTER_DRAFT = gql`
  mutation mutationUpdateLetterDraft($input: UpdateLetterDraftInput!) {
    updateLetterDraft(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_CREATE_REPLY_DRAFT = gql`
  mutation mutationCreateReplyDraft($input: CreateReplyDraftInput!) {
    createReplyDraft(input: $input) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_DELETE_REPLY_DRAFT = gql`
  mutation mutationDeleteReplyDraft($draftId: Int!) {
    deleteReplyDraft(draftId: $draftId) {
      ...taskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`;

export const MUTATION_UPDATE_FILES_EXPIRATION_DATES = gql`
  mutation mutationUpdateFilesExpirationDates($ids: [ID!]!) {
    updateFilesExpirationDates(ids: $ids) {
      ...fileFull
    }
  }

  ${FRAGMENT_FILE_FULL}
`;
