import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { DraftEditorCommand, Editor, EditorState, RichUtils } from 'draft-js';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import { FileCard } from '../FileCard/FileCard';

import { ReactComponent as IconBoldText } from '../../../../assets/icons/IconBoldText.svg';
import { ReactComponent as IconItalicText } from '../../../../assets/icons/IconItalicText.svg';
import { ReactComponent as IconUnderlineText } from '../../../../assets/icons/IconUnderlineText.svg';
import { ReactComponent as IconUnorderedList } from '../../../../assets/icons/IconUnorderedList.svg';
import { ReactComponent as IconOrderedList } from '../../../../assets/icons/IconOrderedList.svg';
import { ReactComponent as IconAttachment } from '../../../../assets/icons/IconAttachment.svg';
import { ReactComponent as IconDelete } from '../../../../assets/icons/IconDelete.svg';

import 'draft-js/dist/Draft.css';
import { Button, ButtonVariant } from '../Button/Button';
import { UploadFilesItem } from '../../../../axios/hooks/files/useUploadFiles';
import { useOpenInOnlyOffice } from '../../../hooks/tasks/useOpenInOnlyOffice';
import s from './TextEditor.module.scss';

interface TextEditorProps {
  className?: string;
  value: EditorState;
  onChange: (state: EditorState) => void;
  submitButton?: React.ReactNode;
  attachments?: UploadFilesItem[];
  onFilesUpload?: (files: FileList) => void;
  onFileRemove?: (localId: string) => void;
  onFileReload?: (localId: string) => void;
  onLetterDelete?: () => void;
}

const TextEditor = (
  {
    className,
    value,
    onChange,
    submitButton,
    attachments,
    onFilesUpload,
    onFileRemove,
    onFileReload,
    onLetterDelete
  }: TextEditorProps,
  ref: React.ForwardedRef<Editor>
) => {
  const editor = useRef<Editor | null>(null);

  useImperativeHandle<Editor | null, Editor | null>(ref, () => {
    return editor.current;
  });

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(value, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(value, inlineStyle));
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onFilesUpload && e.target.files) {
      onFilesUpload(e.target.files);
    }

    e.target.value = '';
  };

  const fileInputId = useMemo(() => uniqueId('fileInput'), []);

  const { openInOnlyOffice } = useOpenInOnlyOffice();

  return (
    <div className={clsx(s.TextEditor, className)}>
      <div
        className={s.TextEditor__content}
        onClick={() => editor.current?.focus()}
      >
        <Editor
          editorState={value}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          ref={editor}
          spellCheck={true}
        />
      </div>
      {attachments && (
        <div className={s.TextEditor__fileAttachments}>
          {attachments.map((file, i) => (
            <FileCard
              key={i}
              {...file}
              onClickName={
                file.canBeOpenedInOnlyOffice && file.id
                  ? () => openInOnlyOffice(parseInt(file.id!))
                  : undefined
              }
              onRemove={onFileRemove && (() => onFileRemove(file.localId!))}
              onReload={onFileReload && (() => onFileReload(file.localId!))}
            />
          ))}
        </div>
      )}
      <div className={s.TextEditor__footer}>
        <div className={s.TextEditor__controls}>
          <InlineStyleControls
            editorState={value}
            onToggle={toggleInlineStyle}
          />
          <BlockStyleControls editorState={value} onToggle={toggleBlockType} />
        </div>
        <label className={s.TextEditor__attachmentButton} htmlFor={fileInputId}>
          <input
            id={fileInputId}
            type="file"
            className={s.TextEdit__fileInput}
            onChange={onFileInputChange}
            multiple={true}
          />
          <IconAttachment />
        </label>

        <div className={s.TextEditor__buttons}>
          {onLetterDelete && (
            <Button
              className={s.TextEditor__deleteButton}
              variant={ButtonVariant.TERTIARY}
              leftIcon={IconDelete}
              onClick={onLetterDelete}
            >
              Удалить
            </Button>
          )}
          {submitButton}
        </div>
      </div>
    </div>
  );
};

export const TextEditorWithRef = forwardRef(TextEditor);
export { TextEditorWithRef as TextEditor };

const BLOCK_TYPES = [
  { icon: <IconUnorderedList />, style: 'unordered-list-item' },
  { icon: <IconOrderedList />, style: 'ordered-list-item' }
];
const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className={s.TextEditor__blockControls}>
      {BLOCK_TYPES.map((type, i) => (
        <span
          className={clsx(
            s.TextEditor__control,
            type.style === blockType && s.TextEditor__control_active
          )}
          onMouseDown={(e) => {
            e.preventDefault();
            onToggle(type.style);
          }}
          key={i}
        >
          {type.icon}
        </span>
      ))}
    </div>
  );
};
const INLINE_STYLES = [
  { icon: <IconBoldText />, style: 'BOLD' },
  { icon: <IconItalicText />, style: 'ITALIC' },
  { icon: <IconUnderlineText />, style: 'UNDERLINE' }
];
const InlineStyleControls = ({ onToggle, editorState }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className={s.TextEditor__inlineControls}>
      {INLINE_STYLES.map((type, i) => (
        <span
          className={clsx(
            s.TextEditor__control,
            currentStyle.has(type.style) && s.TextEditor__control_active
          )}
          onMouseDown={(e) => {
            e.preventDefault();
            onToggle(type.style);
          }}
          key={i}
        >
          {type.icon}
        </span>
      ))}
    </div>
  );
};
