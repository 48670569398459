import React, {
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef
} from 'react';
import clsx from 'clsx';
import Tippy from '@tippyjs/react';
import { ReactComponent as IconDelete } from '../../../../assets/icons/IconDelete.svg';
import { ReactComponent as IconError } from '../../../../assets/icons/IconError.svg';
import { ReactComponent as IconReset } from '../../../../assets/icons/IconReset.svg';
import { ReactComponent as IconChevron } from '../../../../assets/icons/IconChevron.svg';

import { ReactComponent as IconFileArchive } from '../../../../assets/icons/files/IconFileArchive.svg';
import { ReactComponent as IconFileExcel } from '../../../../assets/icons/files/IconFileExcel.svg';
import { ReactComponent as IconFileLibrePresentation } from '../../../../assets/icons/files/IconFileLibrePresentation.svg';
import { ReactComponent as IconFileLibreTable } from '../../../../assets/icons/files/IconFileLibreTable.svg';
import { ReactComponent as IconFileLibreText } from '../../../../assets/icons/files/IconFileLibreText.svg';
import { ReactComponent as IconFileMovie } from '../../../../assets/icons/files/IconFileMovie.svg';
import { ReactComponent as IconFilePDF } from '../../../../assets/icons/files/IconFilePDF.svg';
import { ReactComponent as IconFilePhoto } from '../../../../assets/icons/files/IconFilePhoto.svg';
import { ReactComponent as IconFilePPT } from '../../../../assets/icons/files/IconFilePPT.svg';
import { ReactComponent as IconFileTXT } from '../../../../assets/icons/files/IconFileTXT.svg';
import { ReactComponent as IconFileUnknown } from '../../../../assets/icons/files/IconFileUnknown.svg';
import { ReactComponent as IconFileWord } from '../../../../assets/icons/files/IconFileWord.svg';

import { useToggle } from '../../../hooks/useToggle';
import {
  DropdownItems,
  DropdownItemsList
} from '../DropdownItems/DropdownItems';
import { getTime } from '../../../../utils/date';
import { EmailLetterTag, EmailTaskTag } from '../../../../graphql/types';
import { emailTagText } from '../EmailTag/EmailTag';
import { Loader, loaderColors } from '../Loader/Loader';
import { CircleProgress } from '../CircleProgress/CircleProgress';
import s from './FileCard.module.scss';

export type FileCardProps = {
  className?: string;
  name: string;
  size: number;
  lastTimeModified?: string;
  emailLetterTag?: EmailLetterTag | EmailTaskTag;
  emailLetterId?: string;
  onClickName?: () => void;
  onRemove?: () => void;
  onReload?: () => void;
  dropdownItems?: DropdownItemsList;
  isEditingName?: boolean;
  handleUpdateFileName?: (name: string) => void;
  error?: boolean;
  loading?: boolean;
  progress?: number;
  dark?: boolean;
};

const iconExtensions: [FunctionComponent, string[]][] = [
  [IconFileArchive, ['zip', 'rar', '7z', 'z']],
  [IconFileExcel, ['xls', 'xlsx']],
  [IconFileLibrePresentation, ['odp']],
  [IconFileLibreTable, ['ods']],
  [IconFileLibreText, ['odt']],
  [
    IconFileMovie,
    ['avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'wmv']
  ],
  [IconFilePDF, ['pdf']],
  [IconFilePhoto, ['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'svg']],
  [IconFilePPT, ['ppt', 'pptx']],
  [IconFileTXT, ['txt']],
  [IconFileWord, ['doc', 'docx']]
];

const extensionIcons = iconExtensions.reduce((accum, item) => {
  item[1].forEach((ext) => {
    accum[ext] = item[0];
  });

  return accum;
}, {});

const specificIconClassNames = new Map([
  [IconFileUnknown, s.FileCard__icon_unknown],
  [IconFileTXT, s.FileCard__icon_txt]
]);

export const FileCard = ({
  className,
  name,
  size,
  lastTimeModified,
  emailLetterTag,
  loading,
  error,
  onClickName,
  onRemove,
  onReload,
  dropdownItems,
  isEditingName,
  handleUpdateFileName,
  progress,
  dark
}: FileCardProps) => {
  const formattedSize = useMemo(() => {
    let formattedSize = '';
    if (size <= 1024) {
      formattedSize = `${size} B`;
    } else if (size <= 1024 * 1024) {
      formattedSize = `${(size / 1024).toFixed(1)} KB`;
    } else {
      formattedSize = `${(size / 1024 / 1024).toFixed(1)} MB`;
    }

    return formattedSize;
  }, [size]);

  const fileType = useMemo(
    () => name.split('.').pop()?.toLowerCase() || 'file',
    [name]
  );

  const FileIcon = useMemo(
    () => extensionIcons[fileType] || IconFileUnknown,
    [fileType]
  );

  const dropdownToggle = useToggle(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingName) {
      inputRef.current?.focus();
    }
  }, [isEditingName]);

  const onBlur = () => {
    if (
      inputRef.current &&
      inputRef.current.value !== name &&
      handleUpdateFileName
    ) {
      handleUpdateFileName(inputRef.current.value);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLTextAreaElement;

    if (e.key === 'Enter') {
      e.preventDefault();
      input.blur();
    }
  };

  return (
    <div
      className={clsx(
        s.FileCard,
        error && s.FileCard_error,
        className,
        dark && s.FileCard_dark
      )}
    >
      <div className={s.FileCard__image}>
        {loading ? (
          <Loader size={20} singleColor={loaderColors.buttonSecondary} />
        ) : error ? (
          <IconError className={clsx(s.FileCard__errorIcon)} />
        ) : (
          <FileIcon
            className={clsx(
              s.FileCard__icon,
              specificIconClassNames.get(FileIcon)
            )}
          />
        )}
      </div>
      <div
        className={clsx(
          s.FileCard__name,
          onClickName && !isEditingName && s.FileCard__name_clickable
        )}
        onClick={onClickName && !isEditingName ? onClickName : undefined}
      >
        {isEditingName ? (
          <input
            ref={inputRef}
            className={s.FileCard__nameInput}
            defaultValue={name.split('.').slice(0, -1).join('.')}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        ) : (
          name
        )}
      </div>
      <div className={s.FileCard__meta}>
        {error ? (
          <span className={s.FileCard__error}>Ошибка</span>
        ) : (
          <>
            <span className={clsx(s.FileCard__metaItem, s.FileCard__type)}>
              {fileType}
            </span>
            <span className={s.FileCard__metaItem}>{formattedSize}</span>
            {lastTimeModified && (
              <span className={s.FileCard__metaItem}>
                {getTime(lastTimeModified)}
              </span>
            )}
            {emailLetterTag && (
              <span className={s.FileCard__metaItem}>
                {emailTagText[emailLetterTag]}
              </span>
            )}
          </>
        )}
      </div>
      {typeof progress === 'number' ? (
        <div className={s.FileCard__progress}>
          {Math.floor(progress * 100)}%
          <CircleProgress value={progress} />
        </div>
      ) : (
        <Tippy
          content={
            dropdownItems && (
              <DropdownItems
                className={s.IndexPage__createTaskDropdownList}
                itemClassName={s.IndexPage__createTaskDropdownItem}
                items={dropdownItems.map((item) => ({
                  ...item,
                  onClick: (e) => {
                    item.onClick(e);
                    dropdownToggle.unset();
                  }
                }))}
              />
            )
          }
          interactive
          visible={dropdownToggle.value}
          onClickOutside={dropdownToggle.unset}
          placement="bottom-end"
        >
          <div className={s.FileCard__actions}>
            {dropdownItems && dropdownItems.length !== 0 && (
              <IconChevron
                className={clsx(
                  s.FileCard__dropdownButton,
                  dropdownToggle.value && s.FileCard__dropdownButton_open
                )}
                onClick={dropdownToggle.toggle}
              />
            )}
            {error && (
              <IconReset className={s.FileCard__button} onClick={onReload} />
            )}
            {onRemove && (
              <IconDelete className={s.FileCard__button} onClick={onRemove} />
            )}
          </div>
        </Tippy>
      )}
    </div>
  );
};
