import React from 'react';
import { Modal } from '../../../../../common/components/ui/Modal/Modal';
import { Button } from '../../../../../common/components/ui/Button/Button';

import s from './TaskLogDeleteLetterModal.module.scss';

export interface TaskLogDeleteLetterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLetterDelete: () => void;
  deleteLetterLoading: boolean;
}

export const TaskLogDeleteLetterModal = ({
  isOpen,
  onClose,
  onLetterDelete,
  deleteLetterLoading
}: TaskLogDeleteLetterModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <p className={s.TaskLogDeleteLetterModal____description}>
        Вы уверены, что хотите удалить письмо? Это действие нельзя отменить.
      </p>

      <div className={s.TaskLogDeleteLetterModal__buttons}>
        <Button
          className={s.TaskLogDeleteLetterModal__buttonCancel}
          onClick={onClose}
        >
          Отменить
        </Button>
        <Button
          className={s.TaskLogDeleteLetterModal__buttonConfirm}
          onClick={!deleteLetterLoading ? onLetterDelete : undefined}
          loading={deleteLetterLoading}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};
