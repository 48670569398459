import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import s from './PageFooter.module.scss';

export const PageFooter = () => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={s.PageFooter}>
      <div className={s.PageFooter__title}>NeTupi v3.0</div>
      <div className={s.PageFooter__lineContainer}>
        <hr className={s.PageFooter__line} />
      </div>
      <div className={s.PageFooter__time}>{format(time, 'HH:mm')}</div>
    </div>
  );
};
