import React from 'react';
import clsx from 'clsx';
import { upperFirst } from 'lodash-es';
import {
  Modal,
  ModalProps
} from '../../../../../common/components/ui/Modal/Modal';
import {
  OAuthError,
  OAuthErrorData
} from '../../../../../common/hooks/oauth/OAuthMixin';
import { defaultRequestError } from '../../../../../common/consts';
import s from './CreateEmailAccount.module.scss';

export type OAuthErrorModalContentProps = OAuthErrorData;

export const OAuthErrorModalContent = ({
  error,
  data
}: OAuthErrorModalContentProps) => {
  let errorMessage;
  switch (error) {
    case OAuthError.EMAIL_ACCOUNT_ALREADY_ADDED:
      if (data?.email) {
        return (
          <>
            Почта{' '}
            <span className={s.OAuthErrorModal__email}>
              {upperFirst(data.email)}
            </span>{' '}
            уже была добавлена вами ранее
          </>
        );
      } else {
        return <>Почта уже была добавлена вами ранее</>;
      }
    case OAuthError.EMAIL_ACCOUNT_LINKED_TO_ANOTHER_ACCOUNT:
      if (data?.email) {
        return (
          <>
            Почта{' '}
            <span className={s.OAuthErrorModal__email}>
              {upperFirst(data.email)}
            </span>{' '}
            принадлежит другому пользователю
          </>
        );
      } else {
        return <>Почта принадлежит другому пользователю</>;
      }
    case OAuthError.SMTP_CONNECTION:
      errorMessage = 'Ошибка SMTP подключения';
      if (data?.email) {
        errorMessage += ` почтового аккаунта ${data.email}`;
      }
      if (data?.message) {
        errorMessage += `: ${data.message}`;
      }
      return <>{errorMessage}</>;
    case OAuthError.IMAP_CONNECTION:
      errorMessage = 'Ошибка IMAP подключения';
      if (data?.email) {
        errorMessage += ` почтового аккаунта ${data.email}`;
      }
      if (data?.message) {
        errorMessage += `: ${data.message}`;
      }
      return <>{errorMessage}</>;
    case OAuthError.EMAILS_DONT_MATCH:
      return (
        <>
          Пожалуйста, используйте соответствующий аккаунт для повторной
          авторизации
        </>
      );
    default:
      return <>{defaultRequestError}</>;
  }
};

export type OAuthErrorModalProps = Omit<ModalProps, 'header' | 'children'> &
  OAuthErrorData;

export const OAuthErrorModal = ({
  error,
  data,
  className,
  ...props
}: OAuthErrorModalProps) => {
  return (
    <Modal
      header="НАСТРОЙКА ПОЧТЫ"
      className={clsx(s.OAuthErrorModal, className)}
      {...props}
    >
      <OAuthErrorModalContent error={error} data={data} />
    </Modal>
  );
};
