import gql from 'graphql-tag';

export const FRAGMENT_USER = gql`
  fragment userProps on UserType {
    id
    name
    role
    username
  }
`;

export const FRAGMENT_REFRESH_TOKEN = gql`
  fragment RefreshTokenProps on UserAuthTokenType {
    expires_at
    token
    type
  }
`;

export const FRAGMENT_AUTH_RESPONSE = gql`
  fragment AuthResponseProps on AuthResponseType {
    accessToken
    refreshToken {
      ...RefreshTokenProps
    }
    user {
      ...userProps
    }
  }

  ${FRAGMENT_REFRESH_TOKEN}
  ${FRAGMENT_USER}
`;

export const MUTATION_USE_REFRESH_TOKEN = gql`
  mutation mutationUseRefreshToken($token: String!) {
    authData: useRefreshToken(token: $token) {
      ...AuthResponseProps
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_LOGIN = gql`
  mutation mutationLogin($username: String!, $password: String!) {
    authData: login(username: $username, password: $password) {
      ...AuthResponseProps
    }
  }

  ${FRAGMENT_AUTH_RESPONSE}
`;

export const MUTATION_RECOVER_PASSWORD = gql`
  mutation mutationRecoverPassword(
    $fullName: String!
    $position: String!
    $email: String!
    $comment: String
  ) {
    recoverPassword(
      input: {
        fullName: $fullName
        position: $position
        email: $email
        comment: $comment
      }
    )
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation mutationLogout($token: String!) {
    success: logout(token: $token)
  }
`;
