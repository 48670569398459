import React from 'react';

import { ReactComponent as IconMailbox } from '../../../../../assets/icons/IconMailbox.svg';
import { ReactComponent as YandexLogo } from '../../../../../assets/logo/yandex.svg';
import { ReactComponent as GoogleLogo } from '../../../../../assets/logo/google.svg';

import { Card } from '../../../../../common/components/ui/Card/Card';


import s from './CreateEmailAccount.module.scss';

interface CreateEmailAccountProps {
  createYandexEmailAccount: () => void;
  createGoogleEmailAccount: () => void;
  setCreateCustomAccountModalOpen: (open: boolean) => void;
}

export const CreateEmailAccount = ({
  createYandexEmailAccount,
  createGoogleEmailAccount,
  setCreateCustomAccountModalOpen
}: CreateEmailAccountProps) => {
  return (
    <>
      <div className={s.CreateEmailAccount__optionsList}>
        <Card
          component="li"
          clickable={true}
          className={s.CreateEmailAccount__option}
          onClick={() => createYandexEmailAccount()}
        >
          <YandexLogo />
        </Card>
        <Card
          component="li"
          clickable={true}
          className={s.CreateEmailAccount__option}
          onClick={() => createGoogleEmailAccount()}
        >
          <GoogleLogo />
        </Card>
        <Card
          component="li"
          clickable={true}
          className={s.CreateEmailAccount__option}
          onClick={() => setCreateCustomAccountModalOpen(true)}
        >
          <div>
            <IconMailbox className={s.CreateEmailAccount__optionIcon} />
            <div>
              Настроить
              <br />
              вручную
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
