import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { DocumentCreateInput, TaskType } from '../../types';
import { MUTATION_CREATE_ONLY_OFFICE_DOCUMENT } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';

export interface UseCreateOnlyOfficeDocumentReturn {
  handleCreateOnlyOfficeDocument: (
    input: DocumentCreateInput
  ) => Promise<string | undefined>;
  loading: boolean;
}

const CreateOnlyOfficeDocumentMutationOptions: MutationOptions<
  {
    createOnlyOfficeDocument: TaskType;
  },
  {
    input: DocumentCreateInput;
  }
> = {
  mutation: MUTATION_CREATE_ONLY_OFFICE_DOCUMENT
};

export const useCreateOnlyOfficeDocument =
  (): UseCreateOnlyOfficeDocumentReturn => {
    const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

    const createOnlyOfficeDocument = useGraphqlMutation(
      CreateOnlyOfficeDocumentMutationOptions
    );
    const createOnlyOfficeDocumentRun = createOnlyOfficeDocument.run;
    const handleCreateOnlyOfficeDocument = useCallback(
      (input: DocumentCreateInput) =>
        createOnlyOfficeDocumentRun({ variables: { input } }).then(
          ({ data }) => {
            const task = data?.createOnlyOfficeDocument;
            if (task) {
              tasksStore.handleTaskCreated(task);
            }
            return task?.id;
          }
        ),
      [createOnlyOfficeDocumentRun, tasksStore]
    );
    return {
      handleCreateOnlyOfficeDocument,
      loading: createOnlyOfficeDocument.loading
    };
  };
