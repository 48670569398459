import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_ONLY_OFFICE_ACCESS_TOKEN } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';

export interface UseCreateOnlyOfficeAccessTokenReturn {
  handleCreateOnlyOfficeAccessToken: (
    fileId: number
  ) => Promise<string | undefined>;
  loading: boolean;
}

const CreateOnlyOfficeAccessTokenMutationOptions: MutationOptions<
  {
    createOnlyOfficeAccessToken: string;
  },
  {
    fileId: number;
  }
> = {
  mutation: MUTATION_CREATE_ONLY_OFFICE_ACCESS_TOKEN
};

export const useCreateOnlyOfficeAccessToken =
  (): UseCreateOnlyOfficeAccessTokenReturn => {
    const createOnlyOfficeAccessToken = useGraphqlMutation(
      CreateOnlyOfficeAccessTokenMutationOptions
    );
    const createOnlyOfficeAccessTokenRun = createOnlyOfficeAccessToken.run;
    const handleCreateOnlyOfficeAccessToken = useCallback(
      (fileId: number) =>
        createOnlyOfficeAccessTokenRun({ variables: { fileId } }).then(
          ({ data }) => {
            return data?.createOnlyOfficeAccessToken;
          }
        ),
      [createOnlyOfficeAccessTokenRun]
    );
    return {
      handleCreateOnlyOfficeAccessToken,
      loading: createOnlyOfficeAccessToken.loading
    };
  };
