import { toast } from 'react-toastify';
import { AsyncSingletonError } from '@proscom/prostore-react';

export const handleDefaultError = (
  inputMessage: string,
  err?: any,
  handleSingletonError?: boolean
) => {
  let message = inputMessage;

  if (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }

    if (err.extensions?.exception) {
      const { errorId } = err.extensions?.exception;

      if (errorId) {
        message += `\nКод ошибки: ${errorId}`;
      }
    }

    if (err instanceof AsyncSingletonError) {
      if (handleSingletonError) {
        message = 'Дождитесь окончания выполнения запроса';
      } else {
        return;
      }
    }
  }

  toast(message, {
    className: 'Toastify__toast_error'
  });
};
