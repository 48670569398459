import React from 'react';
import ellipse from './ellipse.png';
import s from './Background.module.scss';

const maxScreenWidth = 7680;
const maxScreenHeight = 4320;
const gridItemSize = 40;
const gridRowsNum = Math.ceil(maxScreenHeight / gridItemSize);
const gridColumnsNum = Math.ceil(maxScreenWidth / gridItemSize);

export const Background = () => {
  return (
    <div className={s.Background}>
      <div className={s.Background__content}>
        <img
          src={ellipse}
          className={s.Background__ellipse_left}
          alt="ellipse"
        />
        <img
          src={ellipse}
          className={s.Background__ellipse_right}
          alt="ellipse"
        />
        <table className={s.Background__grid}>
          <tbody>
            {[...Array(gridRowsNum)].map((v, i) => (
              <tr key={i}>
                {[...Array(gridColumnsNum)].map((v, i) => (
                  <td key={i} className={s.Background__gridItem} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
