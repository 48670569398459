import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useAsyncOperation, useStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { flatten } from 'lodash-es';
import {
  EmailTaskTag,
  TaskStatus,
  TaskTypeEnum,
  TaskUpdateInput
} from '../../../graphql/types';
import { ReactComponent as IconClose } from '../../../assets/icons/IconCancel.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/IconCheck-2.svg';

import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { useUpdateTask } from '../../../graphql/hooks/tasks/useUpdateTask';
import { useDeleteTask } from '../../../graphql/hooks/tasks/useDeleteTask';
import { useUpdateTaskSortDate } from '../../../graphql/hooks/tasks/useUpdateTaskSortDate';
import { useTransferTask } from '../../../graphql/hooks/tasks/useTransferTask';
import { useRespondToTaskTransfer } from '../../../graphql/hooks/tasks/useRespondToTaskTransfer';
import { useUrlKey } from '../../../common/hooks/useUrlKey';
import { URL_KEY_TASK_ID } from '../../../store/urlKeys';
import { handleDefaultError } from '../../../utils/handleDefaultError';
import { IconButton } from '../../../common/components/ui/IconButton/IconButton';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button/Button';
import { TaskTags } from '../TaskTags/TaskTags';
import { LoaderPositions } from '../../../common/components/ui/Loader/Loader';
import { queryLoader } from '../../../common/components/utils/queryLoader';
import { useMediaQuery } from '../../../common/hooks/useMediaQuery';
import { FileCard } from '../../../common/components/ui/FileCard/FileCard';
import { useDownloadFile } from '../../../axios/hooks/files/useDownloadFile';
import { useOpenInOnlyOffice } from '../../../common/hooks/tasks/useOpenInOnlyOffice';
import { useConvertTextTaskToFile } from '../../../graphql/hooks/tasks/useConvertTextTaskToFile';
import {
  UploadTaskFileVariant,
  useUploadTaskFile
} from '../../../common/hooks/tasks/useUploadTaskFile';
import { TaskCardNameInput } from './TaskCardNameInput/TaskCardNameInput';
import { TaskCardDueDateInput } from './TaskCardDueDateInput/TaskCardDueDateInput';
import { TaskCardTransferTaskModal } from './TaskCardTransferTaskModal/TaskCardTransferTaskModal';
import { TaskCardAcceptTaskPrompt } from './TaskCardAcceptTaskPrompt/TaskCardAcceptTaskPrompt';
import { TaskCardDeleteTaskModal } from './TaskCardDeleteTaskModal/TaskCardDeleteTaskModal';
import { TaskCardControlButtons } from './TaskCardControlButtons/TaskCardControlButtons';
import { TaskLog } from './TaskLog/TaskLog';
import { TaskFileAttachments } from './TaskFileAttachments/TaskFileAttachments';
import {
  OpenLettersContext,
  useOpenLettersContextValue
} from './openLettersContext';
import s from './TaskCard.module.scss';

export interface TaskCardProps {
  open: boolean;
  close: () => void;
}

const updateTaskErrorMessage =
  'Произошла ошибка при обновлении задачи, пожалуйста, попробуйте еще раз или перезагрузите страницу';
const transferTaskErrorMessage =
  'Произошла ошибка при передаче задачи, пожалуйста, попробуйте еще раз или перезагрузите страницу';
const respondToTaskTransferErrorMessage =
  'Произошла ошибка при ответе на задачу, пожалуйста, попробуйте еще раз или перезагрузите страницу';
const deleteTaskErrorMessage =
  'Произошла ошибка при удалении задачи, пожалуйста, попробуйте еще раз или перезагрузите страницу';

export const TaskCard = ({ open, close }: TaskCardProps) => {
  const [taskId] = useUrlKey<number | undefined>(URL_KEY_TASK_ID);
  const [{ tasks }, { tasksQuery }] = useStore<TasksStore>(STORE_TASKS);
  const task = tasks.find(({ id }) => taskId === parseInt(id)) || null;

  const attachments = flatten(
    task?.log.map((log) => [
      ...(log.emailLetter?.attachments.map((attachment) => ({
        ...attachment,
        emailLetterTag: log.emailLetter?.email_letter_tag,
        emailLetterId: log.emailLetter?.id
      })) || []),
      ...(log.emailLetterDraft?.attachments.map((attachment) => ({
        ...attachment,
        emailLetterTag: log.emailLetterDraft?.id
          ? EmailTaskTag.Draft
          : undefined,
        emailLetterId: log.emailLetter?.id,
        emailLetterDraftId: log.emailLetterDraft?.id
      })) || [])
    ])
  );

  const [transferTaskModalOpen, setTransferTaskModalOpen] = useState(false);
  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false);
  const [fileAttachmentsModalOpen, setFileAttachmentsModalOpen] =
    useState(false);
  const openLettersContextValue = useOpenLettersContextValue();

  const { handleUpdateTask } = useUpdateTask();
  const { handleDeleteTask, loading: deleteTaskLoading } = useDeleteTask();
  const { handleUpdateTaskSortDate } = useUpdateTaskSortDate();
  const { handleTransferTask, loading: transferTaskLoading } =
    useTransferTask();
  const { handleRespondToTaskTransfer, loading: respondToTaskTransferLoading } =
    useRespondToTaskTransfer();

  const { downloadFile } = useDownloadFile();

  const taskName = task?.name || '';
  const taskDueDate = task?.due_date || '';
  const isFavorite = task?.favorite;
  const isClosed = task?.status === TaskStatus.Closed;
  const isCanceled = task?.status === TaskStatus.Canceled;
  const isLetter = task?.task_type === TaskTypeEnum.Email;

  const updateTask = useCallback(
    async (input: Omit<TaskUpdateInput, 'id'>) => {
      if (task) {
        try {
          await handleUpdateTask({ id: task.id, ...input });
        } catch (e) {
          handleDefaultError(updateTaskErrorMessage, e);
        }
      }
    },
    [task, handleUpdateTask]
  );

  const deleteTask = async () => {
    if (!taskId) {
      return;
    }
    try {
      await handleDeleteTask(taskId.toString());
      close();
      setDeleteTaskModalOpen(false);
      toast('Задача удалена');
    } catch (e) {
      handleDefaultError(deleteTaskErrorMessage, e);
    }
  };

  const updateTaskStatus = (newStatus: TaskStatus) => {
    return updateTask({ status: newStatus });
  };

  const {
    run: toggleTaskStatusClosedRun,
    loading: toggleTaskStatusClosedLoading
  } = useAsyncOperation(
    () => updateTaskStatus(isClosed ? TaskStatus.Opened : TaskStatus.Closed),
    {
      singleton: true
    }
  );

  const {
    run: toggleTaskStatusCanceledRun,
    loading: toggleTaskStatusCanceledLoading
  } = useAsyncOperation(
    () =>
      updateTaskStatus(isCanceled ? TaskStatus.Opened : TaskStatus.Canceled),
    {
      singleton: true
    }
  );

  const closeTask = async () => {
    try {
      await toggleTaskStatusClosedRun();
    } catch (err) {}
  };

  const cancelTask = async () => {
    try {
      await toggleTaskStatusCanceledRun();
    } catch (err) {}
  };

  const updateTaskSortDate = async () => {
    if (task) {
      try {
        await handleUpdateTaskSortDate(task.id);
      } catch (e) {
        handleDefaultError(updateTaskErrorMessage, e);
      }
    }
  };

  const transferTask = async (transfereeUsername: string) => {
    if (!taskId) {
      return;
    }
    try {
      await handleTransferTask({
        taskId: taskId.toString(),
        transfereeUsername
      });
      setTransferTaskModalOpen(true);
    } catch (e) {
      handleDefaultError(transferTaskErrorMessage, e);
    }
  };

  const [currentTransferAcceptance, setCurrentTransferAcceptance] = useState<
    boolean | null
  >(null);
  const respondToTaskTransfer = async (
    acceptTransfer: boolean,
    rememberChoiceForCurrentTransferer: boolean
  ) => {
    if (!taskId) {
      return;
    }
    try {
      setCurrentTransferAcceptance(acceptTransfer);
      await handleRespondToTaskTransfer({
        taskId: taskId.toString(),
        acceptTransfer,
        rememberChoiceForCurrentTransferer
      });
    } catch (e) {
      handleDefaultError(respondToTaskTransferErrorMessage, e);
    } finally {
      setCurrentTransferAcceptance(null);
    }
  };

  const isMobileScreen = useMediaQuery('(max-width: 819px)');
  const InfoComponent = useMemo(
    () =>
      isMobileScreen
        ? React.Fragment
        : ({ children }) => <div className={s.TaskCard__info}>{children}</div>,
    [isMobileScreen]
  );

  const [isEditingFileName, setIsEditingFileName] = useState(false);

  const updateFileName = useCallback(
    async (name: string) => {
      if (!name) {
        handleDefaultError('Название файла не может быть пустым');
        setIsEditingFileName(false);
        return;
      }

      if (/["*/:<>?\\|]/.test(name)) {
        handleDefaultError(
          'Название файла не может содержать символы " * / : < > ? \\ |'
        );
        setIsEditingFileName(false);
        return;
      }

      await updateTask({ name });
      setIsEditingFileName(false);
    },
    [updateTask, setIsEditingFileName]
  );

  const { openInOnlyOffice } = useOpenInOnlyOffice();
  const { handleConvertTextTaskToFile } = useConvertTextTaskToFile();

  const { onFileUpload } = useUploadTaskFile({
    variant: UploadTaskFileVariant.CONVERT_TEXT_TASK_TO_FILE,
    handleConvertTextTaskToFile
  });

  return (
    <>
      {task && (
        <>
          <TaskCardTransferTaskModal
            task={task}
            isOpen={transferTaskModalOpen}
            onClose={() => setTransferTaskModalOpen(false)}
            onTransferTask={transferTask}
            transferTaskLoading={transferTaskLoading}
          />
          <TaskCardDeleteTaskModal
            isOpen={deleteTaskModalOpen}
            onClose={() => setDeleteTaskModalOpen(false)}
            onTaskDelete={deleteTask}
            deleteTaskLoading={deleteTaskLoading}
          />
        </>
      )}

      <OpenLettersContext.Provider value={openLettersContextValue}>
        <div className={clsx(s.TaskCard, open && s.TaskCard_open)}>
          {queryLoader(tasksQuery.state, {
            loaderProps: { position: LoaderPositions.absolute }
          }) ||
            (task && (
              <>
                <TaskLog className={s.TaskCard__log} task={task} />

                <InfoComponent>
                  <div className={s.TaskCard__controls}>
                    {task.transfer_pending ? (
                      <>
                        <TaskCardAcceptTaskPrompt
                          onRespondToTaskTransfer={respondToTaskTransfer}
                          respondToTaskTransferLoading={
                            respondToTaskTransferLoading
                          }
                          currentTransferAcceptance={currentTransferAcceptance}
                        />
                        <IconButton
                          className={s.TaskCard__closeButton}
                          icon={IconClose}
                          onClick={close}
                        />
                      </>
                    ) : (
                      <>
                        <TaskCardDueDateInput
                          initValue={taskDueDate}
                          handleUpdateTask={updateTask}
                          className={s.TaskCard__dueDateInput}
                        />
                        <TaskCardControlButtons
                          isFavoriteTask={isFavorite}
                          isLetterTask={isLetter}
                          onDeleteClick={() => setDeleteTaskModalOpen(true)}
                          onTransferClick={() => setTransferTaskModalOpen(true)}
                          onFavoriteClick={() =>
                            updateTask({ favorite: !isFavorite })
                          }
                          onPostponeClick={updateTaskSortDate}
                          onCloseClick={close}
                          isTextTask={task.task_type === TaskTypeEnum.Text}
                          onFileInputChange={(e) => onFileUpload?.(e, taskId)}
                        />
                      </>
                    )}
                  </div>

                  {task.task_type === TaskTypeEnum.File && task.file ? (
                    <FileCard
                      key={task.file.id}
                      className={s.TaskCard__file}
                      name={task.file.file_name}
                      size={task.file.size}
                      onClickName={
                        task.file.canBeOpenedInOnlyOffice
                          ? () => openInOnlyOffice(parseInt(task.file!.id))
                          : undefined
                      }
                      isEditingName={isEditingFileName}
                      handleUpdateFileName={updateFileName}
                      dropdownItems={[
                        {
                          text: 'Скачать',
                          onClick: () => {
                            downloadFile(
                              parseInt(task.file!.id),
                              task.file!.file_name
                            );
                          }
                        },
                        {
                          text: 'Изменить имя',
                          onClick: () => {
                            setIsEditingFileName(true);
                          }
                        },
                        {
                          text: 'Открыть в OnlyOffice',
                          hidden: !task.file.canBeOpenedInOnlyOffice,
                          onClick: () =>
                            openInOnlyOffice(parseInt(task.file!.id))
                        }
                      ]}
                    />
                  ) : (
                    <TaskCardNameInput
                      initValue={taskName}
                      handleUpdateTask={updateTask}
                      className={clsx(
                        s.TaskCard__name,
                        task.status === TaskStatus.Canceled &&
                          s.TaskCard__name_canceled
                      )}
                    />
                  )}

                  <div className={s.TaskCard__statusButtons}>
                    <Button
                      onClick={closeTask}
                      className={clsx(
                        s.TaskCard__statusButton,
                        isClosed && s.TaskCard__statusButtonClosed_active
                      )}
                      rightIcon={IconCheck}
                      variant={ButtonVariant.TERTIARY}
                      size={ButtonSize.SMALL}
                      loading={toggleTaskStatusClosedLoading}
                    >
                      {isClosed ? 'Завершено' : 'Завершить'}
                    </Button>
                    <Button
                      onClick={cancelTask}
                      className={clsx(
                        s.TaskCard__statusButton,
                        isCanceled && s.TaskCard__statusButtonCanceled_active
                      )}
                      rightIcon={IconClose}
                      variant={ButtonVariant.TERTIARY}
                      size={ButtonSize.SMALL}
                      loading={toggleTaskStatusCanceledLoading}
                    >
                      {isCanceled ? 'Отменено' : 'Отменить'}
                    </Button>
                  </div>

                  {attachments.length !== 0 && (
                    <TaskFileAttachments
                      className={s.TaskCard__attachments}
                      attachments={attachments}
                      onClick={() => setFileAttachmentsModalOpen(true)}
                      isModalOpen={fileAttachmentsModalOpen}
                      setModalOpen={setFileAttachmentsModalOpen}
                    />
                  )}

                  <div className={s.TaskCard__tags}>
                    <TaskTags
                      taskId={parseInt(task.id)}
                      tags={task.tags}
                      tagClassName={s.TaskCard__tag}
                      tagIsInTaskCard
                    />
                  </div>
                </InfoComponent>
              </>
            ))}
        </div>
      </OpenLettersContext.Provider>
    </>
  );
};
