import clsx from 'clsx';
import React, {
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { format } from 'date-fns';
import { Text } from '../../../common/components/ui/Text/Text';
import { EmailTag } from '../../../common/components/ui/EmailTag/EmailTag';

import { ReactComponent as IconCheck } from '../../../assets/icons/IconCheck.svg';
import { ReactComponent as IconStar } from '../../../assets/icons/IconStar.svg';
import { ReactComponent as IconEnvelope } from '../../../assets/icons/IconEnvelope.svg';
import { ReactComponent as IconEdit } from '../../../assets/icons/IconEdit.svg';
import { ReactComponent as IconAttachment } from '../../../assets/icons/IconAttachment.svg';
import { ReactComponent as IconFile } from '../../../assets/icons/files/IconFileUnknown.svg';

import { EmailTaskTag, TaskStatus, TaskType } from '../../../graphql/types';
import { TaskTags } from '../TaskTags/TaskTags';
import s from './TasksListItem.module.scss';

export interface TasksListItemProps
  extends Pick<
      TaskType,
      | 'status'
      | 'name'
      | 'favorite'
      | 'tags'
      | 'transfer_pending'
      | 'log'
      | 'email_task_tag'
      | 'file'
    >,
    Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  id: number;
  lastUpdateDate?: Date;
  lastUpdateMessage?: string;
  className?: string;
  visible?: boolean;
}

export const TasksListItem: React.FC<TasksListItemProps> = ({
  id,
  status,
  name,
  lastUpdateDate,
  lastUpdateMessage,
  favorite,
  tags,
  className,
  transfer_pending,
  log,
  email_task_tag,
  file,
  visible,
  ...props
}) => {
  const onTagClick = (e: MouseEvent) => e.stopPropagation();

  const lettersAmount = useMemo(
    () => log.reduce((acc, log) => (log.emailLetter ? acc + 1 : acc), 0),
    [log]
  );

  const draftsAmount = useMemo(
    () => log.reduce((acc, log) => (log.emailLetterDraft ? acc + 1 : acc), 0),
    [log]
  );

  const attachmentsAmount = useMemo(
    () =>
      log.reduce((acc, log) => {
        if (log.emailLetter?.attachments) {
          acc += log.emailLetter?.attachments.length || 0;
        }

        if (log.emailLetterDraft?.attachments) {
          acc += log.emailLetterDraft?.attachments.length || 0;
        }

        return acc;
      }, 0),
    [log]
  );

  const listItemRef = useRef<HTMLDivElement>(null);
  const favoriteIconRef = useRef<HTMLDivElement>(null);
  const [maxTagsWidth, setMaxTagsWidth] = useState<number>(0);

  const fitTags = useCallback(() => {
    const isTabletMediumScreen = window.innerWidth > 820;
    const isTabletLargeScreen = window.innerWidth > 1180;
    const isDesktopMediumScreen = window.innerWidth > 1440;

    const favoriteIconWidth =
      (isTabletMediumScreen && favoriteIconRef.current?.clientWidth) || 0;

    let maxNameWidth = 0;
    let padding = 20;
    let margin = isTabletMediumScreen ? 24 : 0;

    if (isDesktopMediumScreen) {
      maxNameWidth = 500;
    } else if (isTabletLargeScreen) {
      maxNameWidth = 400;
    } else if (isTabletMediumScreen) {
      maxNameWidth = 340;
    }

    setMaxTagsWidth(
      listItemRef.current?.clientWidth! -
        maxNameWidth -
        favoriteIconWidth -
        padding -
        margin
    );
  }, []);

  useEffect(() => {
    window.addEventListener('resize', fitTags);

    return () => {
      window.removeEventListener('resize', fitTags);
    };
  }, [fitTags]);

  useEffect(() => {
    fitTags();
  }, [fitTags, visible]);

  return (
    <div
      className={clsx(
        s.TasksListItem,
        status === TaskStatus.Closed && s.TasksListItem_closed,
        status === TaskStatus.Canceled && s.TasksListItem_canceled,
        className
      )}
      {...props}
      ref={listItemRef}
    >
      <div className={s.TasksListItem__content}>
        <Text
          className={clsx(
            s.TasksListItem__name,
            !name && s.TasksListItem__name_untitled
          )}
        >
          {lettersAmount !== 0 && (
            <span className={s.TasksListItem__lettersNumber}>
              <IconEnvelope />
              {lettersAmount}
            </span>
          )}
          {file && <IconFile className={s.TasksListItem__fileIcon} />}
          <span className={s.TasksListItem__nameText}>
            {name || 'Без названия'}{' '}
          </span>
          {status === TaskStatus.Closed && (
            <IconCheck className={s.TasksListItem__closedIcon} />
          )}
          {attachmentsAmount !== 0 && (
            <>
              <IconAttachment className={s.TasksListItem__propIcon} />
              <span className={s.TasksListItem__propAmount}>
                {attachmentsAmount}
              </span>
            </>
          )}
          {(email_task_tag === EmailTaskTag.Draft || draftsAmount !== 0) && (
            <>
              <IconEdit className={s.TasksListItem__propIcon} />
              {draftsAmount !== 0 && email_task_tag !== EmailTaskTag.Draft && (
                <span className={s.TasksListItem__propAmount}>
                  {draftsAmount}
                </span>
              )}
            </>
          )}
        </Text>

        {lastUpdateDate && lastUpdateMessage && (
          <div
            className={s.TasksListItem__lastComment}
            dangerouslySetInnerHTML={{
              __html: `${format(
                lastUpdateDate,
                'yyyy-MM-dd, HH:mm'
              )} = ${lastUpdateMessage}`
            }}
          />
        )}

        {email_task_tag && (
          <EmailTag
            tag={email_task_tag}
            className={s.TasksListItem__emailTag}
          />
        )}
      </div>

      {transfer_pending ? (
        <div className={s.TasksListItem__transferPending}>Новая задача</div>
      ) : (
        <>
          {favorite && (
            <div
              className={s.TasksListItem__favoriteIcon}
              ref={favoriteIconRef}
            >
              <IconStar />
            </div>
          )}
          <div className={s.TasksListItem__tags}>
            <TaskTags
              taskId={id}
              tags={tags}
              onTagClick={onTagClick}
              selectedTagClassName={s.TasksListItem__tag_selected}
              needSliceTags
              hideAddTagButton
              maxWidth={maxTagsWidth}
            />
          </div>
        </>
      )}
    </div>
  );
};
