import React, {
  ChangeEvent,
  HTMLAttributes,
  useState,
  KeyboardEvent,
  useMemo
} from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAsyncOperation, useContextStore } from '@proscom/prostore-react';

import { AuthStore } from '../../../../store/AuthStore';
import { STORE_AUTH } from '../../../../store/storeKeys';

import { Window } from '../Window/Window';
import { Input } from '../Input/Input';
import { Header } from '../Header/Header';
import { Button } from '../Button/Button';
import { Link as LinkComponent } from '../Link/Link';
import { defaultRequestError } from '../../../consts';

import s from './AuthForm.module.scss';

export interface ISignInFormData {
  username: string;
  password: string;
}
export interface ISignInFormErrors {
  username?: string;
  password?: string;
}

export interface SignInFormProps extends HTMLAttributes<HTMLDivElement> {
  onSuccess: () => void;
}

export const SignInForm = ({ onSuccess, ...props }: SignInFormProps) => {
  const authStore = useContextStore<AuthStore>(STORE_AUTH);
  const login = useAsyncOperation(authStore.login, { singleton: true });

  const [authError, setAuthError] = useState<string>('');

  const formik = useFormik<ISignInFormData>({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: ({ username, password }) => {
      login
        .run(username, password)
        .then((e) => {
          onSuccess();
        })
        .catch((e) => setAuthError(e?.message || defaultRequestError));
    },
    validate: ({ username, password }) => {
      setAuthError('');
      const errors: ISignInFormErrors = {};
      if (!username) {
        errors.username = 'Введите логин';
      }
      if (!password) {
        errors.password = 'Введите пароль';
      }
      return errors;
    },
    validateOnChange: false
  });
  const handleChange = (value: string, e: ChangeEvent) =>
    formik.handleChange(e);
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && formik.handleSubmit();

  const isLoading = useMemo(
    () => formik.isSubmitting && !authError,
    [formik, authError]
  );

  return (
    <Window header="АВТОРИЗАЦИЯ" {...props}>
      <Header className={s.AuthForm__header}>Авторизация</Header>
      <div className={s.AuthForm__description}>
        Введите данные своей учетной записи
      </div>
      <Input
        name="username"
        value={formik.values.username}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label="Логин (username):\>"
        error={formik.errors.username}
        className={clsx(
          s.AuthForm__input,
          formik.errors.username && s.AuthForm__input_error
        )}
      />
      <Input
        name="password"
        value={formik.values.password}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        type="password"
        label="Пароль:\>"
        error={formik.errors.password}
        className={clsx(
          s.AuthForm__input,
          formik.errors.password && s.AuthForm__input_error
        )}
      />
      <div className={s.AuthForm__linkContainer}>
        <LinkComponent
          component={Link}
          to="recoverPassword"
          className={s.AuthForm__link}
        >
          ЗАБЫЛИ ПАРОЛЬ?
        </LinkComponent>
      </div>
      {authError && <div className={s.AuthForm__error}>{authError}</div>}
      <Button
        className={s.AuthForm__button}
        onClick={() => formik.handleSubmit()}
        loading={isLoading}
      >
        ВОЙТИ
      </Button>
    </Window>
  );
};
