import React from 'react';
import clsx from 'clsx';
import { EmailLetterTag, EmailTaskTag } from '../../../../graphql/types';

import s from './EmailTag.module.scss';

export type EmailTagProps = {
  tag: EmailTaskTag | EmailLetterTag;
  className?: string;
};

export const emailTagText = {
  [EmailTaskTag.Sent]: 'Исходящее',
  [EmailTaskTag.Inbox]: 'Входящее',
  [EmailTaskTag.Draft]: 'Черновик'
};

export const EmailTag = ({ tag, className }: EmailTagProps) => {
  return <div className={clsx(s.EmailTag, className)}>{emailTagText[tag]}</div>;
};
