import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_DOWNLOAD_FILE_TOKEN } from '../../mutations/files';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';

export interface UseCreateDownloadFileTokenReturn {
  handleCreateDownloadFileToken: (
    fileId: number
  ) => Promise<string | undefined>;
  loading: boolean;
}

const CreateDownloadFileTokenMutationOptions: MutationOptions<
  {
    createDownloadFileToken: string;
  },
  {
    fileId: number;
  }
> = {
  mutation: MUTATION_CREATE_DOWNLOAD_FILE_TOKEN
};

export const useCreateDownloadFileToken =
  (): UseCreateDownloadFileTokenReturn => {
    const createDownloadFileToken = useGraphqlMutation(
      CreateDownloadFileTokenMutationOptions
    );
    const createDownloadFileTokenRun = createDownloadFileToken.run;
    const handleCreateDownloadFileToken = useCallback(
      (fileId: number) =>
        createDownloadFileTokenRun({ variables: { fileId } }).then(
          ({ data }) => {
            return data?.createDownloadFileToken;
          }
        ),
      [createDownloadFileTokenRun]
    );
    return {
      handleCreateDownloadFileToken,
      loading: createDownloadFileToken.loading
    };
  };
