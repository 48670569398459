import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_RECOVER_PASSWORD } from '../mutations/auth';
import { RecoverPasswordInput } from '../types';
import { useGraphqlMutation } from '../../common/hooks/utils/useGraphqlMutation';

export interface UseRecoverPasswordReturn {
  handleRecoverPassword: (
    input: RecoverPasswordInput
  ) => Promise<boolean | null | undefined>;
  loading: boolean;
}

const RecoverPasswordMutationOptions: MutationOptions<
  { recoverPassword: boolean },
  RecoverPasswordInput
> = {
  mutation: MUTATION_RECOVER_PASSWORD
};

export const useRecoverPassword = (): UseRecoverPasswordReturn => {
  const recoverPassword = useGraphqlMutation(RecoverPasswordMutationOptions);
  const recoverPasswordRun = recoverPassword.run;
  const handleRecoverPassword = useCallback(
    (input: RecoverPasswordInput) =>
      recoverPasswordRun({ variables: input }).then(
        ({ data }) => data?.recoverPassword
      ),
    [recoverPasswordRun]
  );
  return {
    handleRecoverPassword,
    loading: recoverPassword.loading
  };
};
