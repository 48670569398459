import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconCheck } from './IconCheck.svg';
import s from './Checkbox.module.scss';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  rootProps?: Omit<HTMLAttributes<HTMLDivElement>, 'className'>;
  label?: string;
}

export const Checkbox = ({
  rootProps,
  checked,
  label,
  className,
  ...props
}: CheckboxProps) => (
  <div className={className} {...rootProps}>
    <label className={s.Checkbox__container}>
      <div
        className={clsx(
          s.Checkbox__iconContainer,
          checked && s.Checkbox__iconContainer_checked
        )}
      >
        <IconCheck className={s.Checkbox__icon} />
      </div>
      {label && <div className={s.Checkbox__label}>{label}</div>}
      <input
        type="checkbox"
        checked={checked}
        className={s.Checkbox__input}
        {...props}
      />
    </label>
  </div>
);
