import gql from 'graphql-tag';

export const MUTATION_CREATE_CUSTOM_EMAIL_ACCOUNT = gql`
  mutation mutationCreateEmailAccount($input: EmailAccountCreateInput!) {
    createCustomEmailAccount(input: $input) {
      id
    }
  }
`;
export const MUTATION_UPDATE_EMAIL_ACCOUNT = gql`
  mutation mutationUpdateEmailAccount($input: EmailAccountUpdateInput!) {
    updateEmailAccount(input: $input) {
      id
    }
  }
`;
export const MUTATION_DELETE_EMAIL_ACCOUNT = gql`
  mutation mutationDeleteEmailAccount($id: ID!) {
    result: deleteEmailAccount(id: $id)
  }
`;
