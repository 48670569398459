import { Routes, RouteType } from '../common/components/utils/Routes';

import { DefaultLayout } from './_layouts/DefaultLayout';
import { withUserLayout } from './_layouts/UserLayout';

import { IndexPage } from './index/IndexPage';
import LoginPage from './login/LoginPage';
import RecoverPasswordPage from './recoverPassword/RecoverPasswordPage';
import NotFoundPage from './notFound/NotFoundPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: DefaultLayout(withUserLayout(IndexPage))
  },
  {
    path: '/login',
    exact: true,
    component: DefaultLayout(LoginPage)
  },
  {
    path: '/recoverPassword',
    exact: true,
    component: DefaultLayout(RecoverPasswordPage)
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
