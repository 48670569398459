import React from 'react';
import { ErrorBoundary } from '../../common/components/utils/ErrorBoundary';
import { ErrorMessage } from '../../common/components/ui/ErrorMessage/ErrorMessage';

export function DefaultLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    return (
      <div id="wrapper-offset">
        <ErrorBoundary component={ErrorMessage}>
          <Comp {...props} />
        </ErrorBoundary>
      </div>
    );
  };
}
