import React, { useEffect, useState, KeyboardEvent, useRef } from 'react';
import {
  add as addDate,
  isToday,
  isTomorrow,
  isPast,
  Duration,
  isValid
} from 'date-fns';
import MaskedInput from 'react-maskedinput';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { TaskUpdateInput } from '../../../../graphql/types';
import { DropdownItems } from '../../../../common/components/ui/DropdownItems/DropdownItems';
import { useInput } from '../../../../common/hooks/useInput';
import s from './TaskCardDueDateInput.module.scss';

const DATE_OPTIONS = [
  { duration: {}, name: 'Сегодня' },
  { duration: { days: 1 }, name: 'Завтра' },
  { duration: { weeks: 1 }, name: 'Через неделю' },
  { duration: { months: 1 }, name: 'Через месяц' }
];

const DATE_VALUES_SPECIAL_CASES = [
  { checkDate: (date: Date) => isToday(date), value: 'Сегодня' },
  { checkDate: (date: Date) => isTomorrow(date), value: 'Завтра' }
];

const addDurationToNow = (duration: Duration) => {
  return addDate(new Date(), duration);
};

export interface TaskCardDueDateInputProps {
  initValue: string;
  handleUpdateTask: (updateObj: Omit<TaskUpdateInput, 'id'>) => void;
  className?: string;
}

export const TaskCardDueDateInput = ({
  initValue,
  handleUpdateTask,
  className
}: TaskCardDueDateInputProps) => {
  const inputRef = useRef<any>(null);
  const [value, onChange, setValue] = useInput(initValue);
  const [isDateEdited, setDateEdited] = useState(false);
  const [focusedOptionI, setFocusedOptionI] = useState(-1);

  useEffect(() => setValue(initValue), [setValue, initValue]);

  const startEdit = () => {
    setFocusedOptionI(-1);
    setDateEdited(true);
  };

  const finishEdit = (needUpdate?: boolean) => {
    if (needUpdate && initValue !== value) {
      const newDate = new Date(value);
      if (isValid(newDate)) {
        handleUpdateTask({
          due_date: (isPast(newDate) ? new Date() : newDate).toISOString()
        });
      } else {
        setValue(initValue);
      }
    }
    setFocusedOptionI(-1);
    setDateEdited(false);
    inputRef.current?.blur();
  };

  const getNextIndex = {
    ArrowUp: () =>
      focusedOptionI === -1 ? DATE_OPTIONS.length - 1 : focusedOptionI - 1,
    ArrowDown: () =>
      focusedOptionI === DATE_OPTIONS.length - 1 ? -1 : focusedOptionI + 1
  };

  const handleFocusOnItem = (i: number) => {
    setFocusedOptionI(i);
    const duration = DATE_OPTIONS[i]?.duration;
    const newValue = duration
      ? addDurationToNow(duration).toISOString()
      : value;
    setValue(newValue);
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        finishEdit(true);
        break;
      case 'Escape':
        finishEdit();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();
        const nextItemIndex = getNextIndex[e.key]();
        handleFocusOnItem(nextItemIndex);
    }
  };

  const dueDateObj = new Date(initValue);
  const specialValue =
    dueDateObj &&
    !isDateEdited &&
    DATE_VALUES_SPECIAL_CASES.find(({ checkDate }) => checkDate(dueDateObj))
      ?.value;

  const dynamicProps = specialValue
    ? { placeholder: specialValue }
    : { value: initValue };

  return (
    <div className={clsx(s.TaskCardDueDate, className)}>
      <Tippy
        content={
          <DropdownItems
            items={DATE_OPTIONS.map(({ name, duration }, i) => ({
              text: name,
              onMouseEnter: () => setFocusedOptionI(i),
              onMouseLeave: () => setFocusedOptionI(-1),
              onClick: () => {
                handleUpdateTask({
                  due_date: addDurationToNow(duration).toISOString()
                });
                setDateEdited(false);
              },
              focused: i === focusedOptionI
            }))}
          />
        }
        interactive
        visible={isDateEdited}
        placement="bottom-end"
        onClickOutside={() => finishEdit(true)}
      >
        <span>
          <MaskedInput
            ref={inputRef}
            mask="1111-11-11"
            onChange={onChange}
            onFocus={startEdit}
            onKeyUp={onKeyUp}
            className={s.TaskCardDueDate__input}
            {...dynamicProps}
          />
        </span>
      </Tippy>
    </div>
  );
};
