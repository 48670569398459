import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { LOCAL_STORAGE_KEY_SYSTEM_LOGS_ENABLED } from './storageKeys';

export class SystemLogsEnabledStore extends WebStorageValueStore<boolean> {
  constructor() {
    super(
      localStorage,
      LOCAL_STORAGE_KEY_SYSTEM_LOGS_ENABLED,
      (value) => !!value
    );
  }

  toggleEnabled() {
    this.setValue(!this.state.value);
  }
}
