import { useLocationQuery } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from '../../store/storeKeys';
import {
  URL_KEY_SEARCH_VALUE,
  URL_KEY_SELECTED_DAYS,
  URL_KEY_SELECTED_TAGS,
  URL_KEY_TASK_ID
} from '../../store/urlKeys';
import { getTodayDate } from '../../utils/date';

export const useResetFilters = () => {
  const [, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_SELECTED_TAGS,
    URL_KEY_SEARCH_VALUE,
    URL_KEY_SELECTED_DAYS,
    URL_KEY_TASK_ID
  ]);

  const resetFilters = () => {
    locationStore.changeQuery({
      [URL_KEY_SELECTED_DAYS]: [getTodayDate()],
      [URL_KEY_SELECTED_TAGS]: undefined,
      [URL_KEY_SEARCH_VALUE]: undefined,
      [URL_KEY_TASK_ID]: undefined
    });
  };

  return resetFilters;
};
