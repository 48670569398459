import gql from 'graphql-tag';

export const FRAGMENT_FILE_FULL = gql`
  fragment fileFull on FileType {
    file_name
    id
    source_file_id
    last_modified
    size
    variant
    expired_at
    canBeOpenedInOnlyOffice
  }
`;
