import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { MUTATION_DELETE_TAG } from '../../mutations/tags';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { STORE_TASKS } from '../../../store/storeKeys';
import { TasksStore } from '../../../store/TasksStore';
import { clearCache } from '../../queriesUtils';

export interface UseDeleteTagReturn {
  handleDeleteTag: (tagId: string) => Promise<boolean | undefined>;
  loading: boolean;
}

const DeleteTagMutationOptions: MutationOptions<
  { deleteTag: boolean },
  { tagId: string }
> = {
  mutation: MUTATION_DELETE_TAG,
  refetchQueries: ['queryCurrentUserTags'],
  update: clearCache(['currentUserTags'])
};

export const useDeleteTag = (): UseDeleteTagReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const deleteTag = useGraphqlMutation(DeleteTagMutationOptions);
  const deleteTagRun = deleteTag.run;
  const handleDeleteTag = useCallback(
    (tagId: string) =>
      deleteTagRun({ variables: { tagId } }).then(({ data }) => {
        const deleted = data?.deleteTag;
        if (deleted) {
          tasksStore.handleTagDeleted(tagId);
        }
        return deleted;
      }),
    [deleteTagRun, tasksStore]
  );
  return {
    handleDeleteTag,
    loading: deleteTag.loading
  };
};
