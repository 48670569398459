import React from 'react';
import { Modal } from '../../../../common/components/ui/Modal/Modal';
import { Button } from '../../../../common/components/ui/Button/Button';
import { TaskTransferPermissionType } from '../../../../graphql/types';
import s from './SettingsTaskTransferPermissionsModal.module.scss';

export interface SettingsTaskTransferPermissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPermission: TaskTransferPermissionType;
  onPermissionDelete: (permissionId: string) => void;
  permissionDeleteLoading: boolean;
}

export const SettingsTaskTransferPermissionsModal = ({
  isOpen,
  onClose,
  currentPermission,
  onPermissionDelete,
  permissionDeleteLoading
}: SettingsTaskTransferPermissionsModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <p className={s.SettingsTaskTransferPermissionsModal____description}>
        Вы уверены, что хотите забыть выбор по умолчанию для{' '}
        {currentPermission.transfererUsername}. Это действие нельзя отменить и
        вы снова сможете запомнить выбор только после того как получите задачу
        от этого пользователя
      </p>

      <div className={s.SettingsTaskTransferPermissionsModal__buttons}>
        <Button
          className={s.SettingsTaskTransferPermissionsModal__buttonCancel}
          onClick={onClose}
        >
          Отменить
        </Button>
        <Button
          className={s.SettingsTaskTransferPermissionsModal__buttonConfirm}
          onClick={() => onPermissionDelete(currentPermission.id)}
          loading={permissionDeleteLoading}
        >
          Да, забыть
        </Button>
      </div>
    </Modal>
  );
};
