import { onError } from 'apollo-link-error';
import { switchMap } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';
import { AuthStore } from '../../store/AuthStore';
import { wrapForwardOperation } from './wrapForwardOperation';

export const createCheckAuthenticationErrorLink = (authStore: AuthStore) =>
  onError(
    ({ graphQLErrors, networkError, response, operation, forward }): any => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        const errResponse = graphQLErrors[0]?.extensions?.response;
        if (
          errResponse &&
          errResponse.error === 'Unauthorized' &&
          errResponse.message === 'Invalid Token'
        ) {
          if (authStore.canRefreshToken()) {
            return from(authStore.refreshToken()).pipe(
              switchMap((state) => {
                if (state && state.accessToken) {
                  return wrapForwardOperation(forward, operation);
                } else if (networkError) {
                  return throwError(networkError);
                } else {
                  return of(response);
                }
              })
            );
          }
        }
      }
    }
  );
