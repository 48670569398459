import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { useContextStore } from '@proscom/prostore-react';
import { TaskType, AttachTagInput } from '../../types';
import { MUTATION_ATTACH_TAG_TO_TASK } from '../../mutations/tasks';
import { useGraphqlMutation } from '../../../common/hooks/utils/useGraphqlMutation';
import { TasksStore } from '../../../store/TasksStore';
import { STORE_TASKS } from '../../../store/storeKeys';
import { clearCache } from '../../queriesUtils';

export interface UseAttachTagToTaskReturn {
  handleAttachTagToTask: (input: AttachTagInput) => Promise<void>;
  loading: boolean;
}

const AttachTagToTaskMutationOptions: MutationOptions<
  {
    attachTagToTask: TaskType;
  },
  {
    input: AttachTagInput;
  }
> = {
  mutation: MUTATION_ATTACH_TAG_TO_TASK,
  refetchQueries: ['queryCurrentUserTags'],
  update: clearCache(['currentUserTags'])
};

export const useAttachTagToTask = (): UseAttachTagToTaskReturn => {
  const tasksStore = useContextStore<TasksStore>(STORE_TASKS);

  const attachTagToTask = useGraphqlMutation(AttachTagToTaskMutationOptions);
  const attachTagToTaskRun = attachTagToTask.run;
  const handleAttachTagToTask = useCallback(
    (input: AttachTagInput) =>
      attachTagToTaskRun({ variables: { input } }).then(({ data }) => {
        const task = data?.attachTagToTask;
        if (task) {
          tasksStore.handleTaskUpdated(task);
        }
      }),
    [attachTagToTaskRun, tasksStore]
  );
  return {
    handleAttachTagToTask,
    loading: attachTagToTask.loading
  };
};
