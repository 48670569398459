import { useCallback } from 'react';
import { MutationOptions } from 'apollo-client';
import { MUTATION_CREATE_OAUTH_ONETIME_TOKEN } from '../mutations/oauth';
import { useGraphqlMutation } from '../../common/hooks/utils/useGraphqlMutation';

export interface UseCreateOauthOnetimeTokenReturn {
  handleCreateOauthOnetimeToken: () => Promise<string | null | undefined>;
  loading: boolean;
}

const CreateOauthOnetimeTokenMutationOptions: MutationOptions<{
  createOAuthOneTimeToken: string;
}> = {
  mutation: MUTATION_CREATE_OAUTH_ONETIME_TOKEN
};

export const useCreateOauthOnetimeToken = (): UseCreateOauthOnetimeTokenReturn => {
  const createOauthOnetimeToken = useGraphqlMutation(
    CreateOauthOnetimeTokenMutationOptions
  );
  const createOauthOnetimeTokenRun = createOauthOnetimeToken.run;
  const handleCreateOauthOnetimeToken = useCallback(
    () =>
      createOauthOnetimeTokenRun({}).then(
        ({ data }) => data?.createOAuthOneTimeToken
      ),
    [createOauthOnetimeTokenRun]
  );
  return {
    handleCreateOauthOnetimeToken,
    loading: createOauthOnetimeToken.loading
  };
};
