import React, { ComponentProps, ElementType } from 'react';
import clsx from 'clsx';
import s from './Link.module.scss';

export type LinkProps<ComponentType extends ElementType> = {
  component?: ComponentType;
} & ComponentProps<ComponentType>;

export const Link = <ComponentType extends ElementType = 'a'>({
  component = 'a',
  className,
  ...componentProps
}: LinkProps<ComponentType>) => {
  const Component = component;

  return <Component className={clsx(s.Link, className)} {...componentProps} />;
};
