import React from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import clsx from 'clsx';
import s from './Select.module.scss';

export interface SelectClasses {
  label?: string;
  error?: string;
  reactSelectRoot?: string;
}

export interface ISelectOption<T> {
  label: string;
  value: T;
}
export interface SelectProps<T>
  extends Omit<ReactSelectProps<ISelectOption<T>>, 'classNamePrefix'> {
  label?: string;
  classes?: SelectClasses;
  required?: boolean;
  error?: string;
}

export const Select = <T extends {} = string>({
  label,
  classes,
  className,
  required,
  error,
  ...props
}: SelectProps<T>) => (
  <div className={clsx(s.Select, className)}>
    {label && (
      <label className={clsx(s.Select__label, classes?.label)}>
        {label}
        <span className={s.Select__requiredSign}>{required && '*'}</span>
      </label>
    )}
    <ReactSelect
      className={clsx(s.Select__reactSelectRoot, classes?.reactSelectRoot)}
      classNamePrefix="netupi-select"
      {...props}
    />
    {error && (
      <div className={clsx(s.Select__error, classes?.error)}>{error}</div>
    )}
  </div>
);
