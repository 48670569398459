import React from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import clsx from 'clsx';

import s from './ToggleSwitch.module.scss';

const defaultProps = {
  uncheckedIcon: false,
  checkedIcon: false,
  onColor: '#8001ff',
  offColor: '#BAC0C6',
  handleDiameter: 16,
  width: 36,
  height: 20,
  activeBoxShadow: 'none'
};

export interface ToggleSwitchProps extends ReactSwitchProps {}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  className,
  ...props
}) => {
  return (
    <Switch
      className={clsx(s.ToggleSwitch, className)}
      {...defaultProps}
      {...props}
    />
  );
};
