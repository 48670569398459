import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { isEqual, parseInt } from 'lodash-es';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState } from 'draft-js';
import { toast } from 'react-toastify';
import { useStoreState } from '@proscom/prostore-react';
import {
  EmailLetterDraftType,
  EmailLetterTag,
  EmailLetterType
} from '../../../../../graphql/types';
import {
  emailTextToPreview,
  normalizeEmail,
  normalizeLetterHtml
} from '../../../../../utils/stringUtils';
import { ReactComponent as IconReply } from '../../../../../assets/icons/IconReply.svg';
import { ReactComponent as IconReplyAll } from '../../../../../assets/icons/IconReplyAll.svg';
import { ReactComponent as IconForward } from '../../../../../assets/icons/IconForward.svg';
import { ReactComponent as IconChevron } from '../../../../../assets/icons/IconChevron.svg';
import { ReactComponent as IconAttachment } from '../../../../../assets/icons/IconAttachment.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/IconEdit.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/IconDelete.svg';

import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../../common/components/ui/Button/Button';
import { Modal } from '../../../../../common/components/ui/Modal/Modal';
import { EmailTag } from '../../../../../common/components/ui/EmailTag/EmailTag';
import {
  LetterForm,
  LetterFormRef,
  ReplyMode
} from '../../LetterForm/LetterForm';
import { FileCard } from '../../../../../common/components/ui/FileCard/FileCard';
import { useDownloadFile } from '../../../../../axios/hooks/files/useDownloadFile';
import { OpenLettersContext } from '../../openLettersContext';
import { useCreateReplyDraft } from '../../../../../graphql/hooks/tasks/useCreateReplyDraft';
import { useDeleteReplyDraft } from '../../../../../graphql/hooks/tasks/useDeleteReplyDraft';
import { useDeleteLetter } from '../../../../../graphql/hooks/tasks/useDeleteLetter';
import { handleDefaultError } from '../../../../../utils/handleDefaultError';
import { TaskLogDeleteLetterModal } from '../TaskLogDeleteLetterModal/TaskLogDeleteLetterModal';
import { createForwardedLetterHTML } from '../../LetterForm/createForwardedLetterHTML';
import { useOpenInOnlyOffice } from '../../../../../common/hooks/tasks/useOpenInOnlyOffice';
import { useUrlKey } from '../../../../../common/hooks/useUrlKey';
import { URL_KEY_TASK_ID } from '../../../../../store/urlKeys';
import { useCreateFileTaskFromLetterAttachment } from '../../../../../graphql/hooks/tasks/useCreateFileTaskFromLetterAttachment';
import { EmailAccountsStoreState } from '../../../../../store/EmailAccountsStore';
import { STORE_EMAIL_ACCOUNTS } from '../../../../../store/storeKeys';
import s from './TaskLogStoryLetter.module.scss';

export interface TaskLogStoryLetterProps {
  className?: string;
  date: Date;
  emailAccountDeleted?: boolean;
  taskId: string;
  taskLogStoryId: string;
  emailLetter: EmailLetterType;
  emailLetterDraft?: EmailLetterDraftType | null;
}

export enum ReplyFormShowMode {
  SHOW_IN_LOG,
  SHOW_IN_MODAL
}

export const TaskLogStoryLetter = ({
  className,
  date,
  emailAccountDeleted,
  taskId,
  taskLogStoryId,
  emailLetter,
  emailLetterDraft
}: TaskLogStoryLetterProps) => {
  const {
    id,
    to,
    from,
    subject,
    cc,
    bcc,
    html,
    attachments,
    email_account_id,
    email_letter_tag,
    plain_text
  } = emailLetter;

  const openLettersContext = useContext(OpenLettersContext);
  const open = useMemo(
    () => openLettersContext?.openedLetterIds.includes(emailLetter.id) || false,
    [openLettersContext, emailLetter]
  );
  const scrolled = useMemo(
    () =>
      openLettersContext?.scrolledLetterId === emailLetter.id ||
      (emailLetterDraft?.id &&
        openLettersContext?.scrolledLetterDraftId === emailLetterDraft?.id),
    [openLettersContext, emailLetterDraft, emailLetter]
  );

  const { emailAccounts } =
    useStoreState<EmailAccountsStoreState>(STORE_EMAIL_ACCOUNTS);
  const emailAccount = useMemo(
    () => emailAccounts.find((email) => email.id === email_account_id),
    [emailAccounts, email_account_id]
  );

  const [replyMode, setReplyMode] = useState<ReplyMode | null>(
    emailLetterDraft
      ? emailLetterDraft.is_forwarded
        ? ReplyMode.FORWARD
        : (
            email_letter_tag === EmailLetterTag.Inbox
              ? isEqual(emailLetterDraft.to, [from]) &&
                isEqual(
                  emailLetterDraft.cc,
                  to.filter((email) => email !== emailAccount?.name)
                )
              : isEqual(emailLetterDraft.to, to) &&
                isEqual(emailLetterDraft.cc, cc)
          )
        ? ReplyMode.REPLY_ALL
        : ReplyMode.REPLY
      : null
  );
  const [showMode, setShowMode] = useState<ReplyFormShowMode>(
    ReplyFormShowMode.SHOW_IN_LOG
  );
  const replyFormOpen = useMemo(() => !!replyMode, [replyMode]);
  const elem = useRef<HTMLDivElement>(null);

  const { downloadFile } = useDownloadFile();

  const { handleCreateReplyDraft } = useCreateReplyDraft();
  const { handleDeleteReplyDraft } = useDeleteReplyDraft();
  const { handleDeleteLetter, loading: deleteLetterLoading } =
    useDeleteLetter();

  useEffect(() => {
    if (scrolled) {
      elem.current?.scrollIntoView({ block: 'start', behavior: 'auto' });
      openLettersContext?.setScrolledLetterId(null);
      openLettersContext?.setScrolledLetterDraftId(null);
    }
  }, [scrolled, openLettersContext]);

  useEffect(() => {
    if (replyMode === null) {
      setShowMode(ReplyFormShowMode.SHOW_IN_LOG);
    }
  }, [replyMode]);

  const onClose = useCallback(() => {
    if (showMode === ReplyFormShowMode.SHOW_IN_MODAL) {
      setShowMode(ReplyFormShowMode.SHOW_IN_LOG);
    }
  }, [showMode, setShowMode]);

  const replyOnLetterData = useMemo(() => {
    const { id, to, cc, from, html, subject, email_letter_tag, attachments } =
      emailLetter;
    return {
      id,
      to,
      cc,
      from,
      html,
      date,
      subject,
      email_letter_tag,
      attachments
    };
  }, [emailLetter, date]);

  const letterHtml = useMemo(
    () => (open ? normalizeLetterHtml(html) : ''),
    [html, open]
  );

  const letterDraftData = useMemo(() => {
    if (emailLetterDraft) {
      const { id, to, cc, bcc, html, subject, attachments } = emailLetterDraft;
      return { id, to, cc, bcc, html, subject, attachments };
    }
  }, [emailLetterDraft]);

  const draftEditorState = useMemo(
    () =>
      EditorState.createWithContent(stateFromHTML(letterDraftData?.html || '')),
    [letterDraftData]
  );

  const onCLickReply = useCallback(
    async (replyMode: ReplyMode) => {
      if (!emailLetterDraft && emailAccount) {
        try {
          const normalizedReceivers = to.map(normalizeEmail);
          const normalizedCopyReceivers = cc?.map(normalizeEmail);
          const normalizedFromEmail = normalizeEmail(from);
          const isSent = email_letter_tag === EmailLetterTag.Sent;
          let receivers: string[] = [];
          let copyReceivers: string[] = [];

          switch (replyMode) {
            case ReplyMode.REPLY:
              if (isSent) {
                receivers = normalizedReceivers;
                copyReceivers = [];
              } else {
                receivers = [normalizedFromEmail];
                copyReceivers = [];
              }
              break;
            case ReplyMode.REPLY_ALL:
              if (isSent) {
                receivers = normalizedReceivers;
                copyReceivers = normalizedCopyReceivers || [];
              } else {
                receivers = [normalizedFromEmail];
                copyReceivers = normalizedCopyReceivers
                  ? [
                      ...normalizedReceivers.filter(
                        (email) => email !== emailAccount.email
                      ),
                      ...normalizedCopyReceivers.filter(
                        (email) => email !== emailAccount.email
                      )
                    ]
                  : normalizedReceivers.filter(
                      (email) => email !== emailAccount.email
                    );
              }
              break;
          }
          await handleCreateReplyDraft({
            taskLogStoryId: parseInt(taskLogStoryId),
            to: receivers,
            cc: copyReceivers,
            html:
              replyMode === ReplyMode.FORWARD
                ? createForwardedLetterHTML(date, from, html)
                : '',
            is_forwarded: replyMode === ReplyMode.FORWARD
          });
        } catch (err) {
          handleDefaultError('Произошла ошибка при создании письма', err);
        }
      }
      setReplyMode(replyMode);
    },
    [
      emailLetterDraft,
      setReplyMode,
      handleCreateReplyDraft,
      taskLogStoryId,
      to,
      cc,
      date,
      from,
      html,
      email_letter_tag,
      emailAccount
    ]
  );

  const onClickDeleteDraft = useCallback(async () => {
    setReplyMode(null);
    if (emailLetterDraft?.id) {
      handleDeleteReplyDraft(parseInt(emailLetterDraft?.id));
    }
  }, [setReplyMode, emailLetterDraft, handleDeleteReplyDraft]);

  const [deleteLetterModalOpen, setDeleteLetterModalOpen] = useState(false);

  const deleteLetter = useCallback(async () => {
    try {
      await handleDeleteLetter(taskId, emailLetter.id);
      toast('Письмо удалено');
    } catch (err) {
      handleDefaultError('Произошла ошибка при удалении письма', err);
    }
  }, [handleDeleteLetter, emailLetter, taskId]);

  const letterFormRef = useRef<LetterFormRef>(null);

  useEffect(() => {
    if (replyFormOpen) {
      letterFormRef.current?.editor?.focus();
      letterFormRef.current?.container?.scrollIntoView({
        block: 'start',
        behavior: 'auto'
      });
    }
  }, [replyFormOpen]);

  const { openInOnlyOffice } = useOpenInOnlyOffice();

  const [, setCurrentTaskId] = useUrlKey<string | undefined>(URL_KEY_TASK_ID);
  const { handleCreateFileTaskFromLetterAttachment } =
    useCreateFileTaskFromLetterAttachment();

  const createFileTaskFromLetterAttachment = async (attachmentId: number) => {
    try {
      const taskId = await handleCreateFileTaskFromLetterAttachment(
        attachmentId
      );
      setCurrentTaskId(taskId);
    } catch (err) {
      handleDefaultError('Произошла ошибка при создании задачи', err);
    }
  };

  return (
    <>
      <TaskLogDeleteLetterModal
        isOpen={deleteLetterModalOpen}
        onClose={() => setDeleteLetterModalOpen(false)}
        onLetterDelete={deleteLetter}
        deleteLetterLoading={deleteLetterLoading}
      />
      <div
        className={clsx(
          s.TaskLogStoryLetter,
          open && s.TaskLogStoryLetter_open,
          className
        )}
        onClick={
          !open ? () => openLettersContext?.setLetterOpen(id, true) : undefined
        }
        ref={elem}
      >
        {!emailAccountDeleted && (
          <div className={s.TaskLogStoryLetter__actionButtons}>
            <Button
              variant={ButtonVariant.TERTIARY}
              size={ButtonSize.SMALL}
              className={s.TaskLogStoryLetter__actionButton}
              leftIcon={IconReply}
              onClick={() => onCLickReply(ReplyMode.REPLY)}
            >
              Ответить
            </Button>
            <Button
              variant={ButtonVariant.TERTIARY}
              size={ButtonSize.SMALL}
              className={s.TaskLogStoryLetter__actionButton}
              leftIcon={IconReplyAll}
              onClick={() => onCLickReply(ReplyMode.REPLY_ALL)}
            >
              Ответить всем
            </Button>
            <Button
              variant={ButtonVariant.TERTIARY}
              size={ButtonSize.SMALL}
              className={s.TaskLogStoryLetter__actionButton}
              leftIcon={IconForward}
              onClick={() => onCLickReply(ReplyMode.FORWARD)}
            >
              Переслать
            </Button>
            <Button
              variant={ButtonVariant.TERTIARY}
              size={ButtonSize.SMALL}
              className={s.TaskLogStoryLetter__actionButton}
              leftIcon={IconDelete}
              onClick={() => setDeleteLetterModalOpen(true)}
            >
              Удалить
            </Button>
          </div>
        )}
        <div className={s.TaskLogStoryLetter__fromEmail}>
          <span>{from}</span>
          {emailLetterDraft && !open && (
            <IconEdit className={s.TaskLogStoryLetter__draftIcon} />
          )}
        </div>
        {attachments.length !== 0 && (
          <div className={s.TaskLogStoryLetter__attachmentsAmount}>
            <IconAttachment className={s.TaskLogStoryLetter__attachmentsIcon} />
            <span>{attachments.length}</span>
          </div>
        )}
        <div className={s.TaskLogStoryLetter__date}>
          {format(date, 'yyyy-MM-dd, HH:mm')}
        </div>
        {email_letter_tag && (
          <EmailTag
            tag={email_letter_tag}
            className={s.TaskLogStoryLetter__emailTag}
          />
        )}
        <div className={s.TaskLogStoryLetter__receivers}>
          <div>
            <span className={s.TaskLogStoryLetter__receiversLabel}>Кому: </span>
            {to.join(', ')}
          </div>
          {!!cc?.length && (
            <div>
              <span className={s.TaskLogStoryLetter__receiversLabel}>
                Копия:{' '}
              </span>
              {cc.join(', ')}
            </div>
          )}
          {!!bcc?.length && (
            <div>
              <span className={s.TaskLogStoryLetter__receiversLabel}>
                Скрытая копия:{' '}
              </span>
              {bcc.join(', ')}
            </div>
          )}
        </div>
        <div
          className={clsx(
            s.TaskLogStoryLetter__content,
            open && s.TaskLogStoryLetter__content_open
          )}
          dangerouslySetInnerHTML={{
            __html: open ? letterHtml : emailTextToPreview(plain_text)
          }}
        ></div>
        {(attachments.length !== 0 || emailLetter.htmlFile) && open && (
          <div className={s.TaskLogStoryLetter__attachments}>
            {emailLetter.htmlFile && (
              <FileCard
                key={emailLetter.htmlFile.id}
                className={s.TaskLogStoryLetter__attachment}
                name={emailLetter.htmlFile.file_name}
                size={emailLetter.htmlFile.size}
                onClickName={
                  emailLetter.htmlFile!.canBeOpenedInOnlyOffice &&
                  emailLetter.htmlFile!.id
                    ? () => openInOnlyOffice(parseInt(emailLetter.htmlFile!.id))
                    : undefined
                }
                dropdownItems={[
                  {
                    text: 'Скачать',
                    onClick: () => {
                      downloadFile(
                        parseInt(emailLetter.htmlFile!.id),
                        emailLetter.htmlFile!.file_name
                      );
                    }
                  }
                ]}
              />
            )}
            {attachments.map((attachment) => (
              <FileCard
                key={attachment.id}
                className={s.TaskLogStoryLetter__attachment}
                name={attachment.file_name}
                size={attachment.size}
                onClickName={
                  attachment.canBeOpenedInOnlyOffice
                    ? () => openInOnlyOffice(parseInt(attachment.id))
                    : undefined
                }
                dropdownItems={[
                  {
                    text: 'Скачать',
                    onClick: () => {
                      downloadFile(
                        parseInt(attachment.id),
                        attachment.file_name
                      );
                    }
                  },
                  {
                    text: 'Создать задачу',
                    onClick: () =>
                      createFileTaskFromLetterAttachment(
                        parseInt(attachment.id)
                      )
                  },
                  {
                    text: 'Открыть в OnlyOffice',
                    hidden: !attachment.canBeOpenedInOnlyOffice,
                    onClick: () => openInOnlyOffice(parseInt(attachment.id))
                  }
                ]}
              />
            ))}
          </div>
        )}
        {replyFormOpen && (
          <LetterForm
            component={
              showMode === ReplyFormShowMode.SHOW_IN_MODAL ? Modal : 'div'
            }
            className={clsx(
              s.TaskLogStoryLetter__replyLetterForm,
              showMode === ReplyFormShowMode.SHOW_IN_MODAL &&
                s.TaskLogStoryLetter__replyLetterForm_modal
            )}
            replyOnLetterData={replyOnLetterData}
            replyMode={replyMode}
            setReplyMode={setReplyMode}
            initialValues={{
              email_account_id,
              subject,
              ...(letterDraftData && {
                receivers: letterDraftData.to,
                copyReceivers: letterDraftData.cc,
                hiddenCopyReceivers: letterDraftData.bcc,
                editorState: draftEditorState
              })
            }}
            close={showMode === ReplyFormShowMode.SHOW_IN_MODAL && onClose}
            onExpand={
              showMode === ReplyFormShowMode.SHOW_IN_LOG &&
              (() => setShowMode(ReplyFormShowMode.SHOW_IN_MODAL))
            }
            onLetterSent={() => {
              setReplyMode(null);
              openLettersContext?.setLetterOpen(id, false);
            }}
            classes={{
              replyHeader:
                showMode === ReplyFormShowMode.SHOW_IN_LOG &&
                s.TaskLogStoryLetter__replyLetterFormHeader_hidden,
              expandButton:
                showMode === ReplyFormShowMode.SHOW_IN_MODAL &&
                s.TaskLogStoryLetter__replyLetterFormExpandButton_hidden
            }}
            {...(showMode === ReplyFormShowMode.SHOW_IN_MODAL && {
              isOpen: true,
              disableDefaultStyles: true,
              onClose
            })}
            taskId={taskId}
            letterDraftData={letterDraftData}
            onLetterDelete={onClickDeleteDraft}
            ref={letterFormRef}
          />
        )}
        <div className={s.TaskLogStoryLetter__footer}>
          <button
            className={clsx(
              s.TaskLogStoryLetter__footerButton,
              s.TaskLogStoryLetter__collapseButton
            )}
            onClick={() => openLettersContext?.setLetterOpen(id, false)}
          >
            Свернуть
            <IconChevron className={s.TaskLogStoryLetter__footerButtonIcon} />
          </button>
        </div>
      </div>
    </>
  );
};
