import React, { KeyboardEvent, useRef } from 'react';
import clsx from 'clsx';
import { IconButton } from '../../../../common/components/ui/IconButton/IconButton';
import { Button } from '../../../../common/components/ui/Button/Button';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/IconSearch.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/IconClose.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/IconThinPlus.svg';

import { loaderColors } from '../../../../common/components/ui/Loader/Loader';
import s from './SearchInput.module.scss';

export interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  iconClassName?: string;
  setValue: (str: string) => void;
  handleCreateTask: () => void;
  createTaskLoading: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  className,
  inputClassName,
  iconClassName,
  placeholder = 'Поиск...',
  setValue,
  handleCreateTask,
  createTaskLoading,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCreateTask();
      inputRef.current?.blur();
    }
  };
  return (
    <div className={clsx(s.SearchInput, className)}>
      <div className={clsx(s.SearchInput__icon, iconClassName)}>
        <SearchIcon />
      </div>
      <input
        ref={inputRef}
        className={clsx(s.SearchInput__input, inputClassName)}
        type="text"
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDown}
        {...props}
      />
      <div className={s.SearchInput__rightsideButtons}>
        {!!props.value && (
          <IconButton
            icon={CloseIcon}
            className={s.SearchInput__eraseStringButton}
            onClick={() => setValue('')}
          />
        )}
        {props.value && (
          <>
            <div className={s.SearchInput__divider} />
            <Button
              className={s.SearchInput__createTaskButton}
              rightIcon={PlusIcon}
              onClick={() => handleCreateTask()}
              loading={createTaskLoading}
              loaderProps={{
                singleColor: loaderColors.buttonSecondary
              }}
            >
              Создать
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
